/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from '../API';
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createOrganization = /* GraphQL */ `mutation CreateOrganization(
  $input: CreateOrganizationInput!
  $condition: ModelOrganizationConditionInput
) {
  createOrganization(input: $input, condition: $condition) {
    id
    owners
    name
    organizationNameForHidden
    isHideOrganizationName
    icon
    description
    users {
      nextToken
      __typename
    }
    openJobs {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrganizationMutationVariables,
  APITypes.CreateOrganizationMutation
>;
export const updateOrganization = /* GraphQL */ `mutation UpdateOrganization(
  $input: UpdateOrganizationInput!
  $condition: ModelOrganizationConditionInput
) {
  updateOrganization(input: $input, condition: $condition) {
    id
    owners
    name
    organizationNameForHidden
    isHideOrganizationName
    icon
    description
    users {
      nextToken
      __typename
    }
    openJobs {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrganizationMutationVariables,
  APITypes.UpdateOrganizationMutation
>;
export const deleteOrganization = /* GraphQL */ `mutation DeleteOrganization(
  $input: DeleteOrganizationInput!
  $condition: ModelOrganizationConditionInput
) {
  deleteOrganization(input: $input, condition: $condition) {
    id
    owners
    name
    organizationNameForHidden
    isHideOrganizationName
    icon
    description
    users {
      nextToken
      __typename
    }
    openJobs {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrganizationMutationVariables,
  APITypes.DeleteOrganizationMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    owner
    sub
    firstName
    lastName
    firstNameKana
    lastNameKana
    name
    phoneticName
    displayName
    nameInitial
    email
    birthDate
    gender
    tel
    address
    nearestStation
    baseLocation {
      id
      type
      position
      name
      area
      createdAt
      updatedAt
      owner
      __typename
    }
    baseLocations {
      nextToken
      __typename
    }
    profileIcon
    coverImage
    mainJob {
      id
      type
      position
      name
      color
      createdAt
      updatedAt
      __typename
    }
    mainJobs {
      nextToken
      __typename
    }
    skillSets {
      nextToken
      __typename
    }
    skillTags
    appetiteToChangeJobs
    appetiteToSideWork
    wayOfWorking
    currentLocationType
    isPrivate
    belongsTo
    companyName
    description
    workHistories {
      nextToken
      __typename
    }
    numberOfDayPerWeek
    numberOfHoursPerDay
    salaryType
    facebookUrl
    linkedinUrl
    twitterUrl
    lineID
    instagramUrl
    chatworkID
    minumumSalary
    maximumSalary
    favoritedOpenJobs {
      nextToken
      __typename
    }
    applies {
      nextToken
      __typename
    }
    usedEmployeeBenefits {
      nextToken
      __typename
    }
    organization {
      id
      owners
      name
      organizationNameForHidden
      isHideOrganizationName
      icon
      description
      createdAt
      updatedAt
      __typename
    }
    hopeLocationType
    otherHopes
    isShareFullname
    isShareProfileIcon
    isShareHistories
    isShareCurrentJob
    isShareAppetites
    isShareWorkTime
    isShareHopeSalary
    isShareHopeLocationType
    isShareAbleAreas
    isShareGender
    isShareBirthdate
    isShareBaseLocation
    isCompletedSurvey
    resumeFile
    resumeFiles {
      nextToken
      __typename
    }
    portfolioFiles {
      nextToken
      __typename
    }
    wpWorker {
      id
      userID
      categories
      status
      url
      createdAt
      updatedAt
      __typename
    }
    qualifications {
      nextToken
      __typename
    }
    evaluations {
      nextToken
      __typename
    }
    evaluationComments {
      nextToken
      __typename
    }
    myEvaluationComments {
      nextToken
      __typename
    }
    isAlreadyEditedBasic
    isAgreedTerms
    isAgreedPostForClient
    isConfirmedServiceGuide
    nameForClient
    introductionForClient
    userConversations {
      nextToken
      __typename
    }
    messages {
      nextToken
      __typename
    }
    messageReads {
      nextToken
      __typename
    }
    openJobs {
      nextToken
      __typename
    }
    intervieweeLogs {
      nextToken
      __typename
    }
    interviewerLogs {
      nextToken
      __typename
    }
    skillbaWorkHistories {
      nextToken
      __typename
    }
    skillbaWorkHistoryReviews {
      nextToken
      __typename
    }
    triggerForRegistration
    triggerForRegistrationDescription
    introducerId
    introducerName
    skillNamesForQuery
    jobNamesForQuery
    lastJobNameForQuery
    interviewCommentForQuery
    interviewCommentForClientForQuery
    interviewMinutesForQuery
    interviewActionMethodForQuery
    skillbaWorkHistoryNameForQuery
    skillTagNamesForQuery
    portfolioForQuery
    evaluationAvg
    isImportFromNotion
    notionWorkTimes
    notionSalaries
    isDebug
    shortID
    createdAt
    updatedAt
    lastVisitedAt
    organizationUsersId
    userBaseLocationId
    userMainJobId
    userWpWorkerId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    owner
    sub
    firstName
    lastName
    firstNameKana
    lastNameKana
    name
    phoneticName
    displayName
    nameInitial
    email
    birthDate
    gender
    tel
    address
    nearestStation
    baseLocation {
      id
      type
      position
      name
      area
      createdAt
      updatedAt
      owner
      __typename
    }
    baseLocations {
      nextToken
      __typename
    }
    profileIcon
    coverImage
    mainJob {
      id
      type
      position
      name
      color
      createdAt
      updatedAt
      __typename
    }
    mainJobs {
      nextToken
      __typename
    }
    skillSets {
      nextToken
      __typename
    }
    skillTags
    appetiteToChangeJobs
    appetiteToSideWork
    wayOfWorking
    currentLocationType
    isPrivate
    belongsTo
    companyName
    description
    workHistories {
      nextToken
      __typename
    }
    numberOfDayPerWeek
    numberOfHoursPerDay
    salaryType
    facebookUrl
    linkedinUrl
    twitterUrl
    lineID
    instagramUrl
    chatworkID
    minumumSalary
    maximumSalary
    favoritedOpenJobs {
      nextToken
      __typename
    }
    applies {
      nextToken
      __typename
    }
    usedEmployeeBenefits {
      nextToken
      __typename
    }
    organization {
      id
      owners
      name
      organizationNameForHidden
      isHideOrganizationName
      icon
      description
      createdAt
      updatedAt
      __typename
    }
    hopeLocationType
    otherHopes
    isShareFullname
    isShareProfileIcon
    isShareHistories
    isShareCurrentJob
    isShareAppetites
    isShareWorkTime
    isShareHopeSalary
    isShareHopeLocationType
    isShareAbleAreas
    isShareGender
    isShareBirthdate
    isShareBaseLocation
    isCompletedSurvey
    resumeFile
    resumeFiles {
      nextToken
      __typename
    }
    portfolioFiles {
      nextToken
      __typename
    }
    wpWorker {
      id
      userID
      categories
      status
      url
      createdAt
      updatedAt
      __typename
    }
    qualifications {
      nextToken
      __typename
    }
    evaluations {
      nextToken
      __typename
    }
    evaluationComments {
      nextToken
      __typename
    }
    myEvaluationComments {
      nextToken
      __typename
    }
    isAlreadyEditedBasic
    isAgreedTerms
    isAgreedPostForClient
    isConfirmedServiceGuide
    nameForClient
    introductionForClient
    userConversations {
      nextToken
      __typename
    }
    messages {
      nextToken
      __typename
    }
    messageReads {
      nextToken
      __typename
    }
    openJobs {
      nextToken
      __typename
    }
    intervieweeLogs {
      nextToken
      __typename
    }
    interviewerLogs {
      nextToken
      __typename
    }
    skillbaWorkHistories {
      nextToken
      __typename
    }
    skillbaWorkHistoryReviews {
      nextToken
      __typename
    }
    triggerForRegistration
    triggerForRegistrationDescription
    introducerId
    introducerName
    skillNamesForQuery
    jobNamesForQuery
    lastJobNameForQuery
    interviewCommentForQuery
    interviewCommentForClientForQuery
    interviewMinutesForQuery
    interviewActionMethodForQuery
    skillbaWorkHistoryNameForQuery
    skillTagNamesForQuery
    portfolioForQuery
    evaluationAvg
    isImportFromNotion
    notionWorkTimes
    notionSalaries
    isDebug
    shortID
    createdAt
    updatedAt
    lastVisitedAt
    organizationUsersId
    userBaseLocationId
    userMainJobId
    userWpWorkerId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    owner
    sub
    firstName
    lastName
    firstNameKana
    lastNameKana
    name
    phoneticName
    displayName
    nameInitial
    email
    birthDate
    gender
    tel
    address
    nearestStation
    baseLocation {
      id
      type
      position
      name
      area
      createdAt
      updatedAt
      owner
      __typename
    }
    baseLocations {
      nextToken
      __typename
    }
    profileIcon
    coverImage
    mainJob {
      id
      type
      position
      name
      color
      createdAt
      updatedAt
      __typename
    }
    mainJobs {
      nextToken
      __typename
    }
    skillSets {
      nextToken
      __typename
    }
    skillTags
    appetiteToChangeJobs
    appetiteToSideWork
    wayOfWorking
    currentLocationType
    isPrivate
    belongsTo
    companyName
    description
    workHistories {
      nextToken
      __typename
    }
    numberOfDayPerWeek
    numberOfHoursPerDay
    salaryType
    facebookUrl
    linkedinUrl
    twitterUrl
    lineID
    instagramUrl
    chatworkID
    minumumSalary
    maximumSalary
    favoritedOpenJobs {
      nextToken
      __typename
    }
    applies {
      nextToken
      __typename
    }
    usedEmployeeBenefits {
      nextToken
      __typename
    }
    organization {
      id
      owners
      name
      organizationNameForHidden
      isHideOrganizationName
      icon
      description
      createdAt
      updatedAt
      __typename
    }
    hopeLocationType
    otherHopes
    isShareFullname
    isShareProfileIcon
    isShareHistories
    isShareCurrentJob
    isShareAppetites
    isShareWorkTime
    isShareHopeSalary
    isShareHopeLocationType
    isShareAbleAreas
    isShareGender
    isShareBirthdate
    isShareBaseLocation
    isCompletedSurvey
    resumeFile
    resumeFiles {
      nextToken
      __typename
    }
    portfolioFiles {
      nextToken
      __typename
    }
    wpWorker {
      id
      userID
      categories
      status
      url
      createdAt
      updatedAt
      __typename
    }
    qualifications {
      nextToken
      __typename
    }
    evaluations {
      nextToken
      __typename
    }
    evaluationComments {
      nextToken
      __typename
    }
    myEvaluationComments {
      nextToken
      __typename
    }
    isAlreadyEditedBasic
    isAgreedTerms
    isAgreedPostForClient
    isConfirmedServiceGuide
    nameForClient
    introductionForClient
    userConversations {
      nextToken
      __typename
    }
    messages {
      nextToken
      __typename
    }
    messageReads {
      nextToken
      __typename
    }
    openJobs {
      nextToken
      __typename
    }
    intervieweeLogs {
      nextToken
      __typename
    }
    interviewerLogs {
      nextToken
      __typename
    }
    skillbaWorkHistories {
      nextToken
      __typename
    }
    skillbaWorkHistoryReviews {
      nextToken
      __typename
    }
    triggerForRegistration
    triggerForRegistrationDescription
    introducerId
    introducerName
    skillNamesForQuery
    jobNamesForQuery
    lastJobNameForQuery
    interviewCommentForQuery
    interviewCommentForClientForQuery
    interviewMinutesForQuery
    interviewActionMethodForQuery
    skillbaWorkHistoryNameForQuery
    skillTagNamesForQuery
    portfolioForQuery
    evaluationAvg
    isImportFromNotion
    notionWorkTimes
    notionSalaries
    isDebug
    shortID
    createdAt
    updatedAt
    lastVisitedAt
    organizationUsersId
    userBaseLocationId
    userMainJobId
    userWpWorkerId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createUserPrivate = /* GraphQL */ `mutation CreateUserPrivate(
  $input: CreateUserPrivateInput!
  $condition: ModelUserPrivateConditionInput
) {
  createUserPrivate(input: $input, condition: $condition) {
    sub
    name
    phoneticName
    email
    tel
    address
    nearestStation
    belongsTo
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserPrivateMutationVariables,
  APITypes.CreateUserPrivateMutation
>;
export const updateUserPrivate = /* GraphQL */ `mutation UpdateUserPrivate(
  $input: UpdateUserPrivateInput!
  $condition: ModelUserPrivateConditionInput
) {
  updateUserPrivate(input: $input, condition: $condition) {
    sub
    name
    phoneticName
    email
    tel
    address
    nearestStation
    belongsTo
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserPrivateMutationVariables,
  APITypes.UpdateUserPrivateMutation
>;
export const deleteUserPrivate = /* GraphQL */ `mutation DeleteUserPrivate(
  $input: DeleteUserPrivateInput!
  $condition: ModelUserPrivateConditionInput
) {
  deleteUserPrivate(input: $input, condition: $condition) {
    sub
    name
    phoneticName
    email
    tel
    address
    nearestStation
    belongsTo
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserPrivateMutationVariables,
  APITypes.DeleteUserPrivateMutation
>;
export const createUserShareSetting =
  /* GraphQL */ `mutation CreateUserShareSetting(
  $input: CreateUserShareSettingInput!
  $condition: ModelUserShareSettingConditionInput
) {
  createUserShareSetting(input: $input, condition: $condition) {
    sub
    pathname
    isShareFullname
    isShareProfileIcon
    isShareHistories
    isShareCurrentJob
    isShareAppetites
    isShareWorkTime
    isShareHopeSalary
    isShareHopeLocationType
    isShareAbleAreas
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateUserShareSettingMutationVariables,
    APITypes.CreateUserShareSettingMutation
  >;
export const updateUserShareSetting =
  /* GraphQL */ `mutation UpdateUserShareSetting(
  $input: UpdateUserShareSettingInput!
  $condition: ModelUserShareSettingConditionInput
) {
  updateUserShareSetting(input: $input, condition: $condition) {
    sub
    pathname
    isShareFullname
    isShareProfileIcon
    isShareHistories
    isShareCurrentJob
    isShareAppetites
    isShareWorkTime
    isShareHopeSalary
    isShareHopeLocationType
    isShareAbleAreas
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateUserShareSettingMutationVariables,
    APITypes.UpdateUserShareSettingMutation
  >;
export const deleteUserShareSetting =
  /* GraphQL */ `mutation DeleteUserShareSetting(
  $input: DeleteUserShareSettingInput!
  $condition: ModelUserShareSettingConditionInput
) {
  deleteUserShareSetting(input: $input, condition: $condition) {
    sub
    pathname
    isShareFullname
    isShareProfileIcon
    isShareHistories
    isShareCurrentJob
    isShareAppetites
    isShareWorkTime
    isShareHopeSalary
    isShareHopeLocationType
    isShareAbleAreas
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteUserShareSettingMutationVariables,
    APITypes.DeleteUserShareSettingMutation
  >;
export const createPrefacture = /* GraphQL */ `mutation CreatePrefacture(
  $input: CreatePrefactureInput!
  $condition: ModelPrefactureConditionInput
) {
  createPrefacture(input: $input, condition: $condition) {
    id
    type
    position
    name
    area
    users {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePrefactureMutationVariables,
  APITypes.CreatePrefactureMutation
>;
export const updatePrefacture = /* GraphQL */ `mutation UpdatePrefacture(
  $input: UpdatePrefactureInput!
  $condition: ModelPrefactureConditionInput
) {
  updatePrefacture(input: $input, condition: $condition) {
    id
    type
    position
    name
    area
    users {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePrefactureMutationVariables,
  APITypes.UpdatePrefactureMutation
>;
export const deletePrefacture = /* GraphQL */ `mutation DeletePrefacture(
  $input: DeletePrefactureInput!
  $condition: ModelPrefactureConditionInput
) {
  deletePrefacture(input: $input, condition: $condition) {
    id
    type
    position
    name
    area
    users {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePrefactureMutationVariables,
  APITypes.DeletePrefactureMutation
>;
export const createJob = /* GraphQL */ `mutation CreateJob(
  $input: CreateJobInput!
  $condition: ModelJobConditionInput
) {
  createJob(input: $input, condition: $condition) {
    id
    type
    position
    name
    skillCategories {
      nextToken
      __typename
    }
    skills {
      nextToken
      __typename
    }
    color
    users {
      nextToken
      __typename
    }
    openJobs {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateJobMutationVariables,
  APITypes.CreateJobMutation
>;
export const updateJob = /* GraphQL */ `mutation UpdateJob(
  $input: UpdateJobInput!
  $condition: ModelJobConditionInput
) {
  updateJob(input: $input, condition: $condition) {
    id
    type
    position
    name
    skillCategories {
      nextToken
      __typename
    }
    skills {
      nextToken
      __typename
    }
    color
    users {
      nextToken
      __typename
    }
    openJobs {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateJobMutationVariables,
  APITypes.UpdateJobMutation
>;
export const deleteJob = /* GraphQL */ `mutation DeleteJob(
  $input: DeleteJobInput!
  $condition: ModelJobConditionInput
) {
  deleteJob(input: $input, condition: $condition) {
    id
    type
    position
    name
    skillCategories {
      nextToken
      __typename
    }
    skills {
      nextToken
      __typename
    }
    color
    users {
      nextToken
      __typename
    }
    openJobs {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteJobMutationVariables,
  APITypes.DeleteJobMutation
>;
export const createUserMainJobs = /* GraphQL */ `mutation CreateUserMainJobs(
  $input: CreateUserMainJobsInput!
  $condition: ModelUserMainJobsConditionInput
) {
  createUserMainJobs(input: $input, condition: $condition) {
    id
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    jobID
    job {
      id
      type
      position
      name
      color
      createdAt
      updatedAt
      __typename
    }
    isPositive
    isSelected
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMainJobsMutationVariables,
  APITypes.CreateUserMainJobsMutation
>;
export const updateUserMainJobs = /* GraphQL */ `mutation UpdateUserMainJobs(
  $input: UpdateUserMainJobsInput!
  $condition: ModelUserMainJobsConditionInput
) {
  updateUserMainJobs(input: $input, condition: $condition) {
    id
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    jobID
    job {
      id
      type
      position
      name
      color
      createdAt
      updatedAt
      __typename
    }
    isPositive
    isSelected
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMainJobsMutationVariables,
  APITypes.UpdateUserMainJobsMutation
>;
export const deleteUserMainJobs = /* GraphQL */ `mutation DeleteUserMainJobs(
  $input: DeleteUserMainJobsInput!
  $condition: ModelUserMainJobsConditionInput
) {
  deleteUserMainJobs(input: $input, condition: $condition) {
    id
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    jobID
    job {
      id
      type
      position
      name
      color
      createdAt
      updatedAt
      __typename
    }
    isPositive
    isSelected
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMainJobsMutationVariables,
  APITypes.DeleteUserMainJobsMutation
>;
export const createOpenJobMainJobs =
  /* GraphQL */ `mutation CreateOpenJobMainJobs(
  $input: CreateOpenJobMainJobsInput!
  $condition: ModelOpenJobMainJobsConditionInput
) {
  createOpenJobMainJobs(input: $input, condition: $condition) {
    id
    openJobID
    openJob {
      id
      organizationName
      consultantName
      owners
      isPublic
      skills
      isDraft
      title
      coverImage
      workType
      numberOfDayPerWeek
      numberOfHoursPerDay
      baseLocationTypes
      baseLocationDetail
      salaryType
      minumumSalary
      maximumSalary
      overView
      issue
      description
      recommendFor
      selectionProcessSupplement
      requiredSkillTags
      requiredSkillTagsDescription
      welcomeSkillTags
      welcomeSkillTagsDescription
      memo
      isHideOrganizationName
      organizationNameForHidden
      isHideSkills
      skillNamesForHidden
      isExcellent
      createdAt
      updatedAt
      organizationOpenJobsId
      openJobJobId
      openJobBaseLocationId
      __typename
    }
    jobID
    job {
      id
      type
      position
      name
      color
      createdAt
      updatedAt
      __typename
    }
    isSelected
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateOpenJobMainJobsMutationVariables,
    APITypes.CreateOpenJobMainJobsMutation
  >;
export const updateOpenJobMainJobs =
  /* GraphQL */ `mutation UpdateOpenJobMainJobs(
  $input: UpdateOpenJobMainJobsInput!
  $condition: ModelOpenJobMainJobsConditionInput
) {
  updateOpenJobMainJobs(input: $input, condition: $condition) {
    id
    openJobID
    openJob {
      id
      organizationName
      consultantName
      owners
      isPublic
      skills
      isDraft
      title
      coverImage
      workType
      numberOfDayPerWeek
      numberOfHoursPerDay
      baseLocationTypes
      baseLocationDetail
      salaryType
      minumumSalary
      maximumSalary
      overView
      issue
      description
      recommendFor
      selectionProcessSupplement
      requiredSkillTags
      requiredSkillTagsDescription
      welcomeSkillTags
      welcomeSkillTagsDescription
      memo
      isHideOrganizationName
      organizationNameForHidden
      isHideSkills
      skillNamesForHidden
      isExcellent
      createdAt
      updatedAt
      organizationOpenJobsId
      openJobJobId
      openJobBaseLocationId
      __typename
    }
    jobID
    job {
      id
      type
      position
      name
      color
      createdAt
      updatedAt
      __typename
    }
    isSelected
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateOpenJobMainJobsMutationVariables,
    APITypes.UpdateOpenJobMainJobsMutation
  >;
export const deleteOpenJobMainJobs =
  /* GraphQL */ `mutation DeleteOpenJobMainJobs(
  $input: DeleteOpenJobMainJobsInput!
  $condition: ModelOpenJobMainJobsConditionInput
) {
  deleteOpenJobMainJobs(input: $input, condition: $condition) {
    id
    openJobID
    openJob {
      id
      organizationName
      consultantName
      owners
      isPublic
      skills
      isDraft
      title
      coverImage
      workType
      numberOfDayPerWeek
      numberOfHoursPerDay
      baseLocationTypes
      baseLocationDetail
      salaryType
      minumumSalary
      maximumSalary
      overView
      issue
      description
      recommendFor
      selectionProcessSupplement
      requiredSkillTags
      requiredSkillTagsDescription
      welcomeSkillTags
      welcomeSkillTagsDescription
      memo
      isHideOrganizationName
      organizationNameForHidden
      isHideSkills
      skillNamesForHidden
      isExcellent
      createdAt
      updatedAt
      organizationOpenJobsId
      openJobJobId
      openJobBaseLocationId
      __typename
    }
    jobID
    job {
      id
      type
      position
      name
      color
      createdAt
      updatedAt
      __typename
    }
    isSelected
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteOpenJobMainJobsMutationVariables,
    APITypes.DeleteOpenJobMainJobsMutation
  >;
export const createSkillCategory = /* GraphQL */ `mutation CreateSkillCategory(
  $input: CreateSkillCategoryInput!
  $condition: ModelSkillCategoryConditionInput
) {
  createSkillCategory(input: $input, condition: $condition) {
    id
    type
    position
    name
    job {
      id
      type
      position
      name
      color
      createdAt
      updatedAt
      __typename
    }
    skills {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    jobSkillCategoriesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSkillCategoryMutationVariables,
  APITypes.CreateSkillCategoryMutation
>;
export const updateSkillCategory = /* GraphQL */ `mutation UpdateSkillCategory(
  $input: UpdateSkillCategoryInput!
  $condition: ModelSkillCategoryConditionInput
) {
  updateSkillCategory(input: $input, condition: $condition) {
    id
    type
    position
    name
    job {
      id
      type
      position
      name
      color
      createdAt
      updatedAt
      __typename
    }
    skills {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    jobSkillCategoriesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSkillCategoryMutationVariables,
  APITypes.UpdateSkillCategoryMutation
>;
export const deleteSkillCategory = /* GraphQL */ `mutation DeleteSkillCategory(
  $input: DeleteSkillCategoryInput!
  $condition: ModelSkillCategoryConditionInput
) {
  deleteSkillCategory(input: $input, condition: $condition) {
    id
    type
    position
    name
    job {
      id
      type
      position
      name
      color
      createdAt
      updatedAt
      __typename
    }
    skills {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    jobSkillCategoriesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSkillCategoryMutationVariables,
  APITypes.DeleteSkillCategoryMutation
>;
export const createSkill = /* GraphQL */ `mutation CreateSkill(
  $input: CreateSkillInput!
  $condition: ModelSkillConditionInput
) {
  createSkill(input: $input, condition: $condition) {
    id
    type
    position
    name
    job {
      id
      type
      position
      name
      color
      createdAt
      updatedAt
      __typename
    }
    skillCategory {
      id
      type
      position
      name
      createdAt
      updatedAt
      jobSkillCategoriesId
      __typename
    }
    skillTags {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    jobSkillsId
    skillCategorySkillsId
    skillJobId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSkillMutationVariables,
  APITypes.CreateSkillMutation
>;
export const updateSkill = /* GraphQL */ `mutation UpdateSkill(
  $input: UpdateSkillInput!
  $condition: ModelSkillConditionInput
) {
  updateSkill(input: $input, condition: $condition) {
    id
    type
    position
    name
    job {
      id
      type
      position
      name
      color
      createdAt
      updatedAt
      __typename
    }
    skillCategory {
      id
      type
      position
      name
      createdAt
      updatedAt
      jobSkillCategoriesId
      __typename
    }
    skillTags {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    jobSkillsId
    skillCategorySkillsId
    skillJobId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSkillMutationVariables,
  APITypes.UpdateSkillMutation
>;
export const deleteSkill = /* GraphQL */ `mutation DeleteSkill(
  $input: DeleteSkillInput!
  $condition: ModelSkillConditionInput
) {
  deleteSkill(input: $input, condition: $condition) {
    id
    type
    position
    name
    job {
      id
      type
      position
      name
      color
      createdAt
      updatedAt
      __typename
    }
    skillCategory {
      id
      type
      position
      name
      createdAt
      updatedAt
      jobSkillCategoriesId
      __typename
    }
    skillTags {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    jobSkillsId
    skillCategorySkillsId
    skillJobId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSkillMutationVariables,
  APITypes.DeleteSkillMutation
>;
export const createSkillTag = /* GraphQL */ `mutation CreateSkillTag(
  $input: CreateSkillTagInput!
  $condition: ModelSkillTagConditionInput
) {
  createSkillTag(input: $input, condition: $condition) {
    id
    type
    position
    name
    skill {
      id
      type
      position
      name
      createdAt
      updatedAt
      jobSkillsId
      skillCategorySkillsId
      skillJobId
      __typename
    }
    lead
    createdAt
    updatedAt
    skillSkillTagsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSkillTagMutationVariables,
  APITypes.CreateSkillTagMutation
>;
export const updateSkillTag = /* GraphQL */ `mutation UpdateSkillTag(
  $input: UpdateSkillTagInput!
  $condition: ModelSkillTagConditionInput
) {
  updateSkillTag(input: $input, condition: $condition) {
    id
    type
    position
    name
    skill {
      id
      type
      position
      name
      createdAt
      updatedAt
      jobSkillsId
      skillCategorySkillsId
      skillJobId
      __typename
    }
    lead
    createdAt
    updatedAt
    skillSkillTagsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSkillTagMutationVariables,
  APITypes.UpdateSkillTagMutation
>;
export const deleteSkillTag = /* GraphQL */ `mutation DeleteSkillTag(
  $input: DeleteSkillTagInput!
  $condition: ModelSkillTagConditionInput
) {
  deleteSkillTag(input: $input, condition: $condition) {
    id
    type
    position
    name
    skill {
      id
      type
      position
      name
      createdAt
      updatedAt
      jobSkillsId
      skillCategorySkillsId
      skillJobId
      __typename
    }
    lead
    createdAt
    updatedAt
    skillSkillTagsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSkillTagMutationVariables,
  APITypes.DeleteSkillTagMutation
>;
export const createWorkHistory = /* GraphQL */ `mutation CreateWorkHistory(
  $input: CreateWorkHistoryInput!
  $condition: ModelWorkHistoryConditionInput
) {
  createWorkHistory(input: $input, condition: $condition) {
    id
    owner
    startDate
    endDate
    project
    isSideWork
    isCurrentJob
    isLastJob
    isLastSchool
    historyType
    classification
    industry
    position
    isContinuation
    isDropOut
    baseLocationType
    achievements {
      nextToken
      __typename
    }
    workHistoryDivisions {
      nextToken
      __typename
    }
    organizationName
    description
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateWorkHistoryMutationVariables,
  APITypes.CreateWorkHistoryMutation
>;
export const updateWorkHistory = /* GraphQL */ `mutation UpdateWorkHistory(
  $input: UpdateWorkHistoryInput!
  $condition: ModelWorkHistoryConditionInput
) {
  updateWorkHistory(input: $input, condition: $condition) {
    id
    owner
    startDate
    endDate
    project
    isSideWork
    isCurrentJob
    isLastJob
    isLastSchool
    historyType
    classification
    industry
    position
    isContinuation
    isDropOut
    baseLocationType
    achievements {
      nextToken
      __typename
    }
    workHistoryDivisions {
      nextToken
      __typename
    }
    organizationName
    description
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateWorkHistoryMutationVariables,
  APITypes.UpdateWorkHistoryMutation
>;
export const deleteWorkHistory = /* GraphQL */ `mutation DeleteWorkHistory(
  $input: DeleteWorkHistoryInput!
  $condition: ModelWorkHistoryConditionInput
) {
  deleteWorkHistory(input: $input, condition: $condition) {
    id
    owner
    startDate
    endDate
    project
    isSideWork
    isCurrentJob
    isLastJob
    isLastSchool
    historyType
    classification
    industry
    position
    isContinuation
    isDropOut
    baseLocationType
    achievements {
      nextToken
      __typename
    }
    workHistoryDivisions {
      nextToken
      __typename
    }
    organizationName
    description
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteWorkHistoryMutationVariables,
  APITypes.DeleteWorkHistoryMutation
>;
export const createWorkHistoryAchievement =
  /* GraphQL */ `mutation CreateWorkHistoryAchievement(
  $input: CreateWorkHistoryAchievementInput!
  $condition: ModelWorkHistoryAchievementConditionInput
) {
  createWorkHistoryAchievement(input: $input, condition: $condition) {
    id
    workHistoryID
    workHistory {
      id
      owner
      startDate
      endDate
      project
      isSideWork
      isCurrentJob
      isLastJob
      isLastSchool
      historyType
      classification
      industry
      position
      isContinuation
      isDropOut
      baseLocationType
      organizationName
      description
      userID
      createdAt
      updatedAt
      __typename
    }
    projectName
    coverImage
    description
    topics
    role
    uploads {
      nextToken
      __typename
    }
    links {
      nextToken
      __typename
    }
    division
    position
    startDate
    endDate
    isContinuation
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateWorkHistoryAchievementMutationVariables,
    APITypes.CreateWorkHistoryAchievementMutation
  >;
export const updateWorkHistoryAchievement =
  /* GraphQL */ `mutation UpdateWorkHistoryAchievement(
  $input: UpdateWorkHistoryAchievementInput!
  $condition: ModelWorkHistoryAchievementConditionInput
) {
  updateWorkHistoryAchievement(input: $input, condition: $condition) {
    id
    workHistoryID
    workHistory {
      id
      owner
      startDate
      endDate
      project
      isSideWork
      isCurrentJob
      isLastJob
      isLastSchool
      historyType
      classification
      industry
      position
      isContinuation
      isDropOut
      baseLocationType
      organizationName
      description
      userID
      createdAt
      updatedAt
      __typename
    }
    projectName
    coverImage
    description
    topics
    role
    uploads {
      nextToken
      __typename
    }
    links {
      nextToken
      __typename
    }
    division
    position
    startDate
    endDate
    isContinuation
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateWorkHistoryAchievementMutationVariables,
    APITypes.UpdateWorkHistoryAchievementMutation
  >;
export const deleteWorkHistoryAchievement =
  /* GraphQL */ `mutation DeleteWorkHistoryAchievement(
  $input: DeleteWorkHistoryAchievementInput!
  $condition: ModelWorkHistoryAchievementConditionInput
) {
  deleteWorkHistoryAchievement(input: $input, condition: $condition) {
    id
    workHistoryID
    workHistory {
      id
      owner
      startDate
      endDate
      project
      isSideWork
      isCurrentJob
      isLastJob
      isLastSchool
      historyType
      classification
      industry
      position
      isContinuation
      isDropOut
      baseLocationType
      organizationName
      description
      userID
      createdAt
      updatedAt
      __typename
    }
    projectName
    coverImage
    description
    topics
    role
    uploads {
      nextToken
      __typename
    }
    links {
      nextToken
      __typename
    }
    division
    position
    startDate
    endDate
    isContinuation
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteWorkHistoryAchievementMutationVariables,
    APITypes.DeleteWorkHistoryAchievementMutation
  >;
export const createWorkHistoryAchievementLink =
  /* GraphQL */ `mutation CreateWorkHistoryAchievementLink(
  $input: CreateWorkHistoryAchievementLinkInput!
  $condition: ModelWorkHistoryAchievementLinkConditionInput
) {
  createWorkHistoryAchievementLink(input: $input, condition: $condition) {
    id
    owner
    achievement {
      id
      workHistoryID
      projectName
      coverImage
      description
      topics
      role
      division
      position
      startDate
      endDate
      isContinuation
      createdAt
      updatedAt
      owner
      __typename
    }
    title
    url
    createdAt
    updatedAt
    workHistoryAchievementLinksId
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateWorkHistoryAchievementLinkMutationVariables,
    APITypes.CreateWorkHistoryAchievementLinkMutation
  >;
export const updateWorkHistoryAchievementLink =
  /* GraphQL */ `mutation UpdateWorkHistoryAchievementLink(
  $input: UpdateWorkHistoryAchievementLinkInput!
  $condition: ModelWorkHistoryAchievementLinkConditionInput
) {
  updateWorkHistoryAchievementLink(input: $input, condition: $condition) {
    id
    owner
    achievement {
      id
      workHistoryID
      projectName
      coverImage
      description
      topics
      role
      division
      position
      startDate
      endDate
      isContinuation
      createdAt
      updatedAt
      owner
      __typename
    }
    title
    url
    createdAt
    updatedAt
    workHistoryAchievementLinksId
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateWorkHistoryAchievementLinkMutationVariables,
    APITypes.UpdateWorkHistoryAchievementLinkMutation
  >;
export const deleteWorkHistoryAchievementLink =
  /* GraphQL */ `mutation DeleteWorkHistoryAchievementLink(
  $input: DeleteWorkHistoryAchievementLinkInput!
  $condition: ModelWorkHistoryAchievementLinkConditionInput
) {
  deleteWorkHistoryAchievementLink(input: $input, condition: $condition) {
    id
    owner
    achievement {
      id
      workHistoryID
      projectName
      coverImage
      description
      topics
      role
      division
      position
      startDate
      endDate
      isContinuation
      createdAt
      updatedAt
      owner
      __typename
    }
    title
    url
    createdAt
    updatedAt
    workHistoryAchievementLinksId
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteWorkHistoryAchievementLinkMutationVariables,
    APITypes.DeleteWorkHistoryAchievementLinkMutation
  >;
export const createWorkHistoryAchievementResume =
  /* GraphQL */ `mutation CreateWorkHistoryAchievementResume(
  $input: CreateWorkHistoryAchievementResumeInput!
  $condition: ModelWorkHistoryAchievementResumeConditionInput
) {
  createWorkHistoryAchievementResume(input: $input, condition: $condition) {
    id
    owner
    achievement {
      id
      workHistoryID
      projectName
      coverImage
      description
      topics
      role
      division
      position
      startDate
      endDate
      isContinuation
      createdAt
      updatedAt
      owner
      __typename
    }
    file
    filename
    fileType
    thumbnail
    createdAt
    updatedAt
    workHistoryAchievementUploadsId
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateWorkHistoryAchievementResumeMutationVariables,
    APITypes.CreateWorkHistoryAchievementResumeMutation
  >;
export const updateWorkHistoryAchievementResume =
  /* GraphQL */ `mutation UpdateWorkHistoryAchievementResume(
  $input: UpdateWorkHistoryAchievementResumeInput!
  $condition: ModelWorkHistoryAchievementResumeConditionInput
) {
  updateWorkHistoryAchievementResume(input: $input, condition: $condition) {
    id
    owner
    achievement {
      id
      workHistoryID
      projectName
      coverImage
      description
      topics
      role
      division
      position
      startDate
      endDate
      isContinuation
      createdAt
      updatedAt
      owner
      __typename
    }
    file
    filename
    fileType
    thumbnail
    createdAt
    updatedAt
    workHistoryAchievementUploadsId
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateWorkHistoryAchievementResumeMutationVariables,
    APITypes.UpdateWorkHistoryAchievementResumeMutation
  >;
export const deleteWorkHistoryAchievementResume =
  /* GraphQL */ `mutation DeleteWorkHistoryAchievementResume(
  $input: DeleteWorkHistoryAchievementResumeInput!
  $condition: ModelWorkHistoryAchievementResumeConditionInput
) {
  deleteWorkHistoryAchievementResume(input: $input, condition: $condition) {
    id
    owner
    achievement {
      id
      workHistoryID
      projectName
      coverImage
      description
      topics
      role
      division
      position
      startDate
      endDate
      isContinuation
      createdAt
      updatedAt
      owner
      __typename
    }
    file
    filename
    fileType
    thumbnail
    createdAt
    updatedAt
    workHistoryAchievementUploadsId
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteWorkHistoryAchievementResumeMutationVariables,
    APITypes.DeleteWorkHistoryAchievementResumeMutation
  >;
export const createWorkHistoryDivision =
  /* GraphQL */ `mutation CreateWorkHistoryDivision(
  $input: CreateWorkHistoryDivisionInput!
  $condition: ModelWorkHistoryDivisionConditionInput
) {
  createWorkHistoryDivision(input: $input, condition: $condition) {
    id
    owner
    name
    workHistoryID
    workHistory {
      id
      owner
      startDate
      endDate
      project
      isSideWork
      isCurrentJob
      isLastJob
      isLastSchool
      historyType
      classification
      industry
      position
      isContinuation
      isDropOut
      baseLocationType
      organizationName
      description
      userID
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateWorkHistoryDivisionMutationVariables,
    APITypes.CreateWorkHistoryDivisionMutation
  >;
export const updateWorkHistoryDivision =
  /* GraphQL */ `mutation UpdateWorkHistoryDivision(
  $input: UpdateWorkHistoryDivisionInput!
  $condition: ModelWorkHistoryDivisionConditionInput
) {
  updateWorkHistoryDivision(input: $input, condition: $condition) {
    id
    owner
    name
    workHistoryID
    workHistory {
      id
      owner
      startDate
      endDate
      project
      isSideWork
      isCurrentJob
      isLastJob
      isLastSchool
      historyType
      classification
      industry
      position
      isContinuation
      isDropOut
      baseLocationType
      organizationName
      description
      userID
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateWorkHistoryDivisionMutationVariables,
    APITypes.UpdateWorkHistoryDivisionMutation
  >;
export const deleteWorkHistoryDivision =
  /* GraphQL */ `mutation DeleteWorkHistoryDivision(
  $input: DeleteWorkHistoryDivisionInput!
  $condition: ModelWorkHistoryDivisionConditionInput
) {
  deleteWorkHistoryDivision(input: $input, condition: $condition) {
    id
    owner
    name
    workHistoryID
    workHistory {
      id
      owner
      startDate
      endDate
      project
      isSideWork
      isCurrentJob
      isLastJob
      isLastSchool
      historyType
      classification
      industry
      position
      isContinuation
      isDropOut
      baseLocationType
      organizationName
      description
      userID
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteWorkHistoryDivisionMutationVariables,
    APITypes.DeleteWorkHistoryDivisionMutation
  >;
export const createUserResume = /* GraphQL */ `mutation CreateUserResume(
  $input: CreateUserResumeInput!
  $condition: ModelUserResumeConditionInput
) {
  createUserResume(input: $input, condition: $condition) {
    id
    owner
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    file
    filename
    fileType
    createdAt
    updatedAt
    userResumeFilesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserResumeMutationVariables,
  APITypes.CreateUserResumeMutation
>;
export const updateUserResume = /* GraphQL */ `mutation UpdateUserResume(
  $input: UpdateUserResumeInput!
  $condition: ModelUserResumeConditionInput
) {
  updateUserResume(input: $input, condition: $condition) {
    id
    owner
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    file
    filename
    fileType
    createdAt
    updatedAt
    userResumeFilesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserResumeMutationVariables,
  APITypes.UpdateUserResumeMutation
>;
export const deleteUserResume = /* GraphQL */ `mutation DeleteUserResume(
  $input: DeleteUserResumeInput!
  $condition: ModelUserResumeConditionInput
) {
  deleteUserResume(input: $input, condition: $condition) {
    id
    owner
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    file
    filename
    fileType
    createdAt
    updatedAt
    userResumeFilesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserResumeMutationVariables,
  APITypes.DeleteUserResumeMutation
>;
export const createUserPortfolio = /* GraphQL */ `mutation CreateUserPortfolio(
  $input: CreateUserPortfolioInput!
  $condition: ModelUserPortfolioConditionInput
) {
  createUserPortfolio(input: $input, condition: $condition) {
    id
    owner
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    type
    content
    title
    createdAt
    updatedAt
    userPortfolioFilesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserPortfolioMutationVariables,
  APITypes.CreateUserPortfolioMutation
>;
export const updateUserPortfolio = /* GraphQL */ `mutation UpdateUserPortfolio(
  $input: UpdateUserPortfolioInput!
  $condition: ModelUserPortfolioConditionInput
) {
  updateUserPortfolio(input: $input, condition: $condition) {
    id
    owner
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    type
    content
    title
    createdAt
    updatedAt
    userPortfolioFilesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserPortfolioMutationVariables,
  APITypes.UpdateUserPortfolioMutation
>;
export const deleteUserPortfolio = /* GraphQL */ `mutation DeleteUserPortfolio(
  $input: DeleteUserPortfolioInput!
  $condition: ModelUserPortfolioConditionInput
) {
  deleteUserPortfolio(input: $input, condition: $condition) {
    id
    owner
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    type
    content
    title
    createdAt
    updatedAt
    userPortfolioFilesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserPortfolioMutationVariables,
  APITypes.DeleteUserPortfolioMutation
>;
export const createSkillSet = /* GraphQL */ `mutation CreateSkillSet(
  $input: CreateSkillSetInput!
  $condition: ModelSkillSetConditionInput
) {
  createSkillSet(input: $input, condition: $condition) {
    id
    owner
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    skill {
      id
      type
      position
      name
      createdAt
      updatedAt
      jobSkillsId
      skillCategorySkillsId
      skillJobId
      __typename
    }
    skillTag {
      id
      type
      position
      name
      lead
      createdAt
      updatedAt
      skillSkillTagsId
      __typename
    }
    yearsOfExperience
    createdAt
    updatedAt
    userSkillSetsId
    skillSetSkillId
    skillSetSkillTagId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSkillSetMutationVariables,
  APITypes.CreateSkillSetMutation
>;
export const updateSkillSet = /* GraphQL */ `mutation UpdateSkillSet(
  $input: UpdateSkillSetInput!
  $condition: ModelSkillSetConditionInput
) {
  updateSkillSet(input: $input, condition: $condition) {
    id
    owner
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    skill {
      id
      type
      position
      name
      createdAt
      updatedAt
      jobSkillsId
      skillCategorySkillsId
      skillJobId
      __typename
    }
    skillTag {
      id
      type
      position
      name
      lead
      createdAt
      updatedAt
      skillSkillTagsId
      __typename
    }
    yearsOfExperience
    createdAt
    updatedAt
    userSkillSetsId
    skillSetSkillId
    skillSetSkillTagId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSkillSetMutationVariables,
  APITypes.UpdateSkillSetMutation
>;
export const deleteSkillSet = /* GraphQL */ `mutation DeleteSkillSet(
  $input: DeleteSkillSetInput!
  $condition: ModelSkillSetConditionInput
) {
  deleteSkillSet(input: $input, condition: $condition) {
    id
    owner
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    skill {
      id
      type
      position
      name
      createdAt
      updatedAt
      jobSkillsId
      skillCategorySkillsId
      skillJobId
      __typename
    }
    skillTag {
      id
      type
      position
      name
      lead
      createdAt
      updatedAt
      skillSkillTagsId
      __typename
    }
    yearsOfExperience
    createdAt
    updatedAt
    userSkillSetsId
    skillSetSkillId
    skillSetSkillTagId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSkillSetMutationVariables,
  APITypes.DeleteSkillSetMutation
>;
export const createUserOpenJobs = /* GraphQL */ `mutation CreateUserOpenJobs(
  $input: CreateUserOpenJobsInput!
  $condition: ModelUserOpenJobsConditionInput
) {
  createUserOpenJobs(input: $input, condition: $condition) {
    id
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    openJobID
    openJob {
      id
      organizationName
      consultantName
      owners
      isPublic
      skills
      isDraft
      title
      coverImage
      workType
      numberOfDayPerWeek
      numberOfHoursPerDay
      baseLocationTypes
      baseLocationDetail
      salaryType
      minumumSalary
      maximumSalary
      overView
      issue
      description
      recommendFor
      selectionProcessSupplement
      requiredSkillTags
      requiredSkillTagsDescription
      welcomeSkillTags
      welcomeSkillTagsDescription
      memo
      isHideOrganizationName
      organizationNameForHidden
      isHideSkills
      skillNamesForHidden
      isExcellent
      createdAt
      updatedAt
      organizationOpenJobsId
      openJobJobId
      openJobBaseLocationId
      __typename
    }
    involveType
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserOpenJobsMutationVariables,
  APITypes.CreateUserOpenJobsMutation
>;
export const updateUserOpenJobs = /* GraphQL */ `mutation UpdateUserOpenJobs(
  $input: UpdateUserOpenJobsInput!
  $condition: ModelUserOpenJobsConditionInput
) {
  updateUserOpenJobs(input: $input, condition: $condition) {
    id
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    openJobID
    openJob {
      id
      organizationName
      consultantName
      owners
      isPublic
      skills
      isDraft
      title
      coverImage
      workType
      numberOfDayPerWeek
      numberOfHoursPerDay
      baseLocationTypes
      baseLocationDetail
      salaryType
      minumumSalary
      maximumSalary
      overView
      issue
      description
      recommendFor
      selectionProcessSupplement
      requiredSkillTags
      requiredSkillTagsDescription
      welcomeSkillTags
      welcomeSkillTagsDescription
      memo
      isHideOrganizationName
      organizationNameForHidden
      isHideSkills
      skillNamesForHidden
      isExcellent
      createdAt
      updatedAt
      organizationOpenJobsId
      openJobJobId
      openJobBaseLocationId
      __typename
    }
    involveType
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserOpenJobsMutationVariables,
  APITypes.UpdateUserOpenJobsMutation
>;
export const deleteUserOpenJobs = /* GraphQL */ `mutation DeleteUserOpenJobs(
  $input: DeleteUserOpenJobsInput!
  $condition: ModelUserOpenJobsConditionInput
) {
  deleteUserOpenJobs(input: $input, condition: $condition) {
    id
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    openJobID
    openJob {
      id
      organizationName
      consultantName
      owners
      isPublic
      skills
      isDraft
      title
      coverImage
      workType
      numberOfDayPerWeek
      numberOfHoursPerDay
      baseLocationTypes
      baseLocationDetail
      salaryType
      minumumSalary
      maximumSalary
      overView
      issue
      description
      recommendFor
      selectionProcessSupplement
      requiredSkillTags
      requiredSkillTagsDescription
      welcomeSkillTags
      welcomeSkillTagsDescription
      memo
      isHideOrganizationName
      organizationNameForHidden
      isHideSkills
      skillNamesForHidden
      isExcellent
      createdAt
      updatedAt
      organizationOpenJobsId
      openJobJobId
      openJobBaseLocationId
      __typename
    }
    involveType
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserOpenJobsMutationVariables,
  APITypes.DeleteUserOpenJobsMutation
>;
export const createOpenJob = /* GraphQL */ `mutation CreateOpenJob(
  $input: CreateOpenJobInput!
  $condition: ModelOpenJobConditionInput
) {
  createOpenJob(input: $input, condition: $condition) {
    id
    organization {
      id
      owners
      name
      organizationNameForHidden
      isHideOrganizationName
      icon
      description
      createdAt
      updatedAt
      __typename
    }
    job {
      id
      type
      position
      name
      color
      createdAt
      updatedAt
      __typename
    }
    jobs {
      nextToken
      __typename
    }
    users {
      nextToken
      __typename
    }
    organizationName
    consultantName
    owners
    isPublic
    skills
    isDraft
    title
    coverImage
    workType
    numberOfDayPerWeek
    numberOfHoursPerDay
    baseLocationTypes
    baseLocation {
      id
      type
      position
      name
      area
      createdAt
      updatedAt
      owner
      __typename
    }
    baseLocationDetail
    salaryType
    minumumSalary
    maximumSalary
    overView
    issue
    description
    recommendFor
    selectionProcess {
      nextToken
      __typename
    }
    selectionProcessSupplement
    requiredSkillTags
    requiredSkillTagsDescription
    welcomeSkillTags
    welcomeSkillTagsDescription
    favorites {
      nextToken
      __typename
    }
    applyUsers {
      nextToken
      __typename
    }
    conversations {
      nextToken
      __typename
    }
    memo
    isHideOrganizationName
    organizationNameForHidden
    isHideSkills
    skillNamesForHidden
    isExcellent
    skillbaWorkHistories {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    organizationOpenJobsId
    openJobJobId
    openJobBaseLocationId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOpenJobMutationVariables,
  APITypes.CreateOpenJobMutation
>;
export const updateOpenJob = /* GraphQL */ `mutation UpdateOpenJob(
  $input: UpdateOpenJobInput!
  $condition: ModelOpenJobConditionInput
) {
  updateOpenJob(input: $input, condition: $condition) {
    id
    organization {
      id
      owners
      name
      organizationNameForHidden
      isHideOrganizationName
      icon
      description
      createdAt
      updatedAt
      __typename
    }
    job {
      id
      type
      position
      name
      color
      createdAt
      updatedAt
      __typename
    }
    jobs {
      nextToken
      __typename
    }
    users {
      nextToken
      __typename
    }
    organizationName
    consultantName
    owners
    isPublic
    skills
    isDraft
    title
    coverImage
    workType
    numberOfDayPerWeek
    numberOfHoursPerDay
    baseLocationTypes
    baseLocation {
      id
      type
      position
      name
      area
      createdAt
      updatedAt
      owner
      __typename
    }
    baseLocationDetail
    salaryType
    minumumSalary
    maximumSalary
    overView
    issue
    description
    recommendFor
    selectionProcess {
      nextToken
      __typename
    }
    selectionProcessSupplement
    requiredSkillTags
    requiredSkillTagsDescription
    welcomeSkillTags
    welcomeSkillTagsDescription
    favorites {
      nextToken
      __typename
    }
    applyUsers {
      nextToken
      __typename
    }
    conversations {
      nextToken
      __typename
    }
    memo
    isHideOrganizationName
    organizationNameForHidden
    isHideSkills
    skillNamesForHidden
    isExcellent
    skillbaWorkHistories {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    organizationOpenJobsId
    openJobJobId
    openJobBaseLocationId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOpenJobMutationVariables,
  APITypes.UpdateOpenJobMutation
>;
export const deleteOpenJob = /* GraphQL */ `mutation DeleteOpenJob(
  $input: DeleteOpenJobInput!
  $condition: ModelOpenJobConditionInput
) {
  deleteOpenJob(input: $input, condition: $condition) {
    id
    organization {
      id
      owners
      name
      organizationNameForHidden
      isHideOrganizationName
      icon
      description
      createdAt
      updatedAt
      __typename
    }
    job {
      id
      type
      position
      name
      color
      createdAt
      updatedAt
      __typename
    }
    jobs {
      nextToken
      __typename
    }
    users {
      nextToken
      __typename
    }
    organizationName
    consultantName
    owners
    isPublic
    skills
    isDraft
    title
    coverImage
    workType
    numberOfDayPerWeek
    numberOfHoursPerDay
    baseLocationTypes
    baseLocation {
      id
      type
      position
      name
      area
      createdAt
      updatedAt
      owner
      __typename
    }
    baseLocationDetail
    salaryType
    minumumSalary
    maximumSalary
    overView
    issue
    description
    recommendFor
    selectionProcess {
      nextToken
      __typename
    }
    selectionProcessSupplement
    requiredSkillTags
    requiredSkillTagsDescription
    welcomeSkillTags
    welcomeSkillTagsDescription
    favorites {
      nextToken
      __typename
    }
    applyUsers {
      nextToken
      __typename
    }
    conversations {
      nextToken
      __typename
    }
    memo
    isHideOrganizationName
    organizationNameForHidden
    isHideSkills
    skillNamesForHidden
    isExcellent
    skillbaWorkHistories {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    organizationOpenJobsId
    openJobJobId
    openJobBaseLocationId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOpenJobMutationVariables,
  APITypes.DeleteOpenJobMutation
>;
export const createSelectionProcess =
  /* GraphQL */ `mutation CreateSelectionProcess(
  $input: CreateSelectionProcessInput!
  $condition: ModelSelectionProcessConditionInput
) {
  createSelectionProcess(input: $input, condition: $condition) {
    id
    owner
    openJobID
    openJob {
      id
      organizationName
      consultantName
      owners
      isPublic
      skills
      isDraft
      title
      coverImage
      workType
      numberOfDayPerWeek
      numberOfHoursPerDay
      baseLocationTypes
      baseLocationDetail
      salaryType
      minumumSalary
      maximumSalary
      overView
      issue
      description
      recommendFor
      selectionProcessSupplement
      requiredSkillTags
      requiredSkillTagsDescription
      welcomeSkillTags
      welcomeSkillTagsDescription
      memo
      isHideOrganizationName
      organizationNameForHidden
      isHideSkills
      skillNamesForHidden
      isExcellent
      createdAt
      updatedAt
      organizationOpenJobsId
      openJobJobId
      openJobBaseLocationId
      __typename
    }
    step
    title
    description
    createdAt
    updatedAt
    openJobSelectionProcessId
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateSelectionProcessMutationVariables,
    APITypes.CreateSelectionProcessMutation
  >;
export const updateSelectionProcess =
  /* GraphQL */ `mutation UpdateSelectionProcess(
  $input: UpdateSelectionProcessInput!
  $condition: ModelSelectionProcessConditionInput
) {
  updateSelectionProcess(input: $input, condition: $condition) {
    id
    owner
    openJobID
    openJob {
      id
      organizationName
      consultantName
      owners
      isPublic
      skills
      isDraft
      title
      coverImage
      workType
      numberOfDayPerWeek
      numberOfHoursPerDay
      baseLocationTypes
      baseLocationDetail
      salaryType
      minumumSalary
      maximumSalary
      overView
      issue
      description
      recommendFor
      selectionProcessSupplement
      requiredSkillTags
      requiredSkillTagsDescription
      welcomeSkillTags
      welcomeSkillTagsDescription
      memo
      isHideOrganizationName
      organizationNameForHidden
      isHideSkills
      skillNamesForHidden
      isExcellent
      createdAt
      updatedAt
      organizationOpenJobsId
      openJobJobId
      openJobBaseLocationId
      __typename
    }
    step
    title
    description
    createdAt
    updatedAt
    openJobSelectionProcessId
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateSelectionProcessMutationVariables,
    APITypes.UpdateSelectionProcessMutation
  >;
export const deleteSelectionProcess =
  /* GraphQL */ `mutation DeleteSelectionProcess(
  $input: DeleteSelectionProcessInput!
  $condition: ModelSelectionProcessConditionInput
) {
  deleteSelectionProcess(input: $input, condition: $condition) {
    id
    owner
    openJobID
    openJob {
      id
      organizationName
      consultantName
      owners
      isPublic
      skills
      isDraft
      title
      coverImage
      workType
      numberOfDayPerWeek
      numberOfHoursPerDay
      baseLocationTypes
      baseLocationDetail
      salaryType
      minumumSalary
      maximumSalary
      overView
      issue
      description
      recommendFor
      selectionProcessSupplement
      requiredSkillTags
      requiredSkillTagsDescription
      welcomeSkillTags
      welcomeSkillTagsDescription
      memo
      isHideOrganizationName
      organizationNameForHidden
      isHideSkills
      skillNamesForHidden
      isExcellent
      createdAt
      updatedAt
      organizationOpenJobsId
      openJobJobId
      openJobBaseLocationId
      __typename
    }
    step
    title
    description
    createdAt
    updatedAt
    openJobSelectionProcessId
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteSelectionProcessMutationVariables,
    APITypes.DeleteSelectionProcessMutation
  >;
export const createUserFavoriteOpenJobs =
  /* GraphQL */ `mutation CreateUserFavoriteOpenJobs(
  $input: CreateUserFavoriteOpenJobsInput!
  $condition: ModelUserFavoriteOpenJobsConditionInput
) {
  createUserFavoriteOpenJobs(input: $input, condition: $condition) {
    id
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    openJobID
    openJob {
      id
      organizationName
      consultantName
      owners
      isPublic
      skills
      isDraft
      title
      coverImage
      workType
      numberOfDayPerWeek
      numberOfHoursPerDay
      baseLocationTypes
      baseLocationDetail
      salaryType
      minumumSalary
      maximumSalary
      overView
      issue
      description
      recommendFor
      selectionProcessSupplement
      requiredSkillTags
      requiredSkillTagsDescription
      welcomeSkillTags
      welcomeSkillTagsDescription
      memo
      isHideOrganizationName
      organizationNameForHidden
      isHideSkills
      skillNamesForHidden
      isExcellent
      createdAt
      updatedAt
      organizationOpenJobsId
      openJobJobId
      openJobBaseLocationId
      __typename
    }
    isFavorite
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateUserFavoriteOpenJobsMutationVariables,
    APITypes.CreateUserFavoriteOpenJobsMutation
  >;
export const updateUserFavoriteOpenJobs =
  /* GraphQL */ `mutation UpdateUserFavoriteOpenJobs(
  $input: UpdateUserFavoriteOpenJobsInput!
  $condition: ModelUserFavoriteOpenJobsConditionInput
) {
  updateUserFavoriteOpenJobs(input: $input, condition: $condition) {
    id
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    openJobID
    openJob {
      id
      organizationName
      consultantName
      owners
      isPublic
      skills
      isDraft
      title
      coverImage
      workType
      numberOfDayPerWeek
      numberOfHoursPerDay
      baseLocationTypes
      baseLocationDetail
      salaryType
      minumumSalary
      maximumSalary
      overView
      issue
      description
      recommendFor
      selectionProcessSupplement
      requiredSkillTags
      requiredSkillTagsDescription
      welcomeSkillTags
      welcomeSkillTagsDescription
      memo
      isHideOrganizationName
      organizationNameForHidden
      isHideSkills
      skillNamesForHidden
      isExcellent
      createdAt
      updatedAt
      organizationOpenJobsId
      openJobJobId
      openJobBaseLocationId
      __typename
    }
    isFavorite
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateUserFavoriteOpenJobsMutationVariables,
    APITypes.UpdateUserFavoriteOpenJobsMutation
  >;
export const deleteUserFavoriteOpenJobs =
  /* GraphQL */ `mutation DeleteUserFavoriteOpenJobs(
  $input: DeleteUserFavoriteOpenJobsInput!
  $condition: ModelUserFavoriteOpenJobsConditionInput
) {
  deleteUserFavoriteOpenJobs(input: $input, condition: $condition) {
    id
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    openJobID
    openJob {
      id
      organizationName
      consultantName
      owners
      isPublic
      skills
      isDraft
      title
      coverImage
      workType
      numberOfDayPerWeek
      numberOfHoursPerDay
      baseLocationTypes
      baseLocationDetail
      salaryType
      minumumSalary
      maximumSalary
      overView
      issue
      description
      recommendFor
      selectionProcessSupplement
      requiredSkillTags
      requiredSkillTagsDescription
      welcomeSkillTags
      welcomeSkillTagsDescription
      memo
      isHideOrganizationName
      organizationNameForHidden
      isHideSkills
      skillNamesForHidden
      isExcellent
      createdAt
      updatedAt
      organizationOpenJobsId
      openJobJobId
      openJobBaseLocationId
      __typename
    }
    isFavorite
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteUserFavoriteOpenJobsMutationVariables,
    APITypes.DeleteUserFavoriteOpenJobsMutation
  >;
export const createUserPrefactures =
  /* GraphQL */ `mutation CreateUserPrefactures(
  $input: CreateUserPrefacturesInput!
  $condition: ModelUserPrefacturesConditionInput
) {
  createUserPrefactures(input: $input, condition: $condition) {
    id
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    prefactureID
    prefacture {
      id
      type
      position
      name
      area
      createdAt
      updatedAt
      owner
      __typename
    }
    isSelected
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateUserPrefacturesMutationVariables,
    APITypes.CreateUserPrefacturesMutation
  >;
export const updateUserPrefactures =
  /* GraphQL */ `mutation UpdateUserPrefactures(
  $input: UpdateUserPrefacturesInput!
  $condition: ModelUserPrefacturesConditionInput
) {
  updateUserPrefactures(input: $input, condition: $condition) {
    id
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    prefactureID
    prefacture {
      id
      type
      position
      name
      area
      createdAt
      updatedAt
      owner
      __typename
    }
    isSelected
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateUserPrefacturesMutationVariables,
    APITypes.UpdateUserPrefacturesMutation
  >;
export const deleteUserPrefactures =
  /* GraphQL */ `mutation DeleteUserPrefactures(
  $input: DeleteUserPrefacturesInput!
  $condition: ModelUserPrefacturesConditionInput
) {
  deleteUserPrefactures(input: $input, condition: $condition) {
    id
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    prefactureID
    prefacture {
      id
      type
      position
      name
      area
      createdAt
      updatedAt
      owner
      __typename
    }
    isSelected
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteUserPrefacturesMutationVariables,
    APITypes.DeleteUserPrefacturesMutation
  >;
export const createUserApplies = /* GraphQL */ `mutation CreateUserApplies(
  $input: CreateUserAppliesInput!
  $condition: ModelUserAppliesConditionInput
) {
  createUserApplies(input: $input, condition: $condition) {
    id
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    openJobID
    openJob {
      id
      organizationName
      consultantName
      owners
      isPublic
      skills
      isDraft
      title
      coverImage
      workType
      numberOfDayPerWeek
      numberOfHoursPerDay
      baseLocationTypes
      baseLocationDetail
      salaryType
      minumumSalary
      maximumSalary
      overView
      issue
      description
      recommendFor
      selectionProcessSupplement
      requiredSkillTags
      requiredSkillTagsDescription
      welcomeSkillTags
      welcomeSkillTagsDescription
      memo
      isHideOrganizationName
      organizationNameForHidden
      isHideSkills
      skillNamesForHidden
      isExcellent
      createdAt
      updatedAt
      organizationOpenJobsId
      openJobJobId
      openJobBaseLocationId
      __typename
    }
    type
    url
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserAppliesMutationVariables,
  APITypes.CreateUserAppliesMutation
>;
export const updateUserApplies = /* GraphQL */ `mutation UpdateUserApplies(
  $input: UpdateUserAppliesInput!
  $condition: ModelUserAppliesConditionInput
) {
  updateUserApplies(input: $input, condition: $condition) {
    id
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    openJobID
    openJob {
      id
      organizationName
      consultantName
      owners
      isPublic
      skills
      isDraft
      title
      coverImage
      workType
      numberOfDayPerWeek
      numberOfHoursPerDay
      baseLocationTypes
      baseLocationDetail
      salaryType
      minumumSalary
      maximumSalary
      overView
      issue
      description
      recommendFor
      selectionProcessSupplement
      requiredSkillTags
      requiredSkillTagsDescription
      welcomeSkillTags
      welcomeSkillTagsDescription
      memo
      isHideOrganizationName
      organizationNameForHidden
      isHideSkills
      skillNamesForHidden
      isExcellent
      createdAt
      updatedAt
      organizationOpenJobsId
      openJobJobId
      openJobBaseLocationId
      __typename
    }
    type
    url
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserAppliesMutationVariables,
  APITypes.UpdateUserAppliesMutation
>;
export const deleteUserApplies = /* GraphQL */ `mutation DeleteUserApplies(
  $input: DeleteUserAppliesInput!
  $condition: ModelUserAppliesConditionInput
) {
  deleteUserApplies(input: $input, condition: $condition) {
    id
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    openJobID
    openJob {
      id
      organizationName
      consultantName
      owners
      isPublic
      skills
      isDraft
      title
      coverImage
      workType
      numberOfDayPerWeek
      numberOfHoursPerDay
      baseLocationTypes
      baseLocationDetail
      salaryType
      minumumSalary
      maximumSalary
      overView
      issue
      description
      recommendFor
      selectionProcessSupplement
      requiredSkillTags
      requiredSkillTagsDescription
      welcomeSkillTags
      welcomeSkillTagsDescription
      memo
      isHideOrganizationName
      organizationNameForHidden
      isHideSkills
      skillNamesForHidden
      isExcellent
      createdAt
      updatedAt
      organizationOpenJobsId
      openJobJobId
      openJobBaseLocationId
      __typename
    }
    type
    url
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserAppliesMutationVariables,
  APITypes.DeleteUserAppliesMutation
>;
export const createEmployeeBenefit =
  /* GraphQL */ `mutation CreateEmployeeBenefit(
  $input: CreateEmployeeBenefitInput!
  $condition: ModelEmployeeBenefitConditionInput
) {
  createEmployeeBenefit(input: $input, condition: $condition) {
    id
    title
    organization {
      id
      name
      icon
      createdAt
      updatedAt
      __typename
    }
    type
    tags
    useProcesses
    usedUsers {
      nextToken
      __typename
    }
    coverImage
    couponCode
    url
    isDraft
    createdAt
    updatedAt
    employeeBenefitOrganizationEmployeeBenefitsId
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateEmployeeBenefitMutationVariables,
    APITypes.CreateEmployeeBenefitMutation
  >;
export const updateEmployeeBenefit =
  /* GraphQL */ `mutation UpdateEmployeeBenefit(
  $input: UpdateEmployeeBenefitInput!
  $condition: ModelEmployeeBenefitConditionInput
) {
  updateEmployeeBenefit(input: $input, condition: $condition) {
    id
    title
    organization {
      id
      name
      icon
      createdAt
      updatedAt
      __typename
    }
    type
    tags
    useProcesses
    usedUsers {
      nextToken
      __typename
    }
    coverImage
    couponCode
    url
    isDraft
    createdAt
    updatedAt
    employeeBenefitOrganizationEmployeeBenefitsId
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateEmployeeBenefitMutationVariables,
    APITypes.UpdateEmployeeBenefitMutation
  >;
export const deleteEmployeeBenefit =
  /* GraphQL */ `mutation DeleteEmployeeBenefit(
  $input: DeleteEmployeeBenefitInput!
  $condition: ModelEmployeeBenefitConditionInput
) {
  deleteEmployeeBenefit(input: $input, condition: $condition) {
    id
    title
    organization {
      id
      name
      icon
      createdAt
      updatedAt
      __typename
    }
    type
    tags
    useProcesses
    usedUsers {
      nextToken
      __typename
    }
    coverImage
    couponCode
    url
    isDraft
    createdAt
    updatedAt
    employeeBenefitOrganizationEmployeeBenefitsId
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteEmployeeBenefitMutationVariables,
    APITypes.DeleteEmployeeBenefitMutation
  >;
export const createEmployeeBenefitTag =
  /* GraphQL */ `mutation CreateEmployeeBenefitTag(
  $input: CreateEmployeeBenefitTagInput!
  $condition: ModelEmployeeBenefitTagConditionInput
) {
  createEmployeeBenefitTag(input: $input, condition: $condition) {
    id
    name
    type
    position
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateEmployeeBenefitTagMutationVariables,
    APITypes.CreateEmployeeBenefitTagMutation
  >;
export const updateEmployeeBenefitTag =
  /* GraphQL */ `mutation UpdateEmployeeBenefitTag(
  $input: UpdateEmployeeBenefitTagInput!
  $condition: ModelEmployeeBenefitTagConditionInput
) {
  updateEmployeeBenefitTag(input: $input, condition: $condition) {
    id
    name
    type
    position
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateEmployeeBenefitTagMutationVariables,
    APITypes.UpdateEmployeeBenefitTagMutation
  >;
export const deleteEmployeeBenefitTag =
  /* GraphQL */ `mutation DeleteEmployeeBenefitTag(
  $input: DeleteEmployeeBenefitTagInput!
  $condition: ModelEmployeeBenefitTagConditionInput
) {
  deleteEmployeeBenefitTag(input: $input, condition: $condition) {
    id
    name
    type
    position
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteEmployeeBenefitTagMutationVariables,
    APITypes.DeleteEmployeeBenefitTagMutation
  >;
export const createEmployeeBenefitOrganization =
  /* GraphQL */ `mutation CreateEmployeeBenefitOrganization(
  $input: CreateEmployeeBenefitOrganizationInput!
  $condition: ModelEmployeeBenefitOrganizationConditionInput
) {
  createEmployeeBenefitOrganization(input: $input, condition: $condition) {
    id
    name
    icon
    employeeBenefits {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateEmployeeBenefitOrganizationMutationVariables,
    APITypes.CreateEmployeeBenefitOrganizationMutation
  >;
export const updateEmployeeBenefitOrganization =
  /* GraphQL */ `mutation UpdateEmployeeBenefitOrganization(
  $input: UpdateEmployeeBenefitOrganizationInput!
  $condition: ModelEmployeeBenefitOrganizationConditionInput
) {
  updateEmployeeBenefitOrganization(input: $input, condition: $condition) {
    id
    name
    icon
    employeeBenefits {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateEmployeeBenefitOrganizationMutationVariables,
    APITypes.UpdateEmployeeBenefitOrganizationMutation
  >;
export const deleteEmployeeBenefitOrganization =
  /* GraphQL */ `mutation DeleteEmployeeBenefitOrganization(
  $input: DeleteEmployeeBenefitOrganizationInput!
  $condition: ModelEmployeeBenefitOrganizationConditionInput
) {
  deleteEmployeeBenefitOrganization(input: $input, condition: $condition) {
    id
    name
    icon
    employeeBenefits {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteEmployeeBenefitOrganizationMutationVariables,
    APITypes.DeleteEmployeeBenefitOrganizationMutation
  >;
export const createUserEmployeeBenefits =
  /* GraphQL */ `mutation CreateUserEmployeeBenefits(
  $input: CreateUserEmployeeBenefitsInput!
  $condition: ModelUserEmployeeBenefitsConditionInput
) {
  createUserEmployeeBenefits(input: $input, condition: $condition) {
    id
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    employeeBenefitID
    employeeBenefit {
      id
      title
      type
      tags
      useProcesses
      coverImage
      couponCode
      url
      isDraft
      createdAt
      updatedAt
      employeeBenefitOrganizationEmployeeBenefitsId
      __typename
    }
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateUserEmployeeBenefitsMutationVariables,
    APITypes.CreateUserEmployeeBenefitsMutation
  >;
export const updateUserEmployeeBenefits =
  /* GraphQL */ `mutation UpdateUserEmployeeBenefits(
  $input: UpdateUserEmployeeBenefitsInput!
  $condition: ModelUserEmployeeBenefitsConditionInput
) {
  updateUserEmployeeBenefits(input: $input, condition: $condition) {
    id
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    employeeBenefitID
    employeeBenefit {
      id
      title
      type
      tags
      useProcesses
      coverImage
      couponCode
      url
      isDraft
      createdAt
      updatedAt
      employeeBenefitOrganizationEmployeeBenefitsId
      __typename
    }
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateUserEmployeeBenefitsMutationVariables,
    APITypes.UpdateUserEmployeeBenefitsMutation
  >;
export const deleteUserEmployeeBenefits =
  /* GraphQL */ `mutation DeleteUserEmployeeBenefits(
  $input: DeleteUserEmployeeBenefitsInput!
  $condition: ModelUserEmployeeBenefitsConditionInput
) {
  deleteUserEmployeeBenefits(input: $input, condition: $condition) {
    id
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    employeeBenefitID
    employeeBenefit {
      id
      title
      type
      tags
      useProcesses
      coverImage
      couponCode
      url
      isDraft
      createdAt
      updatedAt
      employeeBenefitOrganizationEmployeeBenefitsId
      __typename
    }
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteUserEmployeeBenefitsMutationVariables,
    APITypes.DeleteUserEmployeeBenefitsMutation
  >;
export const createEvaluation = /* GraphQL */ `mutation CreateEvaluation(
  $input: CreateEvaluationInput!
  $condition: ModelEvaluationConditionInput
) {
  createEvaluation(input: $input, condition: $condition) {
    id
    informationGatheringRating
    analysisRating
    writeDocumentsRating
    communicationRating
    presentationRating
    totalRating
    published
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    picName
    picId
    picOrganizationName
    picComment
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEvaluationMutationVariables,
  APITypes.CreateEvaluationMutation
>;
export const updateEvaluation = /* GraphQL */ `mutation UpdateEvaluation(
  $input: UpdateEvaluationInput!
  $condition: ModelEvaluationConditionInput
) {
  updateEvaluation(input: $input, condition: $condition) {
    id
    informationGatheringRating
    analysisRating
    writeDocumentsRating
    communicationRating
    presentationRating
    totalRating
    published
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    picName
    picId
    picOrganizationName
    picComment
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEvaluationMutationVariables,
  APITypes.UpdateEvaluationMutation
>;
export const deleteEvaluation = /* GraphQL */ `mutation DeleteEvaluation(
  $input: DeleteEvaluationInput!
  $condition: ModelEvaluationConditionInput
) {
  deleteEvaluation(input: $input, condition: $condition) {
    id
    informationGatheringRating
    analysisRating
    writeDocumentsRating
    communicationRating
    presentationRating
    totalRating
    published
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    picName
    picId
    picOrganizationName
    picComment
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEvaluationMutationVariables,
  APITypes.DeleteEvaluationMutation
>;
export const createEvaluationComment =
  /* GraphQL */ `mutation CreateEvaluationComment(
  $input: CreateEvaluationCommentInput!
  $condition: ModelEvaluationCommentConditionInput
) {
  createEvaluationComment(input: $input, condition: $condition) {
    id
    message
    rate
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    commentatorID
    commentator {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateEvaluationCommentMutationVariables,
    APITypes.CreateEvaluationCommentMutation
  >;
export const updateEvaluationComment =
  /* GraphQL */ `mutation UpdateEvaluationComment(
  $input: UpdateEvaluationCommentInput!
  $condition: ModelEvaluationCommentConditionInput
) {
  updateEvaluationComment(input: $input, condition: $condition) {
    id
    message
    rate
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    commentatorID
    commentator {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateEvaluationCommentMutationVariables,
    APITypes.UpdateEvaluationCommentMutation
  >;
export const deleteEvaluationComment =
  /* GraphQL */ `mutation DeleteEvaluationComment(
  $input: DeleteEvaluationCommentInput!
  $condition: ModelEvaluationCommentConditionInput
) {
  deleteEvaluationComment(input: $input, condition: $condition) {
    id
    message
    rate
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    commentatorID
    commentator {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteEvaluationCommentMutationVariables,
    APITypes.DeleteEvaluationCommentMutation
  >;
export const createWPWorker = /* GraphQL */ `mutation CreateWPWorker(
  $input: CreateWPWorkerInput!
  $condition: ModelWPWorkerConditionInput
) {
  createWPWorker(input: $input, condition: $condition) {
    id
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    categories
    status
    url
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateWPWorkerMutationVariables,
  APITypes.CreateWPWorkerMutation
>;
export const updateWPWorker = /* GraphQL */ `mutation UpdateWPWorker(
  $input: UpdateWPWorkerInput!
  $condition: ModelWPWorkerConditionInput
) {
  updateWPWorker(input: $input, condition: $condition) {
    id
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    categories
    status
    url
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateWPWorkerMutationVariables,
  APITypes.UpdateWPWorkerMutation
>;
export const deleteWPWorker = /* GraphQL */ `mutation DeleteWPWorker(
  $input: DeleteWPWorkerInput!
  $condition: ModelWPWorkerConditionInput
) {
  deleteWPWorker(input: $input, condition: $condition) {
    id
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    categories
    status
    url
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteWPWorkerMutationVariables,
  APITypes.DeleteWPWorkerMutation
>;
export const createWPWorkerCategory =
  /* GraphQL */ `mutation CreateWPWorkerCategory(
  $input: CreateWPWorkerCategoryInput!
  $condition: ModelWPWorkerCategoryConditionInput
) {
  createWPWorkerCategory(input: $input, condition: $condition) {
    id
    name
    url
    coverImage
    description
    wpWorkers
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateWPWorkerCategoryMutationVariables,
    APITypes.CreateWPWorkerCategoryMutation
  >;
export const updateWPWorkerCategory =
  /* GraphQL */ `mutation UpdateWPWorkerCategory(
  $input: UpdateWPWorkerCategoryInput!
  $condition: ModelWPWorkerCategoryConditionInput
) {
  updateWPWorkerCategory(input: $input, condition: $condition) {
    id
    name
    url
    coverImage
    description
    wpWorkers
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateWPWorkerCategoryMutationVariables,
    APITypes.UpdateWPWorkerCategoryMutation
  >;
export const deleteWPWorkerCategory =
  /* GraphQL */ `mutation DeleteWPWorkerCategory(
  $input: DeleteWPWorkerCategoryInput!
  $condition: ModelWPWorkerCategoryConditionInput
) {
  deleteWPWorkerCategory(input: $input, condition: $condition) {
    id
    name
    url
    coverImage
    description
    wpWorkers
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteWPWorkerCategoryMutationVariables,
    APITypes.DeleteWPWorkerCategoryMutation
  >;
export const createQualification = /* GraphQL */ `mutation CreateQualification(
  $input: CreateQualificationInput!
  $condition: ModelQualificationConditionInput
) {
  createQualification(input: $input, condition: $condition) {
    id
    owners
    name
    organization
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    releaseDate
    expirationDate
    isNoExpiry
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateQualificationMutationVariables,
  APITypes.CreateQualificationMutation
>;
export const updateQualification = /* GraphQL */ `mutation UpdateQualification(
  $input: UpdateQualificationInput!
  $condition: ModelQualificationConditionInput
) {
  updateQualification(input: $input, condition: $condition) {
    id
    owners
    name
    organization
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    releaseDate
    expirationDate
    isNoExpiry
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateQualificationMutationVariables,
  APITypes.UpdateQualificationMutation
>;
export const deleteQualification = /* GraphQL */ `mutation DeleteQualification(
  $input: DeleteQualificationInput!
  $condition: ModelQualificationConditionInput
) {
  deleteQualification(input: $input, condition: $condition) {
    id
    owners
    name
    organization
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    releaseDate
    expirationDate
    isNoExpiry
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteQualificationMutationVariables,
  APITypes.DeleteQualificationMutation
>;
export const createConversation = /* GraphQL */ `mutation CreateConversation(
  $input: CreateConversationInput!
  $condition: ModelConversationConditionInput
) {
  createConversation(input: $input, condition: $condition) {
    id
    owners
    messages {
      nextToken
      __typename
    }
    name
    associated {
      nextToken
      __typename
    }
    openJob {
      id
      organizationName
      consultantName
      owners
      isPublic
      skills
      isDraft
      title
      coverImage
      workType
      numberOfDayPerWeek
      numberOfHoursPerDay
      baseLocationTypes
      baseLocationDetail
      salaryType
      minumumSalary
      maximumSalary
      overView
      issue
      description
      recommendFor
      selectionProcessSupplement
      requiredSkillTags
      requiredSkillTagsDescription
      welcomeSkillTags
      welcomeSkillTagsDescription
      memo
      isHideOrganizationName
      organizationNameForHidden
      isHideSkills
      skillNamesForHidden
      isExcellent
      createdAt
      updatedAt
      organizationOpenJobsId
      openJobJobId
      openJobBaseLocationId
      __typename
    }
    createdAt
    updatedAt
    openJobConversationsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateConversationMutationVariables,
  APITypes.CreateConversationMutation
>;
export const updateConversation = /* GraphQL */ `mutation UpdateConversation(
  $input: UpdateConversationInput!
  $condition: ModelConversationConditionInput
) {
  updateConversation(input: $input, condition: $condition) {
    id
    owners
    messages {
      nextToken
      __typename
    }
    name
    associated {
      nextToken
      __typename
    }
    openJob {
      id
      organizationName
      consultantName
      owners
      isPublic
      skills
      isDraft
      title
      coverImage
      workType
      numberOfDayPerWeek
      numberOfHoursPerDay
      baseLocationTypes
      baseLocationDetail
      salaryType
      minumumSalary
      maximumSalary
      overView
      issue
      description
      recommendFor
      selectionProcessSupplement
      requiredSkillTags
      requiredSkillTagsDescription
      welcomeSkillTags
      welcomeSkillTagsDescription
      memo
      isHideOrganizationName
      organizationNameForHidden
      isHideSkills
      skillNamesForHidden
      isExcellent
      createdAt
      updatedAt
      organizationOpenJobsId
      openJobJobId
      openJobBaseLocationId
      __typename
    }
    createdAt
    updatedAt
    openJobConversationsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateConversationMutationVariables,
  APITypes.UpdateConversationMutation
>;
export const deleteConversation = /* GraphQL */ `mutation DeleteConversation(
  $input: DeleteConversationInput!
  $condition: ModelConversationConditionInput
) {
  deleteConversation(input: $input, condition: $condition) {
    id
    owners
    messages {
      nextToken
      __typename
    }
    name
    associated {
      nextToken
      __typename
    }
    openJob {
      id
      organizationName
      consultantName
      owners
      isPublic
      skills
      isDraft
      title
      coverImage
      workType
      numberOfDayPerWeek
      numberOfHoursPerDay
      baseLocationTypes
      baseLocationDetail
      salaryType
      minumumSalary
      maximumSalary
      overView
      issue
      description
      recommendFor
      selectionProcessSupplement
      requiredSkillTags
      requiredSkillTagsDescription
      welcomeSkillTags
      welcomeSkillTagsDescription
      memo
      isHideOrganizationName
      organizationNameForHidden
      isHideSkills
      skillNamesForHidden
      isExcellent
      createdAt
      updatedAt
      organizationOpenJobsId
      openJobJobId
      openJobBaseLocationId
      __typename
    }
    createdAt
    updatedAt
    openJobConversationsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteConversationMutationVariables,
  APITypes.DeleteConversationMutation
>;
export const createMessage = /* GraphQL */ `mutation CreateMessage(
  $input: CreateMessageInput!
  $condition: ModelMessageConditionInput
) {
  createMessage(input: $input, condition: $condition) {
    id
    authorId
    author {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    content
    conversationId
    conversation {
      id
      owners
      name
      createdAt
      updatedAt
      openJobConversationsId
      __typename
    }
    createdAt
    isSent
    isEvent
    messageReads {
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMessageMutationVariables,
  APITypes.CreateMessageMutation
>;
export const updateMessage = /* GraphQL */ `mutation UpdateMessage(
  $input: UpdateMessageInput!
  $condition: ModelMessageConditionInput
) {
  updateMessage(input: $input, condition: $condition) {
    id
    authorId
    author {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    content
    conversationId
    conversation {
      id
      owners
      name
      createdAt
      updatedAt
      openJobConversationsId
      __typename
    }
    createdAt
    isSent
    isEvent
    messageReads {
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMessageMutationVariables,
  APITypes.UpdateMessageMutation
>;
export const deleteMessage = /* GraphQL */ `mutation DeleteMessage(
  $input: DeleteMessageInput!
  $condition: ModelMessageConditionInput
) {
  deleteMessage(input: $input, condition: $condition) {
    id
    authorId
    author {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    content
    conversationId
    conversation {
      id
      owners
      name
      createdAt
      updatedAt
      openJobConversationsId
      __typename
    }
    createdAt
    isSent
    isEvent
    messageReads {
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMessageMutationVariables,
  APITypes.DeleteMessageMutation
>;
export const createMessageRead = /* GraphQL */ `mutation CreateMessageRead(
  $input: CreateMessageReadInput!
  $condition: ModelMessageReadConditionInput
) {
  createMessageRead(input: $input, condition: $condition) {
    id
    messageId
    message {
      id
      authorId
      content
      conversationId
      createdAt
      isSent
      isEvent
      updatedAt
      __typename
    }
    userId
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    read
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMessageReadMutationVariables,
  APITypes.CreateMessageReadMutation
>;
export const updateMessageRead = /* GraphQL */ `mutation UpdateMessageRead(
  $input: UpdateMessageReadInput!
  $condition: ModelMessageReadConditionInput
) {
  updateMessageRead(input: $input, condition: $condition) {
    id
    messageId
    message {
      id
      authorId
      content
      conversationId
      createdAt
      isSent
      isEvent
      updatedAt
      __typename
    }
    userId
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    read
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMessageReadMutationVariables,
  APITypes.UpdateMessageReadMutation
>;
export const deleteMessageRead = /* GraphQL */ `mutation DeleteMessageRead(
  $input: DeleteMessageReadInput!
  $condition: ModelMessageReadConditionInput
) {
  deleteMessageRead(input: $input, condition: $condition) {
    id
    messageId
    message {
      id
      authorId
      content
      conversationId
      createdAt
      isSent
      isEvent
      updatedAt
      __typename
    }
    userId
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    read
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMessageReadMutationVariables,
  APITypes.DeleteMessageReadMutation
>;
export const createUserConversations =
  /* GraphQL */ `mutation CreateUserConversations(
  $input: CreateUserConversationsInput!
  $condition: ModelUserConversationsConditionInput
) {
  createUserConversations(input: $input, condition: $condition) {
    id
    owners
    conversationId
    conversation {
      id
      owners
      name
      createdAt
      updatedAt
      openJobConversationsId
      __typename
    }
    userId
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    readAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateUserConversationsMutationVariables,
    APITypes.CreateUserConversationsMutation
  >;
export const updateUserConversations =
  /* GraphQL */ `mutation UpdateUserConversations(
  $input: UpdateUserConversationsInput!
  $condition: ModelUserConversationsConditionInput
) {
  updateUserConversations(input: $input, condition: $condition) {
    id
    owners
    conversationId
    conversation {
      id
      owners
      name
      createdAt
      updatedAt
      openJobConversationsId
      __typename
    }
    userId
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    readAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateUserConversationsMutationVariables,
    APITypes.UpdateUserConversationsMutation
  >;
export const deleteUserConversations =
  /* GraphQL */ `mutation DeleteUserConversations(
  $input: DeleteUserConversationsInput!
  $condition: ModelUserConversationsConditionInput
) {
  deleteUserConversations(input: $input, condition: $condition) {
    id
    owners
    conversationId
    conversation {
      id
      owners
      name
      createdAt
      updatedAt
      openJobConversationsId
      __typename
    }
    userId
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    readAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteUserConversationsMutationVariables,
    APITypes.DeleteUserConversationsMutation
  >;
export const createPageQuery = /* GraphQL */ `mutation CreatePageQuery(
  $input: CreatePageQueryInput!
  $condition: ModelPageQueryConditionInput
) {
  createPageQuery(input: $input, condition: $condition) {
    id
    url
    header
    jobs
    skills
    skillSets
    skillTags
    workType
    baseLocation {
      id
      type
      position
      name
      area
      createdAt
      updatedAt
      owner
      __typename
    }
    baseLocationTypes
    numberOfHoursPerDayStart
    numberOfHoursPerDayEnd
    numberOfHoursPerWeekStart
    numberOfHoursPerWeekEnd
    salaryType
    minumumSalary
    maximumSalary
    createdAt
    updatedAt
    pageQueryBaseLocationId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePageQueryMutationVariables,
  APITypes.CreatePageQueryMutation
>;
export const updatePageQuery = /* GraphQL */ `mutation UpdatePageQuery(
  $input: UpdatePageQueryInput!
  $condition: ModelPageQueryConditionInput
) {
  updatePageQuery(input: $input, condition: $condition) {
    id
    url
    header
    jobs
    skills
    skillSets
    skillTags
    workType
    baseLocation {
      id
      type
      position
      name
      area
      createdAt
      updatedAt
      owner
      __typename
    }
    baseLocationTypes
    numberOfHoursPerDayStart
    numberOfHoursPerDayEnd
    numberOfHoursPerWeekStart
    numberOfHoursPerWeekEnd
    salaryType
    minumumSalary
    maximumSalary
    createdAt
    updatedAt
    pageQueryBaseLocationId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePageQueryMutationVariables,
  APITypes.UpdatePageQueryMutation
>;
export const deletePageQuery = /* GraphQL */ `mutation DeletePageQuery(
  $input: DeletePageQueryInput!
  $condition: ModelPageQueryConditionInput
) {
  deletePageQuery(input: $input, condition: $condition) {
    id
    url
    header
    jobs
    skills
    skillSets
    skillTags
    workType
    baseLocation {
      id
      type
      position
      name
      area
      createdAt
      updatedAt
      owner
      __typename
    }
    baseLocationTypes
    numberOfHoursPerDayStart
    numberOfHoursPerDayEnd
    numberOfHoursPerWeekStart
    numberOfHoursPerWeekEnd
    salaryType
    minumumSalary
    maximumSalary
    createdAt
    updatedAt
    pageQueryBaseLocationId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePageQueryMutationVariables,
  APITypes.DeletePageQueryMutation
>;
export const createInterviewLog = /* GraphQL */ `mutation CreateInterviewLog(
  $input: CreateInterviewLogInput!
  $condition: ModelInterviewLogConditionInput
) {
  createInterviewLog(input: $input, condition: $condition) {
    id
    owners
    intervieweeId
    interviewee {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    interviewerId
    interviewer {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    totalEvaluation
    humanPowerEvaluation
    motivationOfGrowEvaluation
    logicalThinkingEvaluation
    entrepreneurEvaluation
    cooperativenessEvaluation
    companyHistoryEvaluation
    projectHistoryEvaluation
    managementExperienceEvaluation
    comment
    commentForClient
    interviewerName
    video
    videos {
      nextToken
      __typename
    }
    minutes
    actionMethod
    interviewedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInterviewLogMutationVariables,
  APITypes.CreateInterviewLogMutation
>;
export const updateInterviewLog = /* GraphQL */ `mutation UpdateInterviewLog(
  $input: UpdateInterviewLogInput!
  $condition: ModelInterviewLogConditionInput
) {
  updateInterviewLog(input: $input, condition: $condition) {
    id
    owners
    intervieweeId
    interviewee {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    interviewerId
    interviewer {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    totalEvaluation
    humanPowerEvaluation
    motivationOfGrowEvaluation
    logicalThinkingEvaluation
    entrepreneurEvaluation
    cooperativenessEvaluation
    companyHistoryEvaluation
    projectHistoryEvaluation
    managementExperienceEvaluation
    comment
    commentForClient
    interviewerName
    video
    videos {
      nextToken
      __typename
    }
    minutes
    actionMethod
    interviewedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInterviewLogMutationVariables,
  APITypes.UpdateInterviewLogMutation
>;
export const deleteInterviewLog = /* GraphQL */ `mutation DeleteInterviewLog(
  $input: DeleteInterviewLogInput!
  $condition: ModelInterviewLogConditionInput
) {
  deleteInterviewLog(input: $input, condition: $condition) {
    id
    owners
    intervieweeId
    interviewee {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    interviewerId
    interviewer {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    totalEvaluation
    humanPowerEvaluation
    motivationOfGrowEvaluation
    logicalThinkingEvaluation
    entrepreneurEvaluation
    cooperativenessEvaluation
    companyHistoryEvaluation
    projectHistoryEvaluation
    managementExperienceEvaluation
    comment
    commentForClient
    interviewerName
    video
    videos {
      nextToken
      __typename
    }
    minutes
    actionMethod
    interviewedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteInterviewLogMutationVariables,
  APITypes.DeleteInterviewLogMutation
>;
export const createInterviewLogVideo =
  /* GraphQL */ `mutation CreateInterviewLogVideo(
  $input: CreateInterviewLogVideoInput!
  $condition: ModelInterviewLogVideoConditionInput
) {
  createInterviewLogVideo(input: $input, condition: $condition) {
    id
    owners
    title
    url
    interviewLog {
      id
      owners
      intervieweeId
      interviewerId
      totalEvaluation
      humanPowerEvaluation
      motivationOfGrowEvaluation
      logicalThinkingEvaluation
      entrepreneurEvaluation
      cooperativenessEvaluation
      companyHistoryEvaluation
      projectHistoryEvaluation
      managementExperienceEvaluation
      comment
      commentForClient
      interviewerName
      video
      minutes
      actionMethod
      interviewedAt
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    interviewLogVideosId
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateInterviewLogVideoMutationVariables,
    APITypes.CreateInterviewLogVideoMutation
  >;
export const updateInterviewLogVideo =
  /* GraphQL */ `mutation UpdateInterviewLogVideo(
  $input: UpdateInterviewLogVideoInput!
  $condition: ModelInterviewLogVideoConditionInput
) {
  updateInterviewLogVideo(input: $input, condition: $condition) {
    id
    owners
    title
    url
    interviewLog {
      id
      owners
      intervieweeId
      interviewerId
      totalEvaluation
      humanPowerEvaluation
      motivationOfGrowEvaluation
      logicalThinkingEvaluation
      entrepreneurEvaluation
      cooperativenessEvaluation
      companyHistoryEvaluation
      projectHistoryEvaluation
      managementExperienceEvaluation
      comment
      commentForClient
      interviewerName
      video
      minutes
      actionMethod
      interviewedAt
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    interviewLogVideosId
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateInterviewLogVideoMutationVariables,
    APITypes.UpdateInterviewLogVideoMutation
  >;
export const deleteInterviewLogVideo =
  /* GraphQL */ `mutation DeleteInterviewLogVideo(
  $input: DeleteInterviewLogVideoInput!
  $condition: ModelInterviewLogVideoConditionInput
) {
  deleteInterviewLogVideo(input: $input, condition: $condition) {
    id
    owners
    title
    url
    interviewLog {
      id
      owners
      intervieweeId
      interviewerId
      totalEvaluation
      humanPowerEvaluation
      motivationOfGrowEvaluation
      logicalThinkingEvaluation
      entrepreneurEvaluation
      cooperativenessEvaluation
      companyHistoryEvaluation
      projectHistoryEvaluation
      managementExperienceEvaluation
      comment
      commentForClient
      interviewerName
      video
      minutes
      actionMethod
      interviewedAt
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    interviewLogVideosId
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteInterviewLogVideoMutationVariables,
    APITypes.DeleteInterviewLogVideoMutation
  >;
export const createSkillbaWorkHistory =
  /* GraphQL */ `mutation CreateSkillbaWorkHistory(
  $input: CreateSkillbaWorkHistoryInput!
  $condition: ModelSkillbaWorkHistoryConditionInput
) {
  createSkillbaWorkHistory(input: $input, condition: $condition) {
    id
    owners
    openJobId
    openJob {
      id
      organizationName
      consultantName
      owners
      isPublic
      skills
      isDraft
      title
      coverImage
      workType
      numberOfDayPerWeek
      numberOfHoursPerDay
      baseLocationTypes
      baseLocationDetail
      salaryType
      minumumSalary
      maximumSalary
      overView
      issue
      description
      recommendFor
      selectionProcessSupplement
      requiredSkillTags
      requiredSkillTagsDescription
      welcomeSkillTags
      welcomeSkillTagsDescription
      memo
      isHideOrganizationName
      organizationNameForHidden
      isHideSkills
      skillNamesForHidden
      isExcellent
      createdAt
      updatedAt
      organizationOpenJobsId
      openJobJobId
      openJobBaseLocationId
      __typename
    }
    userId
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    startDate
    endDate
    isContinuation
    salary
    salaryType
    numberOfHoursPerWeek
    contractInfo
    comment
    description
    organizationName
    reviews {
      nextToken
      __typename
    }
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateSkillbaWorkHistoryMutationVariables,
    APITypes.CreateSkillbaWorkHistoryMutation
  >;
export const updateSkillbaWorkHistory =
  /* GraphQL */ `mutation UpdateSkillbaWorkHistory(
  $input: UpdateSkillbaWorkHistoryInput!
  $condition: ModelSkillbaWorkHistoryConditionInput
) {
  updateSkillbaWorkHistory(input: $input, condition: $condition) {
    id
    owners
    openJobId
    openJob {
      id
      organizationName
      consultantName
      owners
      isPublic
      skills
      isDraft
      title
      coverImage
      workType
      numberOfDayPerWeek
      numberOfHoursPerDay
      baseLocationTypes
      baseLocationDetail
      salaryType
      minumumSalary
      maximumSalary
      overView
      issue
      description
      recommendFor
      selectionProcessSupplement
      requiredSkillTags
      requiredSkillTagsDescription
      welcomeSkillTags
      welcomeSkillTagsDescription
      memo
      isHideOrganizationName
      organizationNameForHidden
      isHideSkills
      skillNamesForHidden
      isExcellent
      createdAt
      updatedAt
      organizationOpenJobsId
      openJobJobId
      openJobBaseLocationId
      __typename
    }
    userId
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    startDate
    endDate
    isContinuation
    salary
    salaryType
    numberOfHoursPerWeek
    contractInfo
    comment
    description
    organizationName
    reviews {
      nextToken
      __typename
    }
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateSkillbaWorkHistoryMutationVariables,
    APITypes.UpdateSkillbaWorkHistoryMutation
  >;
export const deleteSkillbaWorkHistory =
  /* GraphQL */ `mutation DeleteSkillbaWorkHistory(
  $input: DeleteSkillbaWorkHistoryInput!
  $condition: ModelSkillbaWorkHistoryConditionInput
) {
  deleteSkillbaWorkHistory(input: $input, condition: $condition) {
    id
    owners
    openJobId
    openJob {
      id
      organizationName
      consultantName
      owners
      isPublic
      skills
      isDraft
      title
      coverImage
      workType
      numberOfDayPerWeek
      numberOfHoursPerDay
      baseLocationTypes
      baseLocationDetail
      salaryType
      minumumSalary
      maximumSalary
      overView
      issue
      description
      recommendFor
      selectionProcessSupplement
      requiredSkillTags
      requiredSkillTagsDescription
      welcomeSkillTags
      welcomeSkillTagsDescription
      memo
      isHideOrganizationName
      organizationNameForHidden
      isHideSkills
      skillNamesForHidden
      isExcellent
      createdAt
      updatedAt
      organizationOpenJobsId
      openJobJobId
      openJobBaseLocationId
      __typename
    }
    userId
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    startDate
    endDate
    isContinuation
    salary
    salaryType
    numberOfHoursPerWeek
    contractInfo
    comment
    description
    organizationName
    reviews {
      nextToken
      __typename
    }
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteSkillbaWorkHistoryMutationVariables,
    APITypes.DeleteSkillbaWorkHistoryMutation
  >;
export const createSkillbaWorkHistoryReview =
  /* GraphQL */ `mutation CreateSkillbaWorkHistoryReview(
  $input: CreateSkillbaWorkHistoryReviewInput!
  $condition: ModelSkillbaWorkHistoryReviewConditionInput
) {
  createSkillbaWorkHistoryReview(input: $input, condition: $condition) {
    id
    owners
    userId
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    skillbaWorkHistoryId
    skillbaWorkHistory {
      id
      owners
      openJobId
      userId
      startDate
      endDate
      isContinuation
      salary
      salaryType
      numberOfHoursPerWeek
      contractInfo
      comment
      description
      organizationName
      updatedAt
      createdAt
      __typename
    }
    message
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateSkillbaWorkHistoryReviewMutationVariables,
    APITypes.CreateSkillbaWorkHistoryReviewMutation
  >;
export const updateSkillbaWorkHistoryReview =
  /* GraphQL */ `mutation UpdateSkillbaWorkHistoryReview(
  $input: UpdateSkillbaWorkHistoryReviewInput!
  $condition: ModelSkillbaWorkHistoryReviewConditionInput
) {
  updateSkillbaWorkHistoryReview(input: $input, condition: $condition) {
    id
    owners
    userId
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    skillbaWorkHistoryId
    skillbaWorkHistory {
      id
      owners
      openJobId
      userId
      startDate
      endDate
      isContinuation
      salary
      salaryType
      numberOfHoursPerWeek
      contractInfo
      comment
      description
      organizationName
      updatedAt
      createdAt
      __typename
    }
    message
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateSkillbaWorkHistoryReviewMutationVariables,
    APITypes.UpdateSkillbaWorkHistoryReviewMutation
  >;
export const deleteSkillbaWorkHistoryReview =
  /* GraphQL */ `mutation DeleteSkillbaWorkHistoryReview(
  $input: DeleteSkillbaWorkHistoryReviewInput!
  $condition: ModelSkillbaWorkHistoryReviewConditionInput
) {
  deleteSkillbaWorkHistoryReview(input: $input, condition: $condition) {
    id
    owners
    userId
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    skillbaWorkHistoryId
    skillbaWorkHistory {
      id
      owners
      openJobId
      userId
      startDate
      endDate
      isContinuation
      salary
      salaryType
      numberOfHoursPerWeek
      contractInfo
      comment
      description
      organizationName
      updatedAt
      createdAt
      __typename
    }
    message
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteSkillbaWorkHistoryReviewMutationVariables,
    APITypes.DeleteSkillbaWorkHistoryReviewMutation
  >;
export const createInsight = /* GraphQL */ `mutation CreateInsight(
  $input: CreateInsightInput!
  $condition: ModelInsightConditionInput
) {
  createInsight(input: $input, condition: $condition) {
    id
    owners
    type
    metricsType
    title
    value
    dailyDifference
    reportedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInsightMutationVariables,
  APITypes.CreateInsightMutation
>;
export const updateInsight = /* GraphQL */ `mutation UpdateInsight(
  $input: UpdateInsightInput!
  $condition: ModelInsightConditionInput
) {
  updateInsight(input: $input, condition: $condition) {
    id
    owners
    type
    metricsType
    title
    value
    dailyDifference
    reportedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInsightMutationVariables,
  APITypes.UpdateInsightMutation
>;
export const deleteInsight = /* GraphQL */ `mutation DeleteInsight(
  $input: DeleteInsightInput!
  $condition: ModelInsightConditionInput
) {
  deleteInsight(input: $input, condition: $condition) {
    id
    owners
    type
    metricsType
    title
    value
    dailyDifference
    reportedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteInsightMutationVariables,
  APITypes.DeleteInsightMutation
>;
export const createWorkHistoryCompany =
  /* GraphQL */ `mutation CreateWorkHistoryCompany(
  $input: CreateWorkHistoryCompanyInput!
  $condition: ModelWorkHistoryCompanyConditionInput
) {
  createWorkHistoryCompany(input: $input, condition: $condition) {
    id
    owners
    name
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateWorkHistoryCompanyMutationVariables,
    APITypes.CreateWorkHistoryCompanyMutation
  >;
export const updateWorkHistoryCompany =
  /* GraphQL */ `mutation UpdateWorkHistoryCompany(
  $input: UpdateWorkHistoryCompanyInput!
  $condition: ModelWorkHistoryCompanyConditionInput
) {
  updateWorkHistoryCompany(input: $input, condition: $condition) {
    id
    owners
    name
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateWorkHistoryCompanyMutationVariables,
    APITypes.UpdateWorkHistoryCompanyMutation
  >;
export const deleteWorkHistoryCompany =
  /* GraphQL */ `mutation DeleteWorkHistoryCompany(
  $input: DeleteWorkHistoryCompanyInput!
  $condition: ModelWorkHistoryCompanyConditionInput
) {
  deleteWorkHistoryCompany(input: $input, condition: $condition) {
    id
    owners
    name
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteWorkHistoryCompanyMutationVariables,
    APITypes.DeleteWorkHistoryCompanyMutation
  >;
export const createCSVDownload = /* GraphQL */ `mutation CreateCSVDownload(
  $input: CreateCSVDownloadInput!
  $condition: ModelCSVDownloadConditionInput
) {
  createCSVDownload(input: $input, condition: $condition) {
    id
    owners
    status
    filekey
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCSVDownloadMutationVariables,
  APITypes.CreateCSVDownloadMutation
>;
export const updateCSVDownload = /* GraphQL */ `mutation UpdateCSVDownload(
  $input: UpdateCSVDownloadInput!
  $condition: ModelCSVDownloadConditionInput
) {
  updateCSVDownload(input: $input, condition: $condition) {
    id
    owners
    status
    filekey
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCSVDownloadMutationVariables,
  APITypes.UpdateCSVDownloadMutation
>;
export const deleteCSVDownload = /* GraphQL */ `mutation DeleteCSVDownload(
  $input: DeleteCSVDownloadInput!
  $condition: ModelCSVDownloadConditionInput
) {
  deleteCSVDownload(input: $input, condition: $condition) {
    id
    owners
    status
    filekey
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCSVDownloadMutationVariables,
  APITypes.DeleteCSVDownloadMutation
>;
export const createProfessionalItem =
  /* GraphQL */ `mutation CreateProfessionalItem(
  $input: CreateProfessionalItemInput!
  $condition: ModelProfessionalItemConditionInput
) {
  createProfessionalItem(input: $input, condition: $condition) {
    id
    name
    lead
    category
    keyID
    userIDs
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateProfessionalItemMutationVariables,
    APITypes.CreateProfessionalItemMutation
  >;
export const updateProfessionalItem =
  /* GraphQL */ `mutation UpdateProfessionalItem(
  $input: UpdateProfessionalItemInput!
  $condition: ModelProfessionalItemConditionInput
) {
  updateProfessionalItem(input: $input, condition: $condition) {
    id
    name
    lead
    category
    keyID
    userIDs
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateProfessionalItemMutationVariables,
    APITypes.UpdateProfessionalItemMutation
  >;
export const deleteProfessionalItem =
  /* GraphQL */ `mutation DeleteProfessionalItem(
  $input: DeleteProfessionalItemInput!
  $condition: ModelProfessionalItemConditionInput
) {
  deleteProfessionalItem(input: $input, condition: $condition) {
    id
    name
    lead
    category
    keyID
    userIDs
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteProfessionalItemMutationVariables,
    APITypes.DeleteProfessionalItemMutation
  >;
