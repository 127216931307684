import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import WorkerDashboardLayout from '../layouts/worker';
import ClientDashboardLayout from '../layouts/client';
import AdminDashboardLayout from '../layouts/admin';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import PublicGuard from '../guards/PublicGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component: React.ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed',
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
        {
          path: 'profile-register',
          element: (
            <AuthGuard>
              <ProfileRegister />
            </AuthGuard>
          ),
        },
      ],
    },

    // Client Dashboard Routes
    {
      path: 'client',
      element: (
        <AuthGuard>
          <ClientDashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/client/home" replace /> },
        { path: 'home', element: <Home /> },
        { path: 'users/:id', element: <UserDetails /> },
      ],
    },

    // Admin Dashboard Routes
    {
      path: 'admin',
      element: (
        <AuthGuard>
          <AdminDashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/admin/dashboard" replace /> },
        { path: 'home', element: <AdminHome /> },
        { path: 'seed', element: <Seed /> },
        {
          path: 'jobs',
          children: [
            { element: <AdminOpenJobs /> },
            { path: 'new', element: <EditOpenJob /> },
            { path: 'edit/:id', element: <EditOpenJob /> },
            { path: 'detail/:id', element: <OpenJobDetail /> },
          ],
        },
        { path: 'applies', element: <Applies /> },
        { path: 'profile/:id', element: <UserProfile /> },
        { path: 'users', element: <Users /> },
        {
          path: 'employee-benefits',
          children: [
            { element: <EmployeerBenefits /> },
            { path: 'new', element: <EditEmployeerBenefit /> },
            { path: 'edit/:id', element: <EditEmployeerBenefit /> },
          ],
        },
        {
          path: 'wp-categories',
          children: [
            { element: <WPCategories /> },
            { path: 'new', element: <EditWPCategory /> },
            { path: 'edit/:id', element: <EditWPCategory /> },
          ],
        },
        { path: 'dashboard', element: <AdminDashboard /> },
        { path: 'users/:id', element: <UserDetails /> },
        { path: 'create-user', element: <AdminCreateUser /> },
      ],
    },

    // Worker Dashboard Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <WorkerDashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/home" replace /> },
        { path: 'home', element: <Home /> },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/user/mypage" replace /> },
            { path: 'mypage', element: <MyPage /> },
            { path: 'profile', element: <Profile /> },
            { path: 'edit-basic', element: <EditBasic /> },
            { path: 'edit-conditions', element: <EditConditions /> },
            { path: 'edit-skillsets', element: <EditSkillSets /> },
            { path: 'edit-histories', element: <EditHisories /> },
            { path: 'edit-account', element: <EditAccount /> },
            { path: 'account', element: <Account /> },
            { path: 'keep', element: <KeepOpenJobs /> },
            { path: 'portfolio', element: <Portfolio /> },
          ],
        },
        {
          path: 'message',
          children: [
            { element: <NotFound /> },
            {
              path: ':conversationKey',
              element: <NotFound />,
            },
          ],
        },
        {
          path: 'employee-benefits',
          children: [
            { element: <EmployeeBenefits /> },
            { path: 'detail/:id', element: <EmployeeBenefit /> },
          ],
        },
      ],
    },

    {
      path: 'portfolio',
      element: (
        <PublicGuard>
          <WorkerDashboardLayout />
        </PublicGuard>
      ),
      children: [
        {
          path: ':path',
          element: <PortfolioPublic />,
        },
      ],
    },
    {
      path: ':url',
      element: (
        <PublicGuard>
          <WorkerDashboardLayout />
        </PublicGuard>
      ),
      children: [
        {
          element: <QueriedOpenJobs />,
        },
      ],
    },

    {
      path: 'jobs',
      element: (
        <PublicGuard>
          <WorkerDashboardLayout />
        </PublicGuard>
      ),
      children: [
        { element: <OpenJobs /> },
        { path: 'result', element: <OpenJobs /> },
        { path: 'detail/:id', element: <OpenJobs /> },
      ],
    },

    {
      path: 'intermediate',
      element: <WorkerDashboardLayout />,
      children: [
        {
          element: <Intermediate />,
        },
      ],
    },

    {
      path: 'professionals',
      children: [
        {
          path: '',
          element: <Navigate to="/professionals/categories" replace />,
        },
        {
          path: '/professionals/categories/skills/:professionalItemId',
          element: <Professionals />,
        },
        {
          path: '/professionals/categories/skills',
          element: <ProfessionalsCategories />,
        },
        {
          path: '/professionals/categories',
          element: <ConsultantCategories />,
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <WorkerDashboardLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(
  lazy(() => import('../pages/worker/authentication/Login'))
);
const Register = Loadable(
  lazy(() => import('../pages/worker/authentication/Register'))
);
const ResetPassword = Loadable(
  lazy(() => import('../pages/worker/authentication/ResetPassword'))
);
const VerifyCode = Loadable(
  lazy(() => import('../pages/worker/authentication/VerifyCode'))
);
const ProfileRegister = Loadable(
  lazy(() => import('../pages/worker/authentication/ProfileRegister'))
);

// Main
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const OpenJobs = Loadable(lazy(() => import('../pages/OpenJobs')));
const EditOpenJob = Loadable(lazy(() => import('../pages/EditOpenJob')));
const MyPage = Loadable(lazy(() => import('../pages/worker/mypage/MyPage')));
const Profile = Loadable(lazy(() => import('../pages/worker/mypage/Profile')));
const Professionals = Loadable(
  lazy(() => import('../pages/public/professionals/ProfessionalPage'))
);
const ConsultantCategories = Loadable(
  lazy(() => import('../pages/public/professionals/ConsultantCategories'))
);
const ProfessionalsCategories = Loadable(
  lazy(() => import('../pages/public/professionals/ConsultantSkillList'))
);
const Portfolio = Loadable(
  lazy(() => import('../pages/worker/portfolio/Portfolio'))
);
const PortfolioPublic = Loadable(
  lazy(() => import('../pages/public/portfolio/Portfolio'))
);
const EditBasic = Loadable(
  lazy(() => import('../pages/worker/mypage/edit/EditBasic'))
);
const EditConditions = Loadable(
  lazy(() => import('../pages/worker/mypage/edit/EditConditions'))
);
const EditSkillSets = Loadable(
  lazy(() => import('../pages/worker/mypage/edit/EditSkillSets'))
);
const EditHisories = Loadable(
  lazy(() => import('../pages/worker/mypage/edit/EditHisories'))
);
const EditAccount = Loadable(
  lazy(() => import('../pages/worker/mypage/edit/EditAccount'))
);
const Account = Loadable(lazy(() => import('../pages/worker/mypage/Account')));
const KeepOpenJobs = Loadable(
  lazy(() => import('../pages/worker/mypage/KeepOpenJobs'))
);
const Seed = Loadable(lazy(() => import('../pages/admin/Seed')));
const Applies = Loadable(lazy(() => import('../pages/admin/Applies')));
const Users = Loadable(lazy(() => import('../pages/admin/Users')));
const UserProfile = Loadable(lazy(() => import('../pages/admin/UserProfile')));
const EditEmployeerBenefit = Loadable(
  lazy(() => import('../pages/admin/EditEmployeerBenefit'))
);
const EmployeerBenefits = Loadable(
  lazy(() => import('../pages/admin/EmployeerBenefits'))
);
const WPCategories = Loadable(
  lazy(() => import('../pages/admin/WPCategories'))
);
const EditWPCategory = Loadable(
  lazy(() => import('../pages/admin/EditWPCategory'))
);
const AdminDashboard = Loadable(lazy(() => import('../pages/admin/Dashboard')));
const Message = Loadable(lazy(() => import('../pages/Message')));
const Home = Loadable(lazy(() => import('../pages/Home')));
const AdminHome = Loadable(lazy(() => import('../pages/admin/AdminHome')));
const AdminOpenJobs = Loadable(lazy(() => import('../pages/admin/OpenJobs')));
const OpenJobDetail = Loadable(
  lazy(() => import('../pages/admin/OpenJobDetail'))
);
const EmployeeBenefits = Loadable(
  lazy(() => import('../pages/worker/employeerBenefit/EmployeerBenefits'))
);
const EmployeeBenefit = Loadable(
  lazy(() => import('../pages/worker/employeerBenefit/EmployeerBenefit'))
);
const UserDetails = Loadable(lazy(() => import('../pages/client/UserDetails')));
const Intermediate = Loadable(lazy(() => import('../pages/Intermediate')));
const QueriedOpenJobs = Loadable(
  lazy(() => import('../pages/QueriedOpenJobs'))
);
const AdminCreateUser = Loadable(
  lazy(() => import('../pages/admin/CreateUser'))
);
