import { Theme, alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function ToggleButton(theme: Theme) {
  const isLight = theme.palette.mode === 'light';

  return {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: theme.palette.grey[500],
          border: `solid 1px ${theme.palette.grey[500_32]}`,
          '&.Mui-selected': {
            color: theme.palette.primary.main,
            backgroundColor: alpha(theme.palette.primary.main, 0.08)
          },
          '&.Mui-disabled': {
            color: theme.palette.grey[500_48]
          },
          fontStyle: 14
        }
      }
    }
  };
}
