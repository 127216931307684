import { Icon } from '@iconify/react';
import menuFill from '@iconify/icons-eva/menu-fill';
// material
import { alpha, styled } from '@mui/material/styles';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Box, Stack, AppBar, Toolbar, IconButton, Divider, Link } from '@mui/material';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// components
import { MHidden } from '../../components/@material-extend';
import Logo from '../../components/Logo';
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import ContactsPopover from './ContactsPopover';
import NotificationsPopover from './NotificationsPopover';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
import useAuth from 'hooks/useAuth';
import { gql, useQuery } from '@apollo/client';
import { getUser } from 'graphql/queries';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 48;
const APPBAR_TABLET = 64;
const APPBAR_DESKTOP = 80;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  position: 'inherit'
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('tablet')]: {
    minHeight: APPBAR_TABLET,
  },
  [theme.breakpoints.up('desktop')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

type DashboardNavbarProps = {
  onOpenSidebar: VoidFunction;
};

export default function DashboardNavbar({ onOpenSidebar }: DashboardNavbarProps) {
  const { isCollapse } = useCollapseDrawer();
  const { user: me } = useAuth();
  const { data } = useQuery(gql(getUser), { variables: { sub: me?.sub } });

  const numberUnreadMessages = () => {
    if (data?.getUser) {
      return data.getUser.messageReads?.items?.filter((m: any) => !m.read)?.length || 0;
    }
    return 0;
  }

  return (
    <Stack justifyContent="space-between">
      <RootStyle>
        <ToolbarStyle>
          <MHidden width="desktopUp">
            <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'primary.main', position: 'relative', padding: 0 }}>
              <Icon icon={menuFill} />
              {numberUnreadMessages() > 0 &&
                <Stack
                  sx={{
                    borderRadius: '50%',
                    height: 8,
                    width: 8,
                    background: '#FF4842',
                    position: 'absolute',
                    top: '-4px',
                    right: '-4px',
                  }}
                />
              }
            </IconButton>
          </MHidden>

          <Link component={RouterLink} to={PATH_DASHBOARD.root} sx={{ width: { mobile: '100%', desktop: 'auto' } }}>
            <Logo />
          </Link>
          <Box sx={{ flexGrow: 1 }} />

          <Stack direction="row" alignItems="center" spacing={{ mobile: 0.5, sm: 1.5 }}>
            <AccountPopover />
          </Stack>
        </ToolbarStyle>
      </RootStyle>
      <Divider />
    </Stack>
  );
}
