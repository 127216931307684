import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Pickers(theme: Theme) {
  return {
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          'div[role=presentation]': {
            display: 'flex',
            '& .PrivatePickersFadeTransitionGroup-root:first-of-type': {
              order: 2
            },
            '& .PrivatePickersFadeTransitionGroup-root:nth-of-type(2)': {
              order: 1,
              '& div::after': {
                content: '" /"'
              }
            },
            '& .MuiButtonBase-root': {
              order: 3
            }
          },
          MuiTypography: {
            root: {
              border: 'none'
            }
          },
        }
      }
    },
    MuiMonthPicker: {
      styleOverrides: {
        root: {
          padding: `0 ${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(2)}`,
          ".MuiTypography-root": {
            border: 'none',
            backgroundColor: '#fff',
            borderRadius: '30px',
            height: 36,
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            flex: '1 0 25%',
            color: theme.palette.text.primary,
          },
          ".MuiTypography-root.Mui-selected": {
            color: '#fff',
            backgroundColor: theme.palette.primary.main,
          }
        }
      }
    }
  };
}
