import { useEffect } from 'react';
import { useLocation, useNavigate, matchPath } from 'react-router-dom';
// material
import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  Stack,
  Button,
  Drawer,
  IconButton
} from '@mui/material';
// hooks
import useAuth from '../../hooks/useAuth';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Scrollbar from '../../components/Scrollbar';
import { MHidden } from '../../components/@material-extend';
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const SHRINK_WITH = 96;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('desktop')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex
    })
  }
}));

// ----------------------------------------------------------------------

const SidebarItems = [
  { icon: 'ic_home', title: 'ホーム', path: PATH_DASHBOARD.client.root, isDisable: false },
  { icon: 'ic_search', title: 'タレントを探す', path: PATH_DASHBOARD.client.root, isDisable: false },
  { icon: 'ic_job_offer', title: 'プロジェクト管理', path: PATH_DASHBOARD.client.root, isDisable: false },
  { icon: 'ic_workers', title: '採用進捗管理', path: PATH_DASHBOARD.client.root, isDisable: false },
  { icon: 'ic_mail', title: 'メッセージ', path: PATH_DASHBOARD.root, isDisable: true },
]

type IconCollapseProps = {
  onToggleCollapse: VoidFunction;
  collapseClick: boolean;
};

type DashboardSidebarProps = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
  isShrink: boolean;
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar, isShrink }: DashboardSidebarProps) {
  const { pathname } = useLocation();
  const { user } = useAuth();
  const navigate = useNavigate();

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <MHidden width="desktopUp">
        <IconButton onClick={onCloseSidebar} sx={{ mt: 1.75, ml: 2, height: 24, width: 24, p: 0 }}>
          <SvgIconStyle src="/static/icons/ic_close.svg" sx={{ height: 22, width: 22, color: 'primary.main' }} />
        </IconButton>
      </MHidden>
      {isShrink && (
        <Stack spacing={{ mobile: 3, desktop: 5 }} alignItems="center" sx={{ py: { mobile: 3, desktop: 5 } }}>
          {SidebarItems.map(item => (
            <IconButton
              key={item.title}
              color="inherit"
              onClick={() => navigate(item.path)}
              sx={{ borderRadius: 1, ...(!!matchPath({ path: item.path, end: true }, pathname) && { backgroundColor: 'action.selected' }) }}
            >
              <SvgIconStyle src={`/static/icons/${item.icon}.svg`} sx={{ width: 36, height: 36, color: item.isDisable ? 'text.disabled' : 'text.primary' }} />
            </IconButton>
          ))}
        </Stack>
      )}
      {!isShrink && (
        <Stack spacing={2} sx={{ py: 5, px: 3 }}>
          {SidebarItems.map(item => (
            <Button
              key={item.title}
              variant="text"
              color="inherit"
              size="large"
              fullWidth
              startIcon={<Stack sx={{ mr: 0.5 }}>
                <SvgIconStyle src={`/static/icons/${item.icon}.svg`} sx={{ width: 24, height: 24, color: item.isDisable ? 'text.disabled' : 'text.primary' }} />
              </Stack>}
              onClick={() => navigate(item.path)}
              sx={{
                borderRadius: 1,
                justifyContent: 'flex-start',
                px: 3,
                ...(!!matchPath({ path: item.path, end: true }, pathname) && { backgroundColor: 'action.selected' }),
                color: item.isDisable ? 'text.disabled' : 'text.primary',
              }}
            >
              {item.title}
            </Button>
          ))}
        </Stack>
      )}
      <Box sx={{ flexGrow: 1 }} />

    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          desktop: isShrink ? SHRINK_WITH : DRAWER_WIDTH
        },
        ...(collapseClick && {
          position: 'absolute'
        })
      }}
    >
      <MHidden width="desktopUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          sx={{ height: '100%' }}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="desktopDown">
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          sx={{ height: '100%' }}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              ...(isShrink && {
                width: SHRINK_WITH
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: 'blur(6px)',
                WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                boxShadow: (theme) => theme.customShadows.z20,
                bgcolor: (theme) => alpha(theme.palette.background.default, 0.88)
              }),
              position: 'inherit'
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
