import { Icon } from '@iconify/react';
import menuFill from '@iconify/icons-eva/menu-fill';
// material
import { alpha, styled, useTheme } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Stack, AppBar, Toolbar, IconButton, Divider, Link, Typography, useMediaQuery } from '@mui/material';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// components
import { MHidden } from '../../components/@material-extend';
import Logo from '../../components/Logo';
import AccountPopover from './AccountPopover';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 48;
const APPBAR_TABLET = 64;
const APPBAR_DESKTOP = 80;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: theme.palette.grey[700],
  position: 'inherit'
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('tablet')]: {
    minHeight: APPBAR_TABLET,
  },
  [theme.breakpoints.up('desktop')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

type DashboardNavbarProps = {
  onOpenSidebar: VoidFunction;
};

export default function DashboardNavbar({ onOpenSidebar }: DashboardNavbarProps) {
  const { isCollapse } = useCollapseDrawer();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('desktop'));

  return (
    <Stack justifyContent="space-between">
      <RootStyle>
        <ToolbarStyle>
          <MHidden width="desktopUp">
            <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'primary.main' }}>
              <Icon icon={menuFill} />
            </IconButton>
          </MHidden>

          <Link component={RouterLink} to={PATH_DASHBOARD.admin.root} sx={{ width: { mobile: 'auto', desktop: 'auto' } }}>
            <Stack direction="row" alignItems="center" spacing={1.5}>
              <Logo isWhite />
              {isMobile ? (
                <Typography variant="overline" color="#fff" sx={{ whiteSpace: 'nowrap' }}>管理画面</Typography>
              ) : (
                <Typography variant="h2" color="#fff">管理画面</Typography>
              )}
            </Stack>
          </Link>
          <Box sx={{ flexGrow: 1 }} />

          <Stack direction="row" alignItems="center" spacing={{ mobile: 0.5, sm: 1.5 }}>
            <AccountPopover />
          </Stack>
        </ToolbarStyle>
      </RootStyle>
      <Divider />
    </Stack>
  );
}
