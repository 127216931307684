// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
  profileRegister: path(ROOTS_AUTH, 'profile-register'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  terms: '/terms',
  privacyPolicy: 'privary-policy',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  client: {
    root: path(ROOTS_DASHBOARD, '/client'),
    users: path(ROOTS_DASHBOARD, '/client/users'),
  },
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey'),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    mypage: path(ROOTS_DASHBOARD, '/user/mypage'),
    profile: {
      root: path(ROOTS_DASHBOARD, '/user/profile'),
      editBasic: path(ROOTS_DASHBOARD, '/user/edit-basic'),
      editConditions: path(ROOTS_DASHBOARD, '/user/edit-conditions'),
      editSkillSets: path(ROOTS_DASHBOARD, '/user/edit-skillsets'),
      editHistories: path(ROOTS_DASHBOARD, '/user/edit-histories'),
    },
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: {
      root: path(ROOTS_DASHBOARD, '/user/account'),
      edit: path(ROOTS_DASHBOARD, '/user/edit-account'),
    },
    keep: path(ROOTS_DASHBOARD, '/user/keep'),
    portfolio: path(ROOTS_DASHBOARD, '/user/portfolio'),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
    productById: path(
      ROOTS_DASHBOARD,
      '/e-commerce/product/nike-air-force-1-ndestrukt'
    ),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    editById: path(
      ROOTS_DASHBOARD,
      '/e-commerce/product/nike-blazer-low-77-vintage/edit'
    ),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(
      ROOTS_DASHBOARD,
      '/blog/post/apply-these-7-secret-techniques-to-improve-event'
    ),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post'),
  },
  jobs: {
    root: path(ROOTS_DASHBOARD, '/jobs'),
    detail: path(ROOTS_DASHBOARD, '/jobs/detail'),
    new: path(ROOTS_DASHBOARD, '/new'),
    edit: path(ROOTS_DASHBOARD, '/jobs/edit'),
  },
  admin: {
    root: path(ROOTS_DASHBOARD, '/admin'),
    userProfile: path(ROOTS_DASHBOARD, '/admin/profile'),
    users: path(ROOTS_DASHBOARD, '/admin/users'),
    applies: path(ROOTS_DASHBOARD, '/admin/applies'),
    employeeBenefit: {
      root: path(ROOTS_DASHBOARD, '/admin/employee-benefits'),
      new: path(ROOTS_DASHBOARD, '/admin/employee-benefits/new'),
      edit: path(ROOTS_DASHBOARD, '/admin/employee-benefits/edit'),
    },
    wpCategories: {
      root: path(ROOTS_DASHBOARD, '/admin/wp-categories'),
      detail: path(ROOTS_DASHBOARD, '/admin/wp-categories/detail'),
      new: path(ROOTS_DASHBOARD, '/admin/wp-categories/new'),
      edit: path(ROOTS_DASHBOARD, '/admin/wp-categories/edit'),
    },
    jobs: {
      root: path(ROOTS_DASHBOARD, '/admin/jobs'),
      edit: path(ROOTS_DASHBOARD, 'admin/jobs/edit'),
      detail: path(ROOTS_DASHBOARD, '/admin/jobs/detail'),
      new: path(ROOTS_DASHBOARD, '/admin/jobs/new'),
    },
    dashboard: path(ROOTS_DASHBOARD, '/admin/dashboard'),
    createUser: path(ROOTS_DASHBOARD, '/admin/create-user'),
  },
  message: {
    root: path(ROOTS_DASHBOARD, '/message'),
  },
  employeeBenefit: {
    root: path(ROOTS_DASHBOARD, '/employee-benefits'),
    detail: path(ROOTS_DASHBOARD, '/employee-benefits/detail'),
  },
  intermediate: {
    root: path(ROOTS_DASHBOARD, '/intermediate'),
  },
  professionals: {
    root: path(ROOTS_DASHBOARD, '/professionals'),
    detail: path(
      ROOTS_DASHBOARD,
      '/professionals/categories/skills/:professionalItemId'
    ),
    list: path(ROOTS_DASHBOARD, '/professionals/categories/skills'),
    categories: path(ROOTS_DASHBOARD, '/professionals/categories'),
  },
  public: {
    portfolio: path(ROOTS_DASHBOARD, '/portfolio'),
  },
  pickup: {
    root: path(ROOTS_DASHBOARD, '/pickup'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
