// ----------------------------------------------------------------------

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body: React.CSSProperties;
    buttonL: React.CSSProperties;
    buttonM: React.CSSProperties;
    buttonS: React.CSSProperties;
    formL: React.CSSProperties;
    formM: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    body?: React.CSSProperties;
    buttonL?: React.CSSProperties;
    buttonM?: React.CSSProperties;
    buttonS?: React.CSSProperties;
    formL?: React.CSSProperties;
    formM?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body: true;
    buttonL: true;
    buttonM: true;
    buttonS: true;
    formL: true;
    formM: true;
  }
}

function pxToRem(value: number) {
  return `${value / 16}rem`;
}

function responsiveFontSizes({ mobile, tablet, desktop }: { mobile: number; tablet: number; desktop: number }) {
  return {
    '@media (max-width:767px)': {
      fontSize: pxToRem(mobile)
    },
    '@media (min-width:768px)': {
      fontSize: pxToRem(tablet)
    },
    '@media (min-width:1048px)': {
      fontSize: pxToRem(desktop)
    }
  };
}

const FONT_PRIMARY = 'Noto Sans JP, sans-serif'; // Google Font
// const FONT_SECONDARY = 'CircularStd, sans-serif'; // Local Font

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontWeight: 700,
    lineHeight: 1.6,
    fontSize: pxToRem(32),
    ...responsiveFontSizes({ mobile: 23, tablet: 32, desktop: 32 })
  },
  h2: {
    fontWeight: 700,
    lineHeight: 1.6,
    fontSize: pxToRem(20),
    ...responsiveFontSizes({ mobile: 18, tablet: 20, desktop: 20 })
  },
  h3: {
    fontWeight: 700,
    lineHeight: 1.6,
    fontSize: pxToRem(18),
    ...responsiveFontSizes({ mobile: 16, tablet: 18, desktop: 18 })
  },
  h4: {
    fontWeight: 700,
    lineHeight: 1.6,
    fontSize: pxToRem(16),
    ...responsiveFontSizes({ mobile: 14, tablet: 16, desktop: 16 })
  },
  h5: {
    fontWeight: 700,
    lineHeight: 1.6,
    fontSize: pxToRem(16),
    ...responsiveFontSizes({ mobile: 14, tablet: 16, desktop: 16 })
  },
  h6: {
    fontWeight: 700,
    lineHeight: 1.6,
    fontSize: pxToRem(16),
    ...responsiveFontSizes({ mobile: 14, tablet: 16, desktop: 16 })
  },
  subtitle1: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(16)
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14)
  },
  body: {
    lineHeight: 1.8,
    fontSize: pxToRem(16),
    // ...responsiveFontSizes({ mobile: 14, tablet: 16, desktop: 16 }),
    '@media (max-width:600px)': {
      fontSize: pxToRem(14)
    }
  },
  body1: {
    lineHeight: 1.8,
    fontSize: pxToRem(16),
    // ...responsiveFontSizes({ mobile: 14, tablet: 16, desktop: 16 }),
    '@media (max-width:600px)': {
      fontSize: pxToRem(14)
    }
  },
  body2: {
    lineHeight: 22 / 14,
    fontSize: pxToRem(14)
  },
  caption: {
    lineHeight: 1.6,
    fontSize: pxToRem(14),
    ...responsiveFontSizes({ mobile: 12, tablet: 14, desktop: 14 })
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.6,
    fontSize: pxToRem(14),
    ...responsiveFontSizes({ mobile: 12, tablet: 14, desktop: 14 }),
    letterSpacing: 1.1,
    textTransform: 'uppercase'
  },
  button: {
    fontWeight: 700,
    lineHeight: 1.6,
    fontSize: pxToRem(15),
    ...responsiveFontSizes({ mobile: 14, tablet: 15, desktop: 15 }),
    textTransform: 'capitalize'
  },
  buttonL: {
    fontWeight: 700,
    lineHeight: 1.4,
    fontSize: pxToRem(17)
  },
  buttonM: {
    fontWeight: 700,
    lineHeight: 1.4,
    fontSize: pxToRem(14)
  },
  buttonS: {
    fontWeight: 700,
    lineHeight: 1.6,
    fontSize: pxToRem(12)
  },
  formL: {
    fontWeight: 700,
    lineHeight: 1.6,
    fontSize: pxToRem(15)
  },
  formM: {
    fontWeight: 700,
    lineHeight: 1.4,
    fontSize: pxToRem(14)
  }
} as const;

export default typography;
