import { Icon } from '@iconify/react';
import { alpha, useTheme } from '@mui/material/styles';
// hooks
import useAuth from '../hooks/useAuth';
//
import { MAvatar } from './@material-extend';
import { Avatar, AvatarProps } from '@mui/material';
import { MAvatarProps } from './@material-extend/MAvatar';
import createAvatar from '../utils/createAvatar';
import { AmplifyS3Image } from '@aws-amplify/ui-react/legacy';
import { AccessLevel } from '@aws-amplify/ui-components';
import { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { getUser } from '../graphql/queries';
// components
import SvgIconStyle from './SvgIconStyle';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: MAvatarProps) {
  const theme = useTheme();
  const { user: me } = useAuth();
  const [photoExist, setPhotoExist] = useState(true);
  const { data } = useQuery(gql(getUser), { variables: { sub: me?.sub } });

  return photoExist == true && data && data.getUser && data.getUser.profileIcon ? (
    <Avatar {...other}>
      <AmplifyS3Image
        alt="avatar"
        imgKey={data.getUser.profileIcon}
        level={AccessLevel.Public}
        style={{ zIndex: 8 }}
        imgProps={{
          style: {
            display: 'block',
            maxWidth: '100%',
            height: '100%',
            width: '100%',
            objectFit: 'cover',
          },
        }}
        handleOnError={() => setPhotoExist(false)}
      />
    </Avatar>
  ) : (
    <MAvatar
      alt={"photo"}
      sx={{ color: '#fff', width: { mobile: 32, desktop: 40 }, height: { mobile: 32, desktop: 40 }, backgroundColor: theme.palette.grey[300] }}
      {...other}
    >
      <SvgIconStyle src="/static/icons/ic_person.svg" sx={{ color: theme.palette.grey[500], height: '60%', width: '60%' }} />
    </MAvatar>
  );
}
