import { useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useLocation, useNavigate, matchPath } from 'react-router-dom';
// material
import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  Stack,
  Button,
  Drawer,
  IconButton,
  useTheme,
  useMediaQuery
} from '@mui/material';
// hooks
import useAuth from '../../hooks/useAuth';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Scrollbar from '../../components/Scrollbar';
import { MHidden } from '../../components/@material-extend';
import SvgIconStyle from '../../components/SvgIconStyle';
// API
import { updateUser } from '../../graphql/mutations';
//
import { getUser } from '../../graphql/queries';
import { subscribeToNewMessageRead } from '../../apollo/subscriptions';
import { MessageRead } from '../../API';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const SHRINK_WITH = 96;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('desktop')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex
    })
  }
}));

const NumberStyle = styled('div')(({ theme }) => ({
  borderRadius: '50%',
  height: 24,
  width: 24,
  backgroundColor: '#FF4842',
  color: '#ffffff',
  fontSize: '12px',
  fontWeight: 700,
  textAlign: 'center',
  lineHeight: '24px',
  position: 'absolute',
  top: '-12px',
  right: '-12px',
}));

// ----------------------------------------------------------------------

const SidebarItems = [
  { icon: 'ic_home', title: 'ホーム', path: PATH_DASHBOARD.root, isDisable: false },
  { icon: 'ic_portfolio2', title: 'ポートフォリオ', path: PATH_DASHBOARD.user.portfolio, isDisable: false },
  { icon: 'ic_search', title: 'プロジェクトを探す', path: PATH_DASHBOARD.jobs.root, isDisable: false },
  { icon: 'ic_mail', title: 'メッセージ', path: PATH_DASHBOARD.root, isDisable: true },
]

type IconCollapseProps = {
  onToggleCollapse: VoidFunction;
  collapseClick: boolean;
};

type DashboardSidebarProps = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
  isShrink: boolean;
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar, isShrink }: DashboardSidebarProps) {
  const { pathname } = useLocation();
  const { user: me } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('desktop'));

  const { data, client, subscribeToMore } = useQuery(gql(getUser), { variables: { sub: me?.sub } });
  const numberUnreadMessages = () => {
    if (data?.getUser) {
      return data.getUser.messageReads?.items?.filter((m: any) => !m.read)?.length || 0;
    }
    return 0;
  }

  /* eslint-disable */
  subscribeToMore({
    document: gql(subscribeToNewMessageRead),
    variables: { userId: me?.sub },
    updateQuery: (prev, { subscriptionData }) => {
      if (!prev?.getUser) return;
      if (!subscriptionData.data) return prev;

      const newMessageReadItem = subscriptionData.data.subscribeToNewMessageRead;
      if (prev.getUser.messageReads.items.some((m: any) => m.id === newMessageReadItem.id)) return prev;

      messageCacheUpdate(newMessageReadItem);

      return Object.assign({}, prev, {
        getUser: {
          ...prev.getUser,
          messageReads: {
            nextToken: "fromSubscribe",
            items: [...prev.getUser.messageReads.items, newMessageReadItem],
          }
        }
      });
    }
  });
  /* eslint-enable */

  const messageCacheUpdate = (messageReads: MessageRead) => {
    const message = messageReads?.message;
    console.log(message)
    const conversation = messageReads?.message?.conversation;
    if (!message || !conversation) return;

    client.cache.modify({
      id: client.cache.identify(conversation),
      fields: {
        messages(existingRefs, { readField }) {
          const items = existingRefs.items.some((i: any) => readField('id', i) === message.id) ? existingRefs.items : existingRefs.items.concat([message]);
          return {
            ...existingRefs,
            items,
          };
        },
      },
    });
  }

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  const [updateUserFunction] = useMutation(gql(updateUser));

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps

    const uu = async () => {
      if (!me) return;

      await updateUserFunction({
        variables: {
          input: {
            sub: me.sub,
            lastVisitedAt: new Date(),
          }
        }
      });
    };
    uu();
  }, [pathname]);

  if (pathname.includes('message') && !isShrink) {
    isShrink = !isShrink;
  }

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <MHidden width="desktopUp">
        <IconButton onClick={onCloseSidebar} sx={{ mt: 1.75, ml: 2, height: 24, width: 24, p: 0 }}>
          <SvgIconStyle src="/static/icons/ic_close.svg" sx={{ height: 22, width: 22, color: 'primary.main' }} />
        </IconButton>
      </MHidden>
      {isShrink && (
        <Stack spacing={{ mobile: 3, desktop: 5 }} alignItems="center" sx={{ py: { mobile: 3, desktop: 5 } }}>
          {SidebarItems.map(item => (
            <IconButton
              key={item.title}
              color="inherit"
              onClick={() => navigate(item.path)}
              sx={{ position: 'relative', borderRadius: 1, ...((!!matchPath({ path: item.path, end: true }, pathname) || (item.path == PATH_DASHBOARD.message.root && pathname.includes('message'))) && { backgroundColor: 'action.selected' }) }}
            >
              <SvgIconStyle src={`/static/icons/${item.icon}.svg`} sx={{ width: 36, height: 36, color: item.isDisable ? 'text.disabled' : 'text.primary' }} />
              {item.icon == 'ic_mail' && numberUnreadMessages() > 0 && <NumberStyle>{numberUnreadMessages()}</NumberStyle>}
            </IconButton>
          ))}
        </Stack>
      )}
      {!isShrink && (
        <Stack spacing={2} sx={{ py: 5, px: 3 }}>
          {SidebarItems.map(item => (
            <Button
              key={item.title}
              variant="text"
              color="inherit"
              size="large"
              fullWidth
              startIcon={<Stack sx={{ mr: 0.5 }}>
                <SvgIconStyle src={`/static/icons/${item.icon}.svg`} sx={{ width: 24, height: 24, color: item.isDisable ? 'text.disabled' : 'text.primary' }} />
                {item.icon == 'ic_mail' && numberUnreadMessages() > 0 && <NumberStyle sx={{ top: isMobile ? '12px' : '16px', right: 0 }}>{numberUnreadMessages()}</NumberStyle>}
              </Stack>}
              onClick={() => navigate(item.path)}
              sx={{
                position: 'relative',
                borderRadius: 1,
                justifyContent: 'flex-start',
                px: 3,
                ...(!!matchPath({ path: item.path, end: true }, pathname) && { backgroundColor: 'action.selected' }),
                color: item.isDisable ? 'text.disabled' : 'text.primary',
              }}
            >
              {item.title}
            </Button>
          ))}
        </Stack>
      )}
      <Box sx={{ flexGrow: 1 }} />

    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          desktop: isShrink ? SHRINK_WITH : DRAWER_WIDTH
        },
        ...(collapseClick && {
          position: 'absolute'
        })
      }}
    >
      <MHidden width="desktopUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          sx={{ height: '100%' }}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="desktopDown">
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          sx={{ height: '100%' }}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              ...(isShrink && {
                width: SHRINK_WITH
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: 'blur(6px)',
                WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                boxShadow: (theme) => theme.customShadows.z20,
                bgcolor: (theme) => alpha(theme.palette.background.default, 0.88)
              }),
              position: 'inherit'
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
