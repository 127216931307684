import { ReactNode } from 'react';
// material
import { Breakpoint, Theme, useMediaQuery } from '@mui/material';

// ----------------------------------------------------------------------

type MHiddenProps = {
  children: ReactNode;
  width:
    | 'mobileDown'
    | 'tabletDown'
    | 'desktopDown'
    | 'mobileUp'
    | 'tabletUp'
    | 'desktopUp';
};

export default function MHidden({ width, children }: MHiddenProps) {
  let breakpoint: Breakpoint = 'desktop';
  if (width.includes('mobile')) {
    breakpoint = 'mobile';
  } else if (width.includes('tablet')) {
    breakpoint = 'tablet';
  }
  const hiddenUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up(breakpoint));
  const hiddenDown = useMediaQuery<Theme>((theme) => theme.breakpoints.down(breakpoint));

  if (width.includes('Down')) {
    return hiddenDown ? null : <>{children}</>;
  }

  if (width.includes('Up')) {
    return hiddenUp ? null : <>{children}</>;
  }

  return null;
}
