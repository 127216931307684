import { useState } from 'react';
import { Outlet, useLocation, matchPath } from 'react-router-dom';
// material
import { styled, useTheme } from '@mui/material/styles';
import { Stack, useMediaQuery } from '@mui/material';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { PATH_DASHBOARD } from '../../routes/paths';

// ----------------------------------------------------------------------

const RootStyle = styled('div')({
  display: 'flex',
  height: '100%',
  maxHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
}));

// ----------------------------------------------------------------------

export default function ClientDashboardLayout() {
  const theme = useTheme();
  const { collapseClick } = useCollapseDrawer();
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('desktop'));
  const isShrinkSidebar = (!!matchPath({ path: PATH_DASHBOARD.jobs.root, end: true }, pathname) || !!matchPath({ path: PATH_DASHBOARD.jobs.detail, end: false }, pathname)) && !isMobile;

  return (
    <Stack sx={{ height: '100%' }}>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <RootStyle>
        <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} isShrink={isShrinkSidebar} />
        <MainStyle
          id="infiniteScrollable"
          sx={{
            transition: theme.transitions.create('margin', {
              duration: theme.transitions.duration.complex
            }),
            ...(collapseClick && {
              ml: '102px'
            })
          }}
        >
          <Outlet />
        </MainStyle>
      </RootStyle>
    </Stack>
  );
}
