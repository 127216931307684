import { Theme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

// ----------------------------------------------------------------------

export default function Dialog(theme: Theme) {
  const isMobile = useMediaQuery(theme.breakpoints.down('desktop'));

  return {
    MuiDialog: {
      styleOverrides: {
        root: {
          margin: '0px !important',
          alignItems: isMobile ? 'flex-end !important' : 'center !important'
        },
        paper: {
          boxShadow: theme.customShadows.z24,
          '&.MuiPaper-rounded': {
            borderRadius: theme.shape.borderRadiusMd
          },
          '&.MuiDialog-paperFullScreen': {
            borderRadius: 0
          },
          '&.MuiDialog-paper .MuiDialogActions-root': {
            padding: theme.spacing(3)
          },
          '@media (max-width: 600px)': {
            margin: theme.spacing(2)
          },
          '@media (max-width: 663.95px)': {
            '&.MuiDialog-paperWidthSm.MuiDialog-paperScrollBody': {
              maxWidth: '100%'
            }
          },
          '@media (max-width: 767px)': {
            maxHeight: 'calc(100% - 48px)'
          },
          '@media (min-width: 768px)': {
            maxHeight: 'calc(100% - 64px)'
          },
          '@media (min-width: 1048px)': {
            maxHeight: 'calc(100% - 80px)'
          },
          margin: '0px !important',
          borderRadius: isMobile ? '8px 8px 0px 0px !important' : '8px !important',
          width: '100%',
          ".PrivateDatePickerToolbar-penIcon": {
            top: '0 !important'
          }
        },
        paperFullWidth: {
          width: '100%'
        },
        container: {
          '@media (min-width: 0px)': {
            alignItems: 'flex-end'
          },
          '@media (min-width: 900px)': {
            alignItems: 'center'
          }
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: theme.spacing(3, 3, 0)
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          borderTop: 0,
          borderBottom: 0,
          padding: theme.spacing(3),
          '.PrivatePickersToolbar-dateTitleContainer': {
            backgroundColor: theme.palette.background.neutral,
            borderRadius: theme.spacing(1),
            alignItems: 'center',
            paddingLeft: theme.spacing(2),
          }
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          '& > :not(:first-of-type)': {
            marginLeft: theme.spacing(1.5)
          },
          '.MuiButton-root:nth-of-type(2)': {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
          },
          '.MuiButton-root.Mui-disabled': {
            backgroundColor: theme.palette.action.disabled,
          },
        }
      }
    }
  };
}
