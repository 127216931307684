import { useState, ReactNode, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/worker/authentication/Login';
// apollo
import { getUser } from '../graphql/queries';
// routes
import { PATH_PAGE } from '../routes/paths';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated, user: me, isAdmin } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  const { data } = useQuery(gql(getUser), {
    variables: { sub: me?.sub },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (pathname === '/auth/profile-register' || pathname.includes('admin/seed')) return;

    if (data && !data?.getUser) {
      window.location.href = '/auth/profile-register';
      return;
    }
  }, [data]);

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation) {
    const location = requestedLocation;
    setRequestedLocation(null);
    return <Navigate to={location} />;
  }

  if (pathname.includes('admin') && !isAdmin) {
    return <Navigate to={PATH_PAGE.page404} />
  }

  if (pathname.includes('client') && !isAdmin) {
    return <Navigate to={PATH_PAGE.page404} />
  }

  return <>{data && children}</>;
}
