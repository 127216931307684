import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Timeline(theme: Theme) {
  return {
    MuiTimelineDot: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white
        }
      }
    },

    MuiTimelineConnector: {
      styleOverrides: {
        root: {
          width: '1px',
          backgroundColor: theme.palette.divider
        }
      }
    }
  };
}
