/* tslint:disable */
/* eslint-disable */

export const subscribeToNewMessage = /* GraphQL */`
  subscription SubscribeToNewMessage($conversationId: ID!) {
    subscribeToNewMessage(conversationId: $conversationId) {
      id
      authorId
      author {
        sub
        name
        profileIcon
      }
      messageReads {
        items {
          id
          messageId
          userId
          read
          createdAt
          updatedAt
        }
        nextToken
      }
      content
      conversationId
      createdAt
      isSent
      updatedAt
    }
  }
`;
export const subscribeToNewUCs = /* GraphQL */`
  subscription SubscribeToNewUCs($userId: ID!) {
    subscribeToNewUCs(userId: $userId) {
      id
      conversationId
      conversation {
        createdAt
        id
        messages {
          items {
            id
            authorId
            author {
              sub
              name
              profileIcon
            }
            content
            conversationId
            createdAt
            isSent
            updatedAt
          }
          nextToken
        }
        name
        associated {
          items {
            id
            conversationId
            user {
              sub
              name
              profileIcon
            }
            userId
          }
        }
        updatedAt
      }
      userId
      readAt
      createdAt
      updatedAt
    }
  }
`;
export const subscribeToNewMessageRead = /* GraphQL */`
  subscription SubscribeToNewMessageRead($userId: ID!) {
    subscribeToNewMessageRead(userId: $userId) {
      id
      messageId
      message {
        id
        content
        authorId
        conversationId
        conversation {
          id
        }
      }
      userId
      read
      createdAt
      updatedAt
    }
  }
`;
