import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import { PATH_DASHBOARD } from '../routes/paths';

// ----------------------------------------------------------------------

type PublicGuardProps = {
  children: ReactNode;
};

export default function PublicGuard({ children }: PublicGuardProps) {
  return <>{children}</>;
}
