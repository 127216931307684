import { createContext, ReactNode, useCallback, useEffect, useReducer } from 'react';
import {
  CognitoUser,
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUserSession,
  CognitoUserAttribute
} from 'amazon-cognito-identity-js';
import { Amplify } from 'aws-amplify';
// utils
import axios from '../utils/axios';
// routes
import { PATH_AUTH, PATH_DASHBOARD } from '../routes/paths';
// @types
import { ActionMap, AuthState, AuthUser, AWSCognitoContextType } from '../@types/authentication';
//
import AppSyncConfig from '../aws-exports';
import { hasCognitoUser, hasAuthUser } from '../utils/guard';

// ----------------------------------------------------------------------

export const UserPool = new CognitoUserPool({
  UserPoolId: AppSyncConfig.aws_user_pools_id || '',
  ClientId: AppSyncConfig.aws_user_pools_web_client_id || ''
});

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  isAdmin: false,
  status: 0,
  user: null
};

enum Types {
  auth = 'AUTHENTICATE',
  logout = 'LOGOUT'
}

type AwsAuthPayload = {
  [Types.auth]: {
    isAuthenticated: boolean;
    isAdmin: boolean;
    user: AuthUser;
  };
  [Types.logout]: undefined;
};

type AwsActions = ActionMap<AwsAuthPayload>[keyof ActionMap<AwsAuthPayload>];

const reducer = (state: AuthState, action: AwsActions) => {
  if (action.type === 'AUTHENTICATE') {
    const { isAuthenticated, isAdmin, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isAdmin,
      isInitialized: true,
      user
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      isAdmin: false,
      user: null
    };
  }
  return state;
};

const AuthContext = createContext<AWSCognitoContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getUserAttributes = useCallback(
    (currentUser: CognitoUser): Record<string, any> =>
      new Promise((resolve, reject) => {
        currentUser.getUserAttributes((err, attributes) => {
          if (err) {
            reject(err);
          } else {
            const results: Record<string, any> = {};

            attributes?.forEach((attribute) => {
              results[attribute.Name] = attribute.Value;
            });
            resolve(results);
          }
        });
      }),
    []
  );

  const getSession = useCallback(
    () =>
      new Promise((resolve, reject) => {
        const user = UserPool.getCurrentUser();
        if (user) {
          user.getSession(async (err: Error | null, session: CognitoUserSession | null) => {
            if (err) {
              reject(err);
            } else {
              const attributes = await getUserAttributes(user);
              const token = session?.getIdToken().getJwtToken();
              axios.defaults.headers.common.Authorization = token;

              const payload = session?.getIdToken().payload;
              const isAdmin =
                payload &&
                payload['cognito:groups'] &&
                payload['cognito:groups'].includes('administrator');
              dispatch({
                type: Types.auth,
                payload: {
                  isAuthenticated: true,
                  isAdmin: isAdmin,
                  user: attributes
                }
              });
              resolve({
                user,
                attributes,
                session,
                headers: { Authorization: token }
              });
            }
          });
        } else {
          Amplify.configure({
            ...AppSyncConfig,
            aws_appsync_authenticationType: "AWS_IAM",
          });
          dispatch({
            type: Types.auth,
            payload: {
              isAuthenticated: false,
              isAdmin: false,
              user: null
            }
          });
        }
      }),
    [getUserAttributes]
  );

  const initial = useCallback(async () => {
    try {
      await getSession();
    } catch {
      dispatch({
        type: Types.auth,
        payload: {
          isAuthenticated: false,
          isAdmin: false,
          user: null
        }
      });
    }
  }, [getSession]);

  useEffect(() => {
    initial();
  }, [initial]);

  // We make sure to handle the user update here, but return the resolve value in order for our components to be
  // able to chain additional `.then()` logic. Additionally, we `.catch` the error and "enhance it" by providing
  // a message that our React components can use.
  const login = useCallback(
    (email, password) =>
      new Promise((resolve, reject) => {
        const user = new CognitoUser({
          Username: email,
          Pool: UserPool
        });

        const authDetails = new AuthenticationDetails({
          Username: email,
          Password: password
        });

        user.authenticateUser(authDetails, {
          onSuccess: (data) => {
            localStorage.setItem("isLoggedIn", 'true');
            getSession().then((session) => {
              if (!hasAuthUser(session)) {
                reject();
                return;
              }
              resolve({ attributes: session.attributes });
            });
          },
          onFailure: (err) => {
            reject(err);
          },
          newPasswordRequired: () => {
            // Handle this on login page for update password.
            user.completeNewPasswordChallenge(password, [], {
              onSuccess: (result) => {
                getSession().then((session) => {
                  if (!hasAuthUser(session)) {
                    reject();
                    return;
                  }
                  resolve({ attributes: session.attributes });
                });
              },
              onFailure: (err) => {
                reject(err);
              }
            });
          }
        });
      }),
    [getSession]
  );

  // same thing here
  const logout = () => {
    const user = UserPool.getCurrentUser();
    if (user) {
      localStorage.setItem("isLoggedIn", 'true'); 
      user.signOut();
      dispatch({ type: Types.logout });
    }
  };

  const verifyCode = (code: string) =>
    new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username: sessionStorage.getItem('email') || '',
        Pool: UserPool
      });
      user.confirmRegistration(code, true, async (err) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(undefined);
        sessionStorage.removeItem('email');
      });
    });

  const register = (email: string, password: string) =>
    new Promise((resolve, reject) => {
      UserPool.signUp(
        email,
        password,
        [new CognitoUserAttribute({ Name: 'email', Value: email })],
        [],
        async (err, data) => {
          if (err) {
            reject(err);
            return;
          }
          if (data) {
            sessionStorage.setItem('email', data.user.getUsername());
            resolve(undefined);
            window.location.href = PATH_DASHBOARD.root;
          }
        }
      );
    });

  const resetPassword = (email: string) =>
    new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username: email,
        Pool: UserPool
      });
      user.forgotPassword({
        onSuccess: (result) => {
          resolve(undefined);
        },
        onFailure: (err) => reject(err)
      });
    });

  const confirmPassword = (email: string, verificationCode: string, newPassword: string) =>
    new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username: email,
        Pool: UserPool
      });
      user.confirmPassword(verificationCode, newPassword, {
        onSuccess: () => {
          resolve(undefined);
        },
        onFailure: (err) => reject(err)
      });
    });

  const changePassword = (oldPassword: string, newPassword: string) =>
    new Promise((resolve, reject) => {
      getSession().then((session) => {
        if (!hasCognitoUser(session)) {
          reject();
          return;
        }
        const user = session.user;
        user.changePassword(oldPassword, newPassword, async (err) => {
          if (err) {
            console.log(err);
            reject(err);
            return;
          }
          resolve(undefined);
        });
      });
    });

  const updateProfile = (userData: any) =>
    new Promise((resolve, reject) => {
      getSession().then((session) => {
        if (!hasCognitoUser(session)) {
          reject();
          return;
        }
        const user = session.user;
        user.updateAttributes(
          [
            userData.name &&
              new CognitoUserAttribute({
                Name: 'custom:name',
                Value: userData.name
              })
          ],
          async (err) => {
            if (err) {
              reject(err);
              return;
            }
            getSession();
            resolve(undefined);
          }
        );
      });
    });

  const resendVerifyCode = () =>
    new Promise((resolve, reject) => {
      getSession().then((session) => {
        if (!hasCognitoUser(session)) {
          reject();
          return;
        }
        const user = session.user;
        user.resendConfirmationCode(async (err) => {
          if (err) {
            console.log(err);
            reject(err);
            return;
          }
          resolve(undefined);
        });
      });
    });

  const getUniqueStr = (myStrong?: number): string => {
    let strong = 1000;
    if (myStrong) strong = myStrong;
    return new Date().getTime().toString(16) + Math.floor(strong * Math.random()).toString(16);
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'cognito',
        user: {
          displayName: state?.user && state.user['custom:displayName'],
          role: 'admin',
          ...state.user
        },
        login,
        register,
        logout,
        updateProfile,
        resendVerifyCode,
        verifyCode,
        resetPassword,
        confirmPassword,
        changePassword,
        getSession
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
