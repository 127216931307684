import { AuthUser } from '../@types/authentication';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';

export const hasCognitoUser = (d: any): d is { user: CognitoUser } => {
  if (!d) return false;
  if (d.user && typeof d.user === 'object') {
    return true;
  }
  return false;
};

export const hasAuthUser = (d: any): d is { attributes: AuthUser } => {
  if (!d) return false;
  if (d.attributes && typeof d.attributes === 'object') {
    return true;
  }
  return false;
};

export const hasSession = (d: any): d is { session: CognitoUserSession } => {
  if (!d) return false;
  if (d.session && typeof d.session === 'object') {
    return true;
  }
  return false;
};
