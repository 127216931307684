import { useState } from 'react';
import { Outlet, useLocation, matchPath, useParams } from 'react-router-dom';
// material
import { styled, useTheme } from '@mui/material/styles';
import { Stack, useMediaQuery } from '@mui/material';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import useAuth from '../../hooks/useAuth';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { PATH_DASHBOARD } from '../../routes/paths';
import LogoHeader from './LogoHeader';

// ----------------------------------------------------------------------

const RootStyle = styled('div')({
  display: 'flex',
  height: '100%',
  maxHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
}));

// ----------------------------------------------------------------------

export default function WorkerDashboardLayout() {
  const theme = useTheme();
  const { isAuthenticated } = useAuth();
  const { collapseClick } = useCollapseDrawer();
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();
  const { path: userPath } = useParams();
  const isMobile = useMediaQuery(theme.breakpoints.down('desktop'));
  const isLogin =
    localStorage.getItem('isLoggedIn') === null ||
    localStorage.getItem('isLoggedIn') === 'false';
  const isShrinkSidebar = (!!matchPath({ path: PATH_DASHBOARD.jobs.root, end: true }, pathname) || !!matchPath({ path: PATH_DASHBOARD.jobs.detail, end: false }, pathname) || !!matchPath({ path: PATH_DASHBOARD.pickup.root, end: false }, pathname)) && !isMobile;

  return (
    <Stack sx={{ height: '100%' }}>
      {isAuthenticated && !pathname.includes('portfolio/' + userPath) ? (
        <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      ) : !isAuthenticated && (
        <LogoHeader />
      )}
      <RootStyle>
        {isAuthenticated && !pathname.includes('portfolio/' + userPath) && !isLogin && (
          <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} isShrink={isShrinkSidebar} />
        )}
        <MainStyle
          sx={{
            transition: theme.transitions.create('margin', {
              duration: theme.transitions.duration.complex
            }),
            ...(collapseClick && {
              ml: '102px'
            })
          }}
        >
          <Outlet />
        </MainStyle>
      </RootStyle>
    </Stack>
  );
}
