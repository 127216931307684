
import { Link as RouterLink } from 'react-router-dom';// material
import { useTheme } from '@mui/material/styles';
import { Divider, Stack, useMediaQuery, Link } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
//
import Logo from '../../components/Logo';

// ----------------------------------------------------------------------

type LogoHeaderProps = {
  justifyContent?: string;
};

export default function LogoHeader({ justifyContent }: LogoHeaderProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('desktop'));

  return (
    <Stack
      sx={{
        width: '100%',
        height: { mobile: 48, tablet: 64, desktop: 80 }
      }}
    >
      <Stack
        direction="row"
        justifyContent={justifyContent ? justifyContent : (isMobile ? 'center' : 'left')}
        alignItems="center"
        sx={{
          px: 5,
          height: '100%'
        }}
      >
        <Link href="https://talent.skillba.jp">
          <Logo
            sx={{
              height: { mobile: '30px', desktop: '39.73px' },
              width: { mobile: '114.78px', desktop: '152px' },
            }}
          />
        </Link>
      </Stack>
    </Stack>
  );
}
