import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Link, Container, Typography } from '@mui/material';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
// layouts
import LogoHeader from '../../../layouts/worker/LogoHeader';
// components
import Page from '../../../components/Page';
import { LoginForm } from '../../../components/authentication/login';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 528,
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const urlParams = new URLSearchParams(window.location.search);
const id = urlParams.get('id');

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuth();

  return (
    <Page title="ログイン | スキルバ">
      <LogoHeader />

      <Container sx={{ p: 0 }}>
        <Typography
          variant="h2"
          textAlign="center"
          sx={{ mt: { mobile: 4, desktop: 5 } }}
        >
          ログイン
        </Typography>
        <ContentStyle
          sx={{ py: { mobile: 4, desktop: 6 }, px: { mobile: 2, desktop: 0 } }}
        >
          <LoginForm idJob={id} />
          <Typography variant="body1" sx={{ textAlign: 'center', mt: 3 }}>
            アカウントをお持ちでないですか？ &nbsp;
            <Link
              component={RouterLink}
              to={`${PATH_AUTH.register}${id ? `?id=${id}` : ''}`}
              underline="none"
              variant="subtitle2"
            >
              登録する
            </Link>
          </Typography>
        </ContentStyle>
      </Container>
    </Page>
  );
}
