import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloLink,
} from '@apollo/client';
import React from 'react';
import { Auth } from 'aws-amplify';
import {
  addListeners,
  createAuthLink,
  removeListeners,
} from './amplify-auth-link';
import { AuthOptions } from 'aws-appsync-auth-link';
import AppSyncConfig from './aws-exports';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

const url = AppSyncConfig.aws_appsync_graphqlEndpoint;
const region = AppSyncConfig.aws_appsync_region;
const auth: AuthOptions = {
  type: 'AMAZON_COGNITO_USER_POOLS',
  jwtToken: async () =>
    (await Auth.currentSession()).getIdToken().getJwtToken(),
};

const cache = new InMemoryCache({
  typePolicies: {
    // Conversation: {
    //   fields: {
    //     messages: {
    //       keyArgs: ["@connection", ["key"]],
    //       merge(existing, incoming, { readField }) {
    //         if (incoming) {
    //           let items = [];
    //           if (incoming.nextToken === 'fromSubscribe') {
    //             items = existing ? [
    //               ...Object.values(incoming.items.filter((i: any) => !existing.items.some((e: any) => e.__ref === i.__ref))),
    //               ...Object.values(existing.items || []),
    //             ] : [...Object.values(incoming.items)]
    //           } else {
    //             items = existing ? [
    //               ...Object.values(existing.items || []),
    //               ...Object.values(incoming.items.filter((i: any) => !existing.items.some((e: any) => e.__ref === i.__ref))),
    //             ] : [...Object.values(incoming.items)]
    //           }
    //           return {
    //             __typename: readField('__typename', incoming),
    //             items,
    //             nextToken: incoming.nextToken === 'fromSubscribe' ? existing.nextToken : incoming.nextToken,
    //           };
    //         }
    //       },

    //       read(existing, { readField }) {
    //         if (existing) {
    //           return {
    //             __typename: readField('__typename', existing),
    //             items: existing.items,
    //             nextToken: existing.nextToken,
    //           };
    //         }
    //       },
    //     }
    //   }
    // },
    Query: {
      fields: {
        searchOpenJobs: {
          keyArgs: ['filter'],
          merge(existing, incoming, { readField }) {
            if (incoming) {
              const filterd = incoming.items?.filter(
                (i: any) =>
                  !existing?.items?.some(
                    (item: any) => readField('id', item) === readField('id', i)
                  )
              );
              const items = existing
                ? [...Object.values(existing.items), ...Object.values(filterd)]
                : Object.values(filterd);
              return {
                ...incoming,
                items,
              };
            }
          },

          read(existing) {
            if (existing) {
              return {
                ...existing,
                items: Object.values(existing.items),
              };
            }
          },
        },
        searchUserFavoriteOpenJobs: {
          keyArgs: ['filter'],
          merge(existing, incoming, { readField }) {
            if (incoming) {
              const filterd = incoming.items?.filter(
                (i: any) =>
                  !existing?.items?.some(
                    (item: any) => readField('id', item) === readField('id', i)
                  )
              );
              const items = existing
                ? [...Object.values(existing.items), ...Object.values(filterd)]
                : Object.values(filterd);
              return {
                ...incoming,
                items,
              };
            }
          },

          read(existing) {
            if (existing) {
              return {
                ...existing,
                items: Object.values(existing.items),
              };
            }
          },
        },
        listJobs: {
          keyArgs: ['filter'],
          merge(existing, incoming, { readField }) {
            if (incoming) {
              const filterd = incoming.items?.filter(
                (i: any) =>
                  !existing?.items?.some(
                    (item: any) => readField('id', item) === readField('id', i)
                  )
              );
              const items = existing
                ? [...Object.values(existing.items), ...Object.values(filterd)]
                : Object.values(filterd);
              return {
                ...incoming,
                items,
              };
            }
          },

          read(existing) {
            if (existing) {
              return {
                ...existing,
                items: Object.values(existing.items),
              };
            }
          },
        },
        listSkills: {
          keyArgs: ['filter'],
          merge(existing, incoming, { readField }) {
            if (incoming) {
              const filterd = incoming.items?.filter(
                (i: any) =>
                  !existing?.items?.some(
                    (item: any) => readField('id', item) === readField('id', i)
                  )
              );
              const items = existing
                ? [...Object.values(existing.items), ...Object.values(filterd)]
                : Object.values(filterd);
              return {
                ...incoming,
                items,
              };
            }
          },

          read(existing) {
            if (existing) {
              return {
                ...existing,
                items: Object.values(existing.items),
              };
            }
          },
        },
        listSkillTags: {
          keyArgs: ['filter'],
          merge(existing, incoming, { readField }) {
            if (incoming) {
              const filterd = incoming.items?.filter(
                (i: any) =>
                  !existing?.items?.some(
                    (item: any) => readField('id', item) === readField('id', i)
                  )
              );
              const items = existing
                ? [...Object.values(existing.items), ...Object.values(filterd)]
                : Object.values(filterd);
              return {
                ...incoming,
                items,
              };
            }
          },

          read(existing) {
            if (existing) {
              return {
                ...existing,
                items: Object.values(existing.items),
              };
            }
          },
        },
        listSkillTagsByOrderByPosition: {
          keyArgs: ['filter'],
          merge(existing, incoming, { readField }) {
            if (incoming) {
              const filterd = incoming.items?.filter(
                (i: any) =>
                  !existing?.items?.some(
                    (item: any) => readField('id', item) === readField('id', i)
                  )
              );
              const items = existing
                ? [...Object.values(existing.items), ...Object.values(filterd)]
                : Object.values(filterd);
              return {
                ...incoming,
                items,
              };
            }
          },

          read(existing) {
            if (existing) {
              return {
                ...existing,
                items: Object.values(existing.items),
              };
            }
          },
        },
        listUserAppliesByUser: {
          keyArgs: ['filter'],
          merge(existing, incoming, { readField }) {
            if (incoming) {
              const filterd = incoming.items?.filter(
                (i: any) =>
                  !existing?.items?.some(
                    (item: any) => readField('id', item) === readField('id', i)
                  )
              );
              const items = existing
                ? [...Object.values(existing.items), ...Object.values(filterd)]
                : Object.values(filterd);
              return {
                ...incoming,
                items,
              };
            }
          },

          read(existing) {
            if (existing) {
              return {
                ...existing,
                items: Object.values(existing.items),
              };
            }
          },
        },
        listUserConversations: {
          keyArgs: ['filter'],
          merge(existing, incoming) {
            let items = [];
            if (incoming.nextToken === 'fromSubscribe') {
              items = existing
                ? [
                    ...Object.values(
                      incoming.items.filter(
                        (i: any) =>
                          !existing.items.some((e: any) => e.__ref === i.__ref)
                      )
                    ),
                    ...Object.values(existing?.items || []),
                  ]
                : [...Object.values(incoming.items)];
            } else {
              items = existing
                ? [
                    ...Object.values(existing?.items || []),
                    ...Object.values(
                      incoming.items.filter(
                        (i: any) =>
                          !existing.items.some((e: any) => e.__ref === i.__ref)
                      )
                    ),
                  ]
                : [...Object.values(incoming.items)];
            }
            return {
              nextToken:
                incoming.nextToken === 'fromSubscribe'
                  ? existing.nextToken
                  : incoming.nextToken,
              items,
            };
          },

          read(existing) {
            if (existing) {
              return {
                nextToken: existing.nextToken,
                items: Object.values(existing.items),
              };
            }
          },
        },
        listUsers: {
          keyArgs: ['filter'],
          merge(existing, incoming, { readField }) {
            if (incoming) {
              const items = existing
                ? [
                    ...Object.values(existing.items || []),
                    ...Object.values(incoming.items || []),
                  ]
                : Object.values(incoming.items || []);
              return {
                ...incoming,
                items,
              };
            }
          },

          read(existing) {
            if (existing) {
              return {
                ...existing,
                items: Object.values(existing.items),
              };
            }
          },
        },
        searchUsers: {
          keyArgs: ['@connection', ['key']],
          merge(existing, incoming, { readField }) {
            if (incoming) {
              const items = existing
                ? [
                    ...Object.values(existing.items || []),
                    ...Object.values(incoming.items || []),
                  ]
                : Object.values(incoming.items || []);
              return {
                ...incoming,
                items,
              };
            }
          },

          read(existing) {
            if (existing) {
              return {
                ...existing,
                items: Object.values(existing.items),
              };
            }
          },
        },
        listInsights: {
          keyArgs: ['filter'],
          merge(existing, incoming, { readField }) {
            if (incoming) {
              const filterd = incoming.items?.filter(
                (i: any) =>
                  !existing?.items?.some(
                    (item: any) => readField('id', item) === readField('id', i)
                  )
              );
              const items = existing
                ? [...Object.values(existing.items), ...Object.values(filterd)]
                : Object.values(filterd);
              return {
                ...incoming,
                items,
              };
            }
          },

          read(existing) {
            if (existing) {
              return {
                ...existing,
                items: Object.values(existing.items),
              };
            }
          },
        },
      },
    },
  },
});

const createApolloClient = () =>
  new ApolloClient({
    cache,
    link: ApolloLink.from([
      createAuthLink(),
      createSubscriptionHandshakeLink({ url, region, auth }),
      new HttpLink({ uri: url }),
    ]),
  });

// eslint-disable-next-line import/prefer-default-export
export const useApolloClient = () => {
  const [client] = React.useState(() => createApolloClient());
  React.useEffect(() => {
    const handler = addListeners();
    return () => removeListeners(handler);
  });
  return client;
};
