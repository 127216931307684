/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from '../API';
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const notifyToSlackForNewUserApply =
  /* GraphQL */ `query NotifyToSlackForNewUserApply(
  $name: String
  $birthDate: String
  $job: String
  $openJob: String
  $histories: String
  $sub: String
) {
  notifyToSlackForNewUserApply(
    name: $name
    birthDate: $birthDate
    job: $job
    openJob: $openJob
    histories: $histories
    sub: $sub
  )
}
` as GeneratedQuery<
    APITypes.NotifyToSlackForNewUserApplyQueryVariables,
    APITypes.NotifyToSlackForNewUserApplyQuery
  >;
export const notifyToSlackForNewUser =
  /* GraphQL */ `query NotifyToSlackForNewUser(
  $name: String
  $birthDate: String
  $job: String
  $histories: String
  $sub: String
) {
  notifyToSlackForNewUser(
    name: $name
    birthDate: $birthDate
    job: $job
    histories: $histories
    sub: $sub
  )
}
` as GeneratedQuery<
    APITypes.NotifyToSlackForNewUserQueryVariables,
    APITypes.NotifyToSlackForNewUserQuery
  >;
export const notifyEmailForNewMessage =
  /* GraphQL */ `query NotifyEmailForNewMessage(
  $participants: [NotifyEmailForNewMessageParticipantInput]
  $organizationName: String
  $senderName: String
  $message: String
) {
  notifyEmailForNewMessage(
    participants: $participants
    organizationName: $organizationName
    senderName: $senderName
    message: $message
  )
}
` as GeneratedQuery<
    APITypes.NotifyEmailForNewMessageQueryVariables,
    APITypes.NotifyEmailForNewMessageQuery
  >;
export const notifyErrorToSlack = /* GraphQL */ `query NotifyErrorToSlack(
  $displayName: String
  $queryName: String
  $errorMessage: String
  $sub: String
) {
  notifyErrorToSlack(
    displayName: $displayName
    queryName: $queryName
    errorMessage: $errorMessage
    sub: $sub
  )
}
` as GeneratedQuery<
  APITypes.NotifyErrorToSlackQueryVariables,
  APITypes.NotifyErrorToSlackQuery
>;
export const downloadCSVForInsights =
  /* GraphQL */ `query DownloadCSVForInsights(
  $period: String
  $metricsType: String
  $date: String
) {
  downloadCSVForInsights(
    period: $period
    metricsType: $metricsType
    date: $date
  ) {
    statusCode
    headers
    body
    __typename
  }
}
` as GeneratedQuery<
    APITypes.DownloadCSVForInsightsQueryVariables,
    APITypes.DownloadCSVForInsightsQuery
  >;
export const invokeCreateSearchUsersCSV =
  /* GraphQL */ `query InvokeCreateSearchUsersCSV($filter: String, $type: String) {
  invokeCreateSearchUsersCSV(filter: $filter, type: $type) {
    statusCode
    body
    __typename
  }
}
` as GeneratedQuery<
    APITypes.InvokeCreateSearchUsersCSVQueryVariables,
    APITypes.InvokeCreateSearchUsersCSVQuery
  >;
export const getOrganization = /* GraphQL */ `query GetOrganization($id: ID!) {
  getOrganization(id: $id) {
    id
    owners
    name
    organizationNameForHidden
    isHideOrganizationName
    icon
    description
    users {
      nextToken
      __typename
    }
    openJobs {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrganizationQueryVariables,
  APITypes.GetOrganizationQuery
>;
export const listOrganizations = /* GraphQL */ `query ListOrganizations(
  $filter: ModelOrganizationFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owners
      name
      organizationNameForHidden
      isHideOrganizationName
      icon
      description
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrganizationsQueryVariables,
  APITypes.ListOrganizationsQuery
>;
export const getUser = /* GraphQL */ `query GetUser($sub: ID!) {
  getUser(sub: $sub) {
    id
    owner
    sub
    firstName
    lastName
    firstNameKana
    lastNameKana
    name
    phoneticName
    displayName
    nameInitial
    email
    birthDate
    gender
    tel
    address
    nearestStation
    baseLocation {
      id
      type
      position
      name
      area
      createdAt
      updatedAt
      owner
      __typename
    }
    baseLocations {
      nextToken
      __typename
    }
    profileIcon
    coverImage
    mainJob {
      id
      type
      position
      name
      color
      createdAt
      updatedAt
      __typename
    }
    mainJobs {
      nextToken
      __typename
    }
    skillSets {
      nextToken
      __typename
    }
    skillTags
    appetiteToChangeJobs
    appetiteToSideWork
    wayOfWorking
    currentLocationType
    isPrivate
    belongsTo
    companyName
    description
    workHistories {
      nextToken
      __typename
    }
    numberOfDayPerWeek
    numberOfHoursPerDay
    salaryType
    facebookUrl
    linkedinUrl
    twitterUrl
    lineID
    instagramUrl
    chatworkID
    minumumSalary
    maximumSalary
    favoritedOpenJobs {
      nextToken
      __typename
    }
    applies {
      nextToken
      __typename
    }
    usedEmployeeBenefits {
      nextToken
      __typename
    }
    organization {
      id
      owners
      name
      organizationNameForHidden
      isHideOrganizationName
      icon
      description
      createdAt
      updatedAt
      __typename
    }
    hopeLocationType
    otherHopes
    isShareFullname
    isShareProfileIcon
    isShareHistories
    isShareCurrentJob
    isShareAppetites
    isShareWorkTime
    isShareHopeSalary
    isShareHopeLocationType
    isShareAbleAreas
    isShareGender
    isShareBirthdate
    isShareBaseLocation
    isCompletedSurvey
    resumeFile
    resumeFiles {
      nextToken
      __typename
    }
    portfolioFiles {
      nextToken
      __typename
    }
    wpWorker {
      id
      userID
      categories
      status
      url
      createdAt
      updatedAt
      __typename
    }
    qualifications {
      nextToken
      __typename
    }
    evaluations {
      nextToken
      __typename
    }
    evaluationComments {
      nextToken
      __typename
    }
    myEvaluationComments {
      nextToken
      __typename
    }
    isAlreadyEditedBasic
    isAgreedTerms
    isAgreedPostForClient
    isConfirmedServiceGuide
    nameForClient
    introductionForClient
    userConversations {
      nextToken
      __typename
    }
    messages {
      nextToken
      __typename
    }
    messageReads {
      nextToken
      __typename
    }
    openJobs {
      nextToken
      __typename
    }
    intervieweeLogs {
      nextToken
      __typename
    }
    interviewerLogs {
      nextToken
      __typename
    }
    skillbaWorkHistories {
      nextToken
      __typename
    }
    skillbaWorkHistoryReviews {
      nextToken
      __typename
    }
    triggerForRegistration
    triggerForRegistrationDescription
    introducerId
    introducerName
    skillNamesForQuery
    jobNamesForQuery
    lastJobNameForQuery
    interviewCommentForQuery
    interviewCommentForClientForQuery
    interviewMinutesForQuery
    interviewActionMethodForQuery
    skillbaWorkHistoryNameForQuery
    skillTagNamesForQuery
    portfolioForQuery
    evaluationAvg
    isImportFromNotion
    notionWorkTimes
    notionSalaries
    isDebug
    shortID
    createdAt
    updatedAt
    lastVisitedAt
    organizationUsersId
    userBaseLocationId
    userMainJobId
    userWpWorkerId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $sub: ID
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUsers(
    sub: $sub
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const searchUsers = /* GraphQL */ `query SearchUsers(
  $filter: SearchableUserFilterInput
  $sort: [SearchableUserSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableUserAggregationInput]
) {
  searchUsers(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchUsersQueryVariables,
  APITypes.SearchUsersQuery
>;
export const getUserPrivate = /* GraphQL */ `query GetUserPrivate($sub: ID!) {
  getUserPrivate(sub: $sub) {
    sub
    name
    phoneticName
    email
    tel
    address
    nearestStation
    belongsTo
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserPrivateQueryVariables,
  APITypes.GetUserPrivateQuery
>;
export const listUserPrivates = /* GraphQL */ `query ListUserPrivates(
  $sub: ID
  $filter: ModelUserPrivateFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUserPrivates(
    sub: $sub
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      sub
      name
      phoneticName
      email
      tel
      address
      nearestStation
      belongsTo
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserPrivatesQueryVariables,
  APITypes.ListUserPrivatesQuery
>;
export const getUserShareSetting =
  /* GraphQL */ `query GetUserShareSetting($sub: ID!) {
  getUserShareSetting(sub: $sub) {
    sub
    pathname
    isShareFullname
    isShareProfileIcon
    isShareHistories
    isShareCurrentJob
    isShareAppetites
    isShareWorkTime
    isShareHopeSalary
    isShareHopeLocationType
    isShareAbleAreas
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetUserShareSettingQueryVariables,
    APITypes.GetUserShareSettingQuery
  >;
export const listUserShareSettings = /* GraphQL */ `query ListUserShareSettings(
  $sub: ID
  $filter: ModelUserShareSettingFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUserShareSettings(
    sub: $sub
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      sub
      pathname
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserShareSettingsQueryVariables,
  APITypes.ListUserShareSettingsQuery
>;
export const getPrefacture = /* GraphQL */ `query GetPrefacture($id: ID!) {
  getPrefacture(id: $id) {
    id
    type
    position
    name
    area
    users {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPrefactureQueryVariables,
  APITypes.GetPrefactureQuery
>;
export const listPrefactures = /* GraphQL */ `query ListPrefactures(
  $filter: ModelPrefactureFilterInput
  $limit: Int
  $nextToken: String
) {
  listPrefactures(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      position
      name
      area
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPrefacturesQueryVariables,
  APITypes.ListPrefacturesQuery
>;
export const listPrefacturesByOrderByPosition =
  /* GraphQL */ `query ListPrefacturesByOrderByPosition(
  $type: String!
  $position: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPrefactureFilterInput
  $limit: Int
  $nextToken: String
) {
  listPrefacturesByOrderByPosition(
    type: $type
    position: $position
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      position
      name
      area
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListPrefacturesByOrderByPositionQueryVariables,
    APITypes.ListPrefacturesByOrderByPositionQuery
  >;
export const getJob = /* GraphQL */ `query GetJob($id: ID!) {
  getJob(id: $id) {
    id
    type
    position
    name
    skillCategories {
      nextToken
      __typename
    }
    skills {
      nextToken
      __typename
    }
    color
    users {
      nextToken
      __typename
    }
    openJobs {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetJobQueryVariables, APITypes.GetJobQuery>;
export const listJobs =
  /* GraphQL */ `query ListJobs($filter: ModelJobFilterInput, $limit: Int, $nextToken: String) {
  listJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      position
      name
      color
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListJobsQueryVariables, APITypes.ListJobsQuery>;
export const listJobsByOrderByPosition =
  /* GraphQL */ `query ListJobsByOrderByPosition(
  $type: String!
  $position: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelJobFilterInput
  $limit: Int
  $nextToken: String
) {
  listJobsByOrderByPosition(
    type: $type
    position: $position
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      position
      name
      color
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListJobsByOrderByPositionQueryVariables,
    APITypes.ListJobsByOrderByPositionQuery
  >;
export const getUserMainJobs = /* GraphQL */ `query GetUserMainJobs($id: ID!) {
  getUserMainJobs(id: $id) {
    id
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    jobID
    job {
      id
      type
      position
      name
      color
      createdAt
      updatedAt
      __typename
    }
    isPositive
    isSelected
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserMainJobsQueryVariables,
  APITypes.GetUserMainJobsQuery
>;
export const listUserMainJobs = /* GraphQL */ `query ListUserMainJobs(
  $filter: ModelUserMainJobsFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserMainJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userID
      jobID
      isPositive
      isSelected
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserMainJobsQueryVariables,
  APITypes.ListUserMainJobsQuery
>;
export const listUserMainJobsByUser =
  /* GraphQL */ `query ListUserMainJobsByUser(
  $userID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserMainJobsFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserMainJobsByUser(
    userID: $userID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID
      jobID
      isPositive
      isSelected
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListUserMainJobsByUserQueryVariables,
    APITypes.ListUserMainJobsByUserQuery
  >;
export const getOpenJobMainJobs =
  /* GraphQL */ `query GetOpenJobMainJobs($id: ID!) {
  getOpenJobMainJobs(id: $id) {
    id
    openJobID
    openJob {
      id
      organizationName
      consultantName
      owners
      isPublic
      skills
      isDraft
      title
      coverImage
      workType
      numberOfDayPerWeek
      numberOfHoursPerDay
      baseLocationTypes
      baseLocationDetail
      salaryType
      minumumSalary
      maximumSalary
      overView
      issue
      description
      recommendFor
      selectionProcessSupplement
      requiredSkillTags
      requiredSkillTagsDescription
      welcomeSkillTags
      welcomeSkillTagsDescription
      memo
      isHideOrganizationName
      organizationNameForHidden
      isHideSkills
      skillNamesForHidden
      isExcellent
      createdAt
      updatedAt
      organizationOpenJobsId
      openJobJobId
      openJobBaseLocationId
      __typename
    }
    jobID
    job {
      id
      type
      position
      name
      color
      createdAt
      updatedAt
      __typename
    }
    isSelected
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetOpenJobMainJobsQueryVariables,
    APITypes.GetOpenJobMainJobsQuery
  >;
export const listOpenJobMainJobs = /* GraphQL */ `query ListOpenJobMainJobs(
  $filter: ModelOpenJobMainJobsFilterInput
  $limit: Int
  $nextToken: String
) {
  listOpenJobMainJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      openJobID
      jobID
      isSelected
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOpenJobMainJobsQueryVariables,
  APITypes.ListOpenJobMainJobsQuery
>;
export const listOpenJobMainJobsByOpenJob =
  /* GraphQL */ `query ListOpenJobMainJobsByOpenJob(
  $openJobID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelOpenJobMainJobsFilterInput
  $limit: Int
  $nextToken: String
) {
  listOpenJobMainJobsByOpenJob(
    openJobID: $openJobID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      openJobID
      jobID
      isSelected
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListOpenJobMainJobsByOpenJobQueryVariables,
    APITypes.ListOpenJobMainJobsByOpenJobQuery
  >;
export const getSkillCategory =
  /* GraphQL */ `query GetSkillCategory($id: ID!) {
  getSkillCategory(id: $id) {
    id
    type
    position
    name
    job {
      id
      type
      position
      name
      color
      createdAt
      updatedAt
      __typename
    }
    skills {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    jobSkillCategoriesId
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetSkillCategoryQueryVariables,
    APITypes.GetSkillCategoryQuery
  >;
export const listSkillCategories = /* GraphQL */ `query ListSkillCategories(
  $filter: ModelSkillCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listSkillCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      position
      name
      createdAt
      updatedAt
      jobSkillCategoriesId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSkillCategoriesQueryVariables,
  APITypes.ListSkillCategoriesQuery
>;
export const listSkillCategoriesByOrderByPosition =
  /* GraphQL */ `query ListSkillCategoriesByOrderByPosition(
  $type: String!
  $position: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSkillCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listSkillCategoriesByOrderByPosition(
    type: $type
    position: $position
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      position
      name
      createdAt
      updatedAt
      jobSkillCategoriesId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListSkillCategoriesByOrderByPositionQueryVariables,
    APITypes.ListSkillCategoriesByOrderByPositionQuery
  >;
export const getSkill = /* GraphQL */ `query GetSkill($id: ID!) {
  getSkill(id: $id) {
    id
    type
    position
    name
    job {
      id
      type
      position
      name
      color
      createdAt
      updatedAt
      __typename
    }
    skillCategory {
      id
      type
      position
      name
      createdAt
      updatedAt
      jobSkillCategoriesId
      __typename
    }
    skillTags {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    jobSkillsId
    skillCategorySkillsId
    skillJobId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetSkillQueryVariables, APITypes.GetSkillQuery>;
export const listSkills = /* GraphQL */ `query ListSkills(
  $filter: ModelSkillFilterInput
  $limit: Int
  $nextToken: String
) {
  listSkills(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      position
      name
      createdAt
      updatedAt
      jobSkillsId
      skillCategorySkillsId
      skillJobId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSkillsQueryVariables,
  APITypes.ListSkillsQuery
>;
export const listSkillsByOrderByPosition =
  /* GraphQL */ `query ListSkillsByOrderByPosition(
  $type: String!
  $position: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSkillFilterInput
  $limit: Int
  $nextToken: String
) {
  listSkillsByOrderByPosition(
    type: $type
    position: $position
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      position
      name
      createdAt
      updatedAt
      jobSkillsId
      skillCategorySkillsId
      skillJobId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListSkillsByOrderByPositionQueryVariables,
    APITypes.ListSkillsByOrderByPositionQuery
  >;
export const getSkillTag = /* GraphQL */ `query GetSkillTag($id: ID!) {
  getSkillTag(id: $id) {
    id
    type
    position
    name
    skill {
      id
      type
      position
      name
      createdAt
      updatedAt
      jobSkillsId
      skillCategorySkillsId
      skillJobId
      __typename
    }
    lead
    createdAt
    updatedAt
    skillSkillTagsId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSkillTagQueryVariables,
  APITypes.GetSkillTagQuery
>;
export const listSkillTags = /* GraphQL */ `query ListSkillTags(
  $filter: ModelSkillTagFilterInput
  $limit: Int
  $nextToken: String
) {
  listSkillTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      position
      name
      lead
      createdAt
      updatedAt
      skillSkillTagsId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSkillTagsQueryVariables,
  APITypes.ListSkillTagsQuery
>;
export const listSkillTagsByOrderByPosition =
  /* GraphQL */ `query ListSkillTagsByOrderByPosition(
  $type: String!
  $position: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSkillTagFilterInput
  $limit: Int
  $nextToken: String
) {
  listSkillTagsByOrderByPosition(
    type: $type
    position: $position
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      position
      name
      lead
      createdAt
      updatedAt
      skillSkillTagsId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListSkillTagsByOrderByPositionQueryVariables,
    APITypes.ListSkillTagsByOrderByPositionQuery
  >;
export const getWorkHistory = /* GraphQL */ `query GetWorkHistory($id: ID!) {
  getWorkHistory(id: $id) {
    id
    owner
    startDate
    endDate
    project
    isSideWork
    isCurrentJob
    isLastJob
    isLastSchool
    historyType
    classification
    industry
    position
    isContinuation
    isDropOut
    baseLocationType
    achievements {
      nextToken
      __typename
    }
    workHistoryDivisions {
      nextToken
      __typename
    }
    organizationName
    description
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetWorkHistoryQueryVariables,
  APITypes.GetWorkHistoryQuery
>;
export const listWorkHistories = /* GraphQL */ `query ListWorkHistories(
  $filter: ModelWorkHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listWorkHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      startDate
      endDate
      project
      isSideWork
      isCurrentJob
      isLastJob
      isLastSchool
      historyType
      classification
      industry
      position
      isContinuation
      isDropOut
      baseLocationType
      organizationName
      description
      userID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListWorkHistoriesQueryVariables,
  APITypes.ListWorkHistoriesQuery
>;
export const listWorkHistoriesByUser =
  /* GraphQL */ `query ListWorkHistoriesByUser(
  $userID: ID!
  $position: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelWorkHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listWorkHistoriesByUser(
    userID: $userID
    position: $position
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      startDate
      endDate
      project
      isSideWork
      isCurrentJob
      isLastJob
      isLastSchool
      historyType
      classification
      industry
      position
      isContinuation
      isDropOut
      baseLocationType
      organizationName
      description
      userID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListWorkHistoriesByUserQueryVariables,
    APITypes.ListWorkHistoriesByUserQuery
  >;
export const getWorkHistoryAchievement =
  /* GraphQL */ `query GetWorkHistoryAchievement($id: ID!) {
  getWorkHistoryAchievement(id: $id) {
    id
    workHistoryID
    workHistory {
      id
      owner
      startDate
      endDate
      project
      isSideWork
      isCurrentJob
      isLastJob
      isLastSchool
      historyType
      classification
      industry
      position
      isContinuation
      isDropOut
      baseLocationType
      organizationName
      description
      userID
      createdAt
      updatedAt
      __typename
    }
    projectName
    coverImage
    description
    topics
    role
    uploads {
      nextToken
      __typename
    }
    links {
      nextToken
      __typename
    }
    division
    position
    startDate
    endDate
    isContinuation
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetWorkHistoryAchievementQueryVariables,
    APITypes.GetWorkHistoryAchievementQuery
  >;
export const listWorkHistoryAchievements =
  /* GraphQL */ `query ListWorkHistoryAchievements(
  $filter: ModelWorkHistoryAchievementFilterInput
  $limit: Int
  $nextToken: String
) {
  listWorkHistoryAchievements(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      workHistoryID
      projectName
      coverImage
      description
      topics
      role
      division
      position
      startDate
      endDate
      isContinuation
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListWorkHistoryAchievementsQueryVariables,
    APITypes.ListWorkHistoryAchievementsQuery
  >;
export const listWorkHistoryAchievementsByWorkHistory =
  /* GraphQL */ `query ListWorkHistoryAchievementsByWorkHistory(
  $workHistoryID: ID!
  $position: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelWorkHistoryAchievementFilterInput
  $limit: Int
  $nextToken: String
) {
  listWorkHistoryAchievementsByWorkHistory(
    workHistoryID: $workHistoryID
    position: $position
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      workHistoryID
      projectName
      coverImage
      description
      topics
      role
      division
      position
      startDate
      endDate
      isContinuation
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListWorkHistoryAchievementsByWorkHistoryQueryVariables,
    APITypes.ListWorkHistoryAchievementsByWorkHistoryQuery
  >;
export const getWorkHistoryAchievementLink =
  /* GraphQL */ `query GetWorkHistoryAchievementLink($id: ID!) {
  getWorkHistoryAchievementLink(id: $id) {
    id
    owner
    achievement {
      id
      workHistoryID
      projectName
      coverImage
      description
      topics
      role
      division
      position
      startDate
      endDate
      isContinuation
      createdAt
      updatedAt
      owner
      __typename
    }
    title
    url
    createdAt
    updatedAt
    workHistoryAchievementLinksId
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetWorkHistoryAchievementLinkQueryVariables,
    APITypes.GetWorkHistoryAchievementLinkQuery
  >;
export const listWorkHistoryAchievementLinks =
  /* GraphQL */ `query ListWorkHistoryAchievementLinks(
  $filter: ModelWorkHistoryAchievementLinkFilterInput
  $limit: Int
  $nextToken: String
) {
  listWorkHistoryAchievementLinks(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      title
      url
      createdAt
      updatedAt
      workHistoryAchievementLinksId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListWorkHistoryAchievementLinksQueryVariables,
    APITypes.ListWorkHistoryAchievementLinksQuery
  >;
export const getWorkHistoryAchievementResume =
  /* GraphQL */ `query GetWorkHistoryAchievementResume($id: ID!) {
  getWorkHistoryAchievementResume(id: $id) {
    id
    owner
    achievement {
      id
      workHistoryID
      projectName
      coverImage
      description
      topics
      role
      division
      position
      startDate
      endDate
      isContinuation
      createdAt
      updatedAt
      owner
      __typename
    }
    file
    filename
    fileType
    thumbnail
    createdAt
    updatedAt
    workHistoryAchievementUploadsId
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetWorkHistoryAchievementResumeQueryVariables,
    APITypes.GetWorkHistoryAchievementResumeQuery
  >;
export const listWorkHistoryAchievementResumes =
  /* GraphQL */ `query ListWorkHistoryAchievementResumes(
  $filter: ModelWorkHistoryAchievementResumeFilterInput
  $limit: Int
  $nextToken: String
) {
  listWorkHistoryAchievementResumes(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      file
      filename
      fileType
      thumbnail
      createdAt
      updatedAt
      workHistoryAchievementUploadsId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListWorkHistoryAchievementResumesQueryVariables,
    APITypes.ListWorkHistoryAchievementResumesQuery
  >;
export const getWorkHistoryDivision =
  /* GraphQL */ `query GetWorkHistoryDivision($id: ID!) {
  getWorkHistoryDivision(id: $id) {
    id
    owner
    name
    workHistoryID
    workHistory {
      id
      owner
      startDate
      endDate
      project
      isSideWork
      isCurrentJob
      isLastJob
      isLastSchool
      historyType
      classification
      industry
      position
      isContinuation
      isDropOut
      baseLocationType
      organizationName
      description
      userID
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetWorkHistoryDivisionQueryVariables,
    APITypes.GetWorkHistoryDivisionQuery
  >;
export const listWorkHistoryDivisions =
  /* GraphQL */ `query ListWorkHistoryDivisions(
  $filter: ModelWorkHistoryDivisionFilterInput
  $limit: Int
  $nextToken: String
) {
  listWorkHistoryDivisions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      name
      workHistoryID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListWorkHistoryDivisionsQueryVariables,
    APITypes.ListWorkHistoryDivisionsQuery
  >;
export const listWorkHistoryDivisionsByWorkHistory =
  /* GraphQL */ `query ListWorkHistoryDivisionsByWorkHistory(
  $workHistoryID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelWorkHistoryDivisionFilterInput
  $limit: Int
  $nextToken: String
) {
  listWorkHistoryDivisionsByWorkHistory(
    workHistoryID: $workHistoryID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      name
      workHistoryID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListWorkHistoryDivisionsByWorkHistoryQueryVariables,
    APITypes.ListWorkHistoryDivisionsByWorkHistoryQuery
  >;
export const getUserResume = /* GraphQL */ `query GetUserResume($id: ID!) {
  getUserResume(id: $id) {
    id
    owner
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    file
    filename
    fileType
    createdAt
    updatedAt
    userResumeFilesId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserResumeQueryVariables,
  APITypes.GetUserResumeQuery
>;
export const listUserResumes = /* GraphQL */ `query ListUserResumes(
  $filter: ModelUserResumeFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserResumes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      file
      filename
      fileType
      createdAt
      updatedAt
      userResumeFilesId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserResumesQueryVariables,
  APITypes.ListUserResumesQuery
>;
export const getUserPortfolio =
  /* GraphQL */ `query GetUserPortfolio($id: ID!) {
  getUserPortfolio(id: $id) {
    id
    owner
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    type
    content
    title
    createdAt
    updatedAt
    userPortfolioFilesId
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetUserPortfolioQueryVariables,
    APITypes.GetUserPortfolioQuery
  >;
export const listUserPortfolios = /* GraphQL */ `query ListUserPortfolios(
  $filter: ModelUserPortfolioFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserPortfolios(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      type
      content
      title
      createdAt
      updatedAt
      userPortfolioFilesId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserPortfoliosQueryVariables,
  APITypes.ListUserPortfoliosQuery
>;
export const getSkillSet = /* GraphQL */ `query GetSkillSet($id: ID!) {
  getSkillSet(id: $id) {
    id
    owner
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    skill {
      id
      type
      position
      name
      createdAt
      updatedAt
      jobSkillsId
      skillCategorySkillsId
      skillJobId
      __typename
    }
    skillTag {
      id
      type
      position
      name
      lead
      createdAt
      updatedAt
      skillSkillTagsId
      __typename
    }
    yearsOfExperience
    createdAt
    updatedAt
    userSkillSetsId
    skillSetSkillId
    skillSetSkillTagId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSkillSetQueryVariables,
  APITypes.GetSkillSetQuery
>;
export const listSkillSets = /* GraphQL */ `query ListSkillSets(
  $filter: ModelSkillSetFilterInput
  $limit: Int
  $nextToken: String
) {
  listSkillSets(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      yearsOfExperience
      createdAt
      updatedAt
      userSkillSetsId
      skillSetSkillId
      skillSetSkillTagId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSkillSetsQueryVariables,
  APITypes.ListSkillSetsQuery
>;
export const searchSkillSets = /* GraphQL */ `query SearchSkillSets(
  $filter: SearchableSkillSetFilterInput
  $sort: [SearchableSkillSetSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableSkillSetAggregationInput]
) {
  searchSkillSets(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      owner
      yearsOfExperience
      createdAt
      updatedAt
      userSkillSetsId
      skillSetSkillId
      skillSetSkillTagId
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchSkillSetsQueryVariables,
  APITypes.SearchSkillSetsQuery
>;
export const getUserOpenJobs = /* GraphQL */ `query GetUserOpenJobs($id: ID!) {
  getUserOpenJobs(id: $id) {
    id
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    openJobID
    openJob {
      id
      organizationName
      consultantName
      owners
      isPublic
      skills
      isDraft
      title
      coverImage
      workType
      numberOfDayPerWeek
      numberOfHoursPerDay
      baseLocationTypes
      baseLocationDetail
      salaryType
      minumumSalary
      maximumSalary
      overView
      issue
      description
      recommendFor
      selectionProcessSupplement
      requiredSkillTags
      requiredSkillTagsDescription
      welcomeSkillTags
      welcomeSkillTagsDescription
      memo
      isHideOrganizationName
      organizationNameForHidden
      isHideSkills
      skillNamesForHidden
      isExcellent
      createdAt
      updatedAt
      organizationOpenJobsId
      openJobJobId
      openJobBaseLocationId
      __typename
    }
    involveType
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserOpenJobsQueryVariables,
  APITypes.GetUserOpenJobsQuery
>;
export const listUserOpenJobs = /* GraphQL */ `query ListUserOpenJobs(
  $filter: ModelUserOpenJobsFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserOpenJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userID
      openJobID
      involveType
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserOpenJobsQueryVariables,
  APITypes.ListUserOpenJobsQuery
>;
export const listUserOpenJobsByUser =
  /* GraphQL */ `query ListUserOpenJobsByUser(
  $userID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserOpenJobsFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserOpenJobsByUser(
    userID: $userID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID
      openJobID
      involveType
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListUserOpenJobsByUserQueryVariables,
    APITypes.ListUserOpenJobsByUserQuery
  >;
export const getOpenJob = /* GraphQL */ `query GetOpenJob($id: ID!) {
  getOpenJob(id: $id) {
    id
    organization {
      id
      owners
      name
      organizationNameForHidden
      isHideOrganizationName
      icon
      description
      createdAt
      updatedAt
      __typename
    }
    job {
      id
      type
      position
      name
      color
      createdAt
      updatedAt
      __typename
    }
    jobs {
      nextToken
      __typename
    }
    users {
      nextToken
      __typename
    }
    organizationName
    consultantName
    owners
    isPublic
    skills
    isDraft
    title
    coverImage
    workType
    numberOfDayPerWeek
    numberOfHoursPerDay
    baseLocationTypes
    baseLocation {
      id
      type
      position
      name
      area
      createdAt
      updatedAt
      owner
      __typename
    }
    baseLocationDetail
    salaryType
    minumumSalary
    maximumSalary
    overView
    issue
    description
    recommendFor
    selectionProcess {
      nextToken
      __typename
    }
    selectionProcessSupplement
    requiredSkillTags
    requiredSkillTagsDescription
    welcomeSkillTags
    welcomeSkillTagsDescription
    favorites {
      nextToken
      __typename
    }
    applyUsers {
      nextToken
      __typename
    }
    conversations {
      nextToken
      __typename
    }
    memo
    isHideOrganizationName
    organizationNameForHidden
    isHideSkills
    skillNamesForHidden
    isExcellent
    skillbaWorkHistories {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    organizationOpenJobsId
    openJobJobId
    openJobBaseLocationId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOpenJobQueryVariables,
  APITypes.GetOpenJobQuery
>;
export const listOpenJobs = /* GraphQL */ `query ListOpenJobs(
  $filter: ModelOpenJobFilterInput
  $limit: Int
  $nextToken: String
) {
  listOpenJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      organizationName
      consultantName
      owners
      isPublic
      skills
      isDraft
      title
      coverImage
      workType
      numberOfDayPerWeek
      numberOfHoursPerDay
      baseLocationTypes
      baseLocationDetail
      salaryType
      minumumSalary
      maximumSalary
      overView
      issue
      description
      recommendFor
      selectionProcessSupplement
      requiredSkillTags
      requiredSkillTagsDescription
      welcomeSkillTags
      welcomeSkillTagsDescription
      memo
      isHideOrganizationName
      organizationNameForHidden
      isHideSkills
      skillNamesForHidden
      isExcellent
      createdAt
      updatedAt
      organizationOpenJobsId
      openJobJobId
      openJobBaseLocationId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOpenJobsQueryVariables,
  APITypes.ListOpenJobsQuery
>;
export const searchOpenJobs = /* GraphQL */ `query SearchOpenJobs(
  $filter: SearchableOpenJobFilterInput
  $sort: [SearchableOpenJobSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableOpenJobAggregationInput]
) {
  searchOpenJobs(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      organizationName
      consultantName
      owners
      isPublic
      skills
      isDraft
      title
      coverImage
      workType
      numberOfDayPerWeek
      numberOfHoursPerDay
      baseLocationTypes
      baseLocationDetail
      salaryType
      minumumSalary
      maximumSalary
      overView
      issue
      description
      recommendFor
      selectionProcessSupplement
      requiredSkillTags
      requiredSkillTagsDescription
      welcomeSkillTags
      welcomeSkillTagsDescription
      memo
      isHideOrganizationName
      organizationNameForHidden
      isHideSkills
      skillNamesForHidden
      isExcellent
      createdAt
      updatedAt
      organizationOpenJobsId
      openJobJobId
      openJobBaseLocationId
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchOpenJobsQueryVariables,
  APITypes.SearchOpenJobsQuery
>;
export const getSelectionProcess =
  /* GraphQL */ `query GetSelectionProcess($id: ID!) {
  getSelectionProcess(id: $id) {
    id
    owner
    openJobID
    openJob {
      id
      organizationName
      consultantName
      owners
      isPublic
      skills
      isDraft
      title
      coverImage
      workType
      numberOfDayPerWeek
      numberOfHoursPerDay
      baseLocationTypes
      baseLocationDetail
      salaryType
      minumumSalary
      maximumSalary
      overView
      issue
      description
      recommendFor
      selectionProcessSupplement
      requiredSkillTags
      requiredSkillTagsDescription
      welcomeSkillTags
      welcomeSkillTagsDescription
      memo
      isHideOrganizationName
      organizationNameForHidden
      isHideSkills
      skillNamesForHidden
      isExcellent
      createdAt
      updatedAt
      organizationOpenJobsId
      openJobJobId
      openJobBaseLocationId
      __typename
    }
    step
    title
    description
    createdAt
    updatedAt
    openJobSelectionProcessId
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetSelectionProcessQueryVariables,
    APITypes.GetSelectionProcessQuery
  >;
export const listSelectionProcesses =
  /* GraphQL */ `query ListSelectionProcesses(
  $filter: ModelSelectionProcessFilterInput
  $limit: Int
  $nextToken: String
) {
  listSelectionProcesses(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      openJobID
      step
      title
      description
      createdAt
      updatedAt
      openJobSelectionProcessId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListSelectionProcessesQueryVariables,
    APITypes.ListSelectionProcessesQuery
  >;
export const listSelectionProcessByOpenJob =
  /* GraphQL */ `query ListSelectionProcessByOpenJob(
  $openJobID: ID!
  $step: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelselectionProcessFilterInput
  $limit: Int
  $nextToken: String
) {
  listSelectionProcessByOpenJob(
    openJobID: $openJobID
    step: $step
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      openJobID
      step
      title
      description
      createdAt
      updatedAt
      openJobSelectionProcessId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListSelectionProcessByOpenJobQueryVariables,
    APITypes.ListSelectionProcessByOpenJobQuery
  >;
export const getUserFavoriteOpenJobs =
  /* GraphQL */ `query GetUserFavoriteOpenJobs($id: ID!) {
  getUserFavoriteOpenJobs(id: $id) {
    id
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    openJobID
    openJob {
      id
      organizationName
      consultantName
      owners
      isPublic
      skills
      isDraft
      title
      coverImage
      workType
      numberOfDayPerWeek
      numberOfHoursPerDay
      baseLocationTypes
      baseLocationDetail
      salaryType
      minumumSalary
      maximumSalary
      overView
      issue
      description
      recommendFor
      selectionProcessSupplement
      requiredSkillTags
      requiredSkillTagsDescription
      welcomeSkillTags
      welcomeSkillTagsDescription
      memo
      isHideOrganizationName
      organizationNameForHidden
      isHideSkills
      skillNamesForHidden
      isExcellent
      createdAt
      updatedAt
      organizationOpenJobsId
      openJobJobId
      openJobBaseLocationId
      __typename
    }
    isFavorite
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetUserFavoriteOpenJobsQueryVariables,
    APITypes.GetUserFavoriteOpenJobsQuery
  >;
export const listUserFavoriteOpenJobs =
  /* GraphQL */ `query ListUserFavoriteOpenJobs(
  $filter: ModelUserFavoriteOpenJobsFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserFavoriteOpenJobs(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID
      openJobID
      isFavorite
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListUserFavoriteOpenJobsQueryVariables,
    APITypes.ListUserFavoriteOpenJobsQuery
  >;
export const listUserFavoriteOpenJobsByUser =
  /* GraphQL */ `query ListUserFavoriteOpenJobsByUser(
  $userID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserFavoriteOpenJobsFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserFavoriteOpenJobsByUser(
    userID: $userID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID
      openJobID
      isFavorite
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListUserFavoriteOpenJobsByUserQueryVariables,
    APITypes.ListUserFavoriteOpenJobsByUserQuery
  >;
export const searchUserFavoriteOpenJobs =
  /* GraphQL */ `query SearchUserFavoriteOpenJobs(
  $filter: SearchableUserFavoriteOpenJobsFilterInput
  $sort: [SearchableUserFavoriteOpenJobsSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableUserFavoriteOpenJobsAggregationInput]
) {
  searchUserFavoriteOpenJobs(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      userID
      openJobID
      isFavorite
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
    APITypes.SearchUserFavoriteOpenJobsQueryVariables,
    APITypes.SearchUserFavoriteOpenJobsQuery
  >;
export const getUserPrefactures =
  /* GraphQL */ `query GetUserPrefactures($id: ID!) {
  getUserPrefactures(id: $id) {
    id
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    prefactureID
    prefacture {
      id
      type
      position
      name
      area
      createdAt
      updatedAt
      owner
      __typename
    }
    isSelected
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetUserPrefacturesQueryVariables,
    APITypes.GetUserPrefacturesQuery
  >;
export const listUserPrefactures = /* GraphQL */ `query ListUserPrefactures(
  $filter: ModelUserPrefacturesFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserPrefactures(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userID
      prefactureID
      isSelected
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserPrefacturesQueryVariables,
  APITypes.ListUserPrefacturesQuery
>;
export const listUserPrefacturesByUser =
  /* GraphQL */ `query ListUserPrefacturesByUser(
  $userID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserPrefacturesFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserPrefacturesByUser(
    userID: $userID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID
      prefactureID
      isSelected
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListUserPrefacturesByUserQueryVariables,
    APITypes.ListUserPrefacturesByUserQuery
  >;
export const getUserApplies = /* GraphQL */ `query GetUserApplies($id: ID!) {
  getUserApplies(id: $id) {
    id
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    openJobID
    openJob {
      id
      organizationName
      consultantName
      owners
      isPublic
      skills
      isDraft
      title
      coverImage
      workType
      numberOfDayPerWeek
      numberOfHoursPerDay
      baseLocationTypes
      baseLocationDetail
      salaryType
      minumumSalary
      maximumSalary
      overView
      issue
      description
      recommendFor
      selectionProcessSupplement
      requiredSkillTags
      requiredSkillTagsDescription
      welcomeSkillTags
      welcomeSkillTagsDescription
      memo
      isHideOrganizationName
      organizationNameForHidden
      isHideSkills
      skillNamesForHidden
      isExcellent
      createdAt
      updatedAt
      organizationOpenJobsId
      openJobJobId
      openJobBaseLocationId
      __typename
    }
    type
    url
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserAppliesQueryVariables,
  APITypes.GetUserAppliesQuery
>;
export const listUserApplies = /* GraphQL */ `query ListUserApplies(
  $filter: ModelUserAppliesFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserApplies(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userID
      openJobID
      type
      url
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserAppliesQueryVariables,
  APITypes.ListUserAppliesQuery
>;
export const listUserAppliesByUser = /* GraphQL */ `query ListUserAppliesByUser(
  $userID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserAppliesFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserAppliesByUser(
    userID: $userID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID
      openJobID
      type
      url
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserAppliesByUserQueryVariables,
  APITypes.ListUserAppliesByUserQuery
>;
export const listUserAppliesByCreatedAt =
  /* GraphQL */ `query ListUserAppliesByCreatedAt(
  $type: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserAppliesFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserAppliesByCreatedAt(
    type: $type
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID
      openJobID
      type
      url
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListUserAppliesByCreatedAtQueryVariables,
    APITypes.ListUserAppliesByCreatedAtQuery
  >;
export const getEmployeeBenefit =
  /* GraphQL */ `query GetEmployeeBenefit($id: ID!) {
  getEmployeeBenefit(id: $id) {
    id
    title
    organization {
      id
      name
      icon
      createdAt
      updatedAt
      __typename
    }
    type
    tags
    useProcesses
    usedUsers {
      nextToken
      __typename
    }
    coverImage
    couponCode
    url
    isDraft
    createdAt
    updatedAt
    employeeBenefitOrganizationEmployeeBenefitsId
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetEmployeeBenefitQueryVariables,
    APITypes.GetEmployeeBenefitQuery
  >;
export const listEmployeeBenefits = /* GraphQL */ `query ListEmployeeBenefits(
  $filter: ModelEmployeeBenefitFilterInput
  $limit: Int
  $nextToken: String
) {
  listEmployeeBenefits(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      type
      tags
      useProcesses
      coverImage
      couponCode
      url
      isDraft
      createdAt
      updatedAt
      employeeBenefitOrganizationEmployeeBenefitsId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEmployeeBenefitsQueryVariables,
  APITypes.ListEmployeeBenefitsQuery
>;
export const listEmployeeBenefitByCreatedAt =
  /* GraphQL */ `query ListEmployeeBenefitByCreatedAt(
  $type: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEmployeeBenefitFilterInput
  $limit: Int
  $nextToken: String
) {
  listEmployeeBenefitByCreatedAt(
    type: $type
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      type
      tags
      useProcesses
      coverImage
      couponCode
      url
      isDraft
      createdAt
      updatedAt
      employeeBenefitOrganizationEmployeeBenefitsId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListEmployeeBenefitByCreatedAtQueryVariables,
    APITypes.ListEmployeeBenefitByCreatedAtQuery
  >;
export const searchEmployeeBenefits =
  /* GraphQL */ `query SearchEmployeeBenefits(
  $filter: SearchableEmployeeBenefitFilterInput
  $sort: [SearchableEmployeeBenefitSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableEmployeeBenefitAggregationInput]
) {
  searchEmployeeBenefits(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      title
      type
      tags
      useProcesses
      coverImage
      couponCode
      url
      isDraft
      createdAt
      updatedAt
      employeeBenefitOrganizationEmployeeBenefitsId
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
    APITypes.SearchEmployeeBenefitsQueryVariables,
    APITypes.SearchEmployeeBenefitsQuery
  >;
export const getEmployeeBenefitTag =
  /* GraphQL */ `query GetEmployeeBenefitTag($id: ID!) {
  getEmployeeBenefitTag(id: $id) {
    id
    name
    type
    position
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetEmployeeBenefitTagQueryVariables,
    APITypes.GetEmployeeBenefitTagQuery
  >;
export const listEmployeeBenefitTags =
  /* GraphQL */ `query ListEmployeeBenefitTags(
  $filter: ModelEmployeeBenefitTagFilterInput
  $limit: Int
  $nextToken: String
) {
  listEmployeeBenefitTags(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      type
      position
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListEmployeeBenefitTagsQueryVariables,
    APITypes.ListEmployeeBenefitTagsQuery
  >;
export const listEmployeeBenefitTagByPosition =
  /* GraphQL */ `query ListEmployeeBenefitTagByPosition(
  $type: String!
  $position: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEmployeeBenefitTagFilterInput
  $limit: Int
  $nextToken: String
) {
  listEmployeeBenefitTagByPosition(
    type: $type
    position: $position
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      type
      position
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListEmployeeBenefitTagByPositionQueryVariables,
    APITypes.ListEmployeeBenefitTagByPositionQuery
  >;
export const getEmployeeBenefitOrganization =
  /* GraphQL */ `query GetEmployeeBenefitOrganization($id: ID!) {
  getEmployeeBenefitOrganization(id: $id) {
    id
    name
    icon
    employeeBenefits {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetEmployeeBenefitOrganizationQueryVariables,
    APITypes.GetEmployeeBenefitOrganizationQuery
  >;
export const listEmployeeBenefitOrganizations =
  /* GraphQL */ `query ListEmployeeBenefitOrganizations(
  $filter: ModelEmployeeBenefitOrganizationFilterInput
  $limit: Int
  $nextToken: String
) {
  listEmployeeBenefitOrganizations(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      icon
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListEmployeeBenefitOrganizationsQueryVariables,
    APITypes.ListEmployeeBenefitOrganizationsQuery
  >;
export const getUserEmployeeBenefits =
  /* GraphQL */ `query GetUserEmployeeBenefits($id: ID!) {
  getUserEmployeeBenefits(id: $id) {
    id
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    employeeBenefitID
    employeeBenefit {
      id
      title
      type
      tags
      useProcesses
      coverImage
      couponCode
      url
      isDraft
      createdAt
      updatedAt
      employeeBenefitOrganizationEmployeeBenefitsId
      __typename
    }
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetUserEmployeeBenefitsQueryVariables,
    APITypes.GetUserEmployeeBenefitsQuery
  >;
export const listUserEmployeeBenefits =
  /* GraphQL */ `query ListUserEmployeeBenefits(
  $filter: ModelUserEmployeeBenefitsFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserEmployeeBenefits(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID
      employeeBenefitID
      status
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListUserEmployeeBenefitsQueryVariables,
    APITypes.ListUserEmployeeBenefitsQuery
  >;
export const listUserEmployeeBenefitsByUser =
  /* GraphQL */ `query ListUserEmployeeBenefitsByUser(
  $userID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserEmployeeBenefitsFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserEmployeeBenefitsByUser(
    userID: $userID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID
      employeeBenefitID
      status
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListUserEmployeeBenefitsByUserQueryVariables,
    APITypes.ListUserEmployeeBenefitsByUserQuery
  >;
export const getEvaluation = /* GraphQL */ `query GetEvaluation($id: ID!) {
  getEvaluation(id: $id) {
    id
    informationGatheringRating
    analysisRating
    writeDocumentsRating
    communicationRating
    presentationRating
    totalRating
    published
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    picName
    picId
    picOrganizationName
    picComment
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEvaluationQueryVariables,
  APITypes.GetEvaluationQuery
>;
export const listEvaluations = /* GraphQL */ `query ListEvaluations(
  $filter: ModelEvaluationFilterInput
  $limit: Int
  $nextToken: String
) {
  listEvaluations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      informationGatheringRating
      analysisRating
      writeDocumentsRating
      communicationRating
      presentationRating
      totalRating
      published
      userID
      picName
      picId
      picOrganizationName
      picComment
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEvaluationsQueryVariables,
  APITypes.ListEvaluationsQuery
>;
export const listUserEvaluationsByUser =
  /* GraphQL */ `query ListUserEvaluationsByUser(
  $userID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEvaluationFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserEvaluationsByUser(
    userID: $userID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      informationGatheringRating
      analysisRating
      writeDocumentsRating
      communicationRating
      presentationRating
      totalRating
      published
      userID
      picName
      picId
      picOrganizationName
      picComment
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListUserEvaluationsByUserQueryVariables,
    APITypes.ListUserEvaluationsByUserQuery
  >;
export const getEvaluationComment =
  /* GraphQL */ `query GetEvaluationComment($id: ID!) {
  getEvaluationComment(id: $id) {
    id
    message
    rate
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    commentatorID
    commentator {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetEvaluationCommentQueryVariables,
    APITypes.GetEvaluationCommentQuery
  >;
export const listEvaluationComments =
  /* GraphQL */ `query ListEvaluationComments(
  $filter: ModelEvaluationCommentFilterInput
  $limit: Int
  $nextToken: String
) {
  listEvaluationComments(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      message
      rate
      userID
      commentatorID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListEvaluationCommentsQueryVariables,
    APITypes.ListEvaluationCommentsQuery
  >;
export const listUserEvaluationCommentsByUser =
  /* GraphQL */ `query ListUserEvaluationCommentsByUser(
  $userID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEvaluationCommentFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserEvaluationCommentsByUser(
    userID: $userID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      message
      rate
      userID
      commentatorID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListUserEvaluationCommentsByUserQueryVariables,
    APITypes.ListUserEvaluationCommentsByUserQuery
  >;
export const listUserEvaluationCommentsByCommentator =
  /* GraphQL */ `query ListUserEvaluationCommentsByCommentator(
  $commentatorID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEvaluationCommentFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserEvaluationCommentsByCommentator(
    commentatorID: $commentatorID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      message
      rate
      userID
      commentatorID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListUserEvaluationCommentsByCommentatorQueryVariables,
    APITypes.ListUserEvaluationCommentsByCommentatorQuery
  >;
export const getWPWorker = /* GraphQL */ `query GetWPWorker($id: ID!) {
  getWPWorker(id: $id) {
    id
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    categories
    status
    url
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetWPWorkerQueryVariables,
  APITypes.GetWPWorkerQuery
>;
export const listWPWorkers = /* GraphQL */ `query ListWPWorkers(
  $filter: ModelWPWorkerFilterInput
  $limit: Int
  $nextToken: String
) {
  listWPWorkers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userID
      categories
      status
      url
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListWPWorkersQueryVariables,
  APITypes.ListWPWorkersQuery
>;
export const listWPWorkerByCreatedAt =
  /* GraphQL */ `query ListWPWorkerByCreatedAt(
  $userID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelWPWorkerFilterInput
  $limit: Int
  $nextToken: String
) {
  listWPWorkerByCreatedAt(
    userID: $userID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID
      categories
      status
      url
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListWPWorkerByCreatedAtQueryVariables,
    APITypes.ListWPWorkerByCreatedAtQuery
  >;
export const getWPWorkerCategory =
  /* GraphQL */ `query GetWPWorkerCategory($id: ID!) {
  getWPWorkerCategory(id: $id) {
    id
    name
    url
    coverImage
    description
    wpWorkers
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetWPWorkerCategoryQueryVariables,
    APITypes.GetWPWorkerCategoryQuery
  >;
export const listWPWorkerCategories =
  /* GraphQL */ `query ListWPWorkerCategories(
  $filter: ModelWPWorkerCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listWPWorkerCategories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      url
      coverImage
      description
      wpWorkers
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListWPWorkerCategoriesQueryVariables,
    APITypes.ListWPWorkerCategoriesQuery
  >;
export const getQualification =
  /* GraphQL */ `query GetQualification($id: ID!) {
  getQualification(id: $id) {
    id
    owners
    name
    organization
    userID
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    releaseDate
    expirationDate
    isNoExpiry
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetQualificationQueryVariables,
    APITypes.GetQualificationQuery
  >;
export const listQualifications = /* GraphQL */ `query ListQualifications(
  $filter: ModelQualificationFilterInput
  $limit: Int
  $nextToken: String
) {
  listQualifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owners
      name
      organization
      userID
      releaseDate
      expirationDate
      isNoExpiry
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListQualificationsQueryVariables,
  APITypes.ListQualificationsQuery
>;
export const listQualificationByUser =
  /* GraphQL */ `query ListQualificationByUser(
  $userID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelQualificationFilterInput
  $limit: Int
  $nextToken: String
) {
  listQualificationByUser(
    userID: $userID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owners
      name
      organization
      userID
      releaseDate
      expirationDate
      isNoExpiry
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListQualificationByUserQueryVariables,
    APITypes.ListQualificationByUserQuery
  >;
export const getConversation = /* GraphQL */ `query GetConversation($id: ID!) {
  getConversation(id: $id) {
    id
    owners
    messages {
      nextToken
      __typename
    }
    name
    associated {
      nextToken
      __typename
    }
    openJob {
      id
      organizationName
      consultantName
      owners
      isPublic
      skills
      isDraft
      title
      coverImage
      workType
      numberOfDayPerWeek
      numberOfHoursPerDay
      baseLocationTypes
      baseLocationDetail
      salaryType
      minumumSalary
      maximumSalary
      overView
      issue
      description
      recommendFor
      selectionProcessSupplement
      requiredSkillTags
      requiredSkillTagsDescription
      welcomeSkillTags
      welcomeSkillTagsDescription
      memo
      isHideOrganizationName
      organizationNameForHidden
      isHideSkills
      skillNamesForHidden
      isExcellent
      createdAt
      updatedAt
      organizationOpenJobsId
      openJobJobId
      openJobBaseLocationId
      __typename
    }
    createdAt
    updatedAt
    openJobConversationsId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetConversationQueryVariables,
  APITypes.GetConversationQuery
>;
export const listConversations = /* GraphQL */ `query ListConversations(
  $filter: ModelConversationFilterInput
  $limit: Int
  $nextToken: String
) {
  listConversations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owners
      name
      createdAt
      updatedAt
      openJobConversationsId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListConversationsQueryVariables,
  APITypes.ListConversationsQuery
>;
export const getMessage = /* GraphQL */ `query GetMessage($id: ID!) {
  getMessage(id: $id) {
    id
    authorId
    author {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    content
    conversationId
    conversation {
      id
      owners
      name
      createdAt
      updatedAt
      openJobConversationsId
      __typename
    }
    createdAt
    isSent
    isEvent
    messageReads {
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMessageQueryVariables,
  APITypes.GetMessageQuery
>;
export const listMessages = /* GraphQL */ `query ListMessages(
  $filter: ModelMessageFilterInput
  $limit: Int
  $nextToken: String
) {
  listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      authorId
      content
      conversationId
      createdAt
      isSent
      isEvent
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMessagesQueryVariables,
  APITypes.ListMessagesQuery
>;
export const listMessagesByConversation =
  /* GraphQL */ `query ListMessagesByConversation(
  $conversationId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMessageFilterInput
  $limit: Int
  $nextToken: String
) {
  listMessagesByConversation(
    conversationId: $conversationId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      authorId
      content
      conversationId
      createdAt
      isSent
      isEvent
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListMessagesByConversationQueryVariables,
    APITypes.ListMessagesByConversationQuery
  >;
export const getMessageRead = /* GraphQL */ `query GetMessageRead($id: ID!) {
  getMessageRead(id: $id) {
    id
    messageId
    message {
      id
      authorId
      content
      conversationId
      createdAt
      isSent
      isEvent
      updatedAt
      __typename
    }
    userId
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    read
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMessageReadQueryVariables,
  APITypes.GetMessageReadQuery
>;
export const listMessageReads = /* GraphQL */ `query ListMessageReads(
  $filter: ModelMessageReadFilterInput
  $limit: Int
  $nextToken: String
) {
  listMessageReads(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      messageId
      userId
      read
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMessageReadsQueryVariables,
  APITypes.ListMessageReadsQuery
>;
export const getUserConversations =
  /* GraphQL */ `query GetUserConversations($id: ID!) {
  getUserConversations(id: $id) {
    id
    owners
    conversationId
    conversation {
      id
      owners
      name
      createdAt
      updatedAt
      openJobConversationsId
      __typename
    }
    userId
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    readAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetUserConversationsQueryVariables,
    APITypes.GetUserConversationsQuery
  >;
export const listUserConversations = /* GraphQL */ `query ListUserConversations(
  $filter: ModelUserConversationsFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserConversations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owners
      conversationId
      userId
      readAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserConversationsQueryVariables,
  APITypes.ListUserConversationsQuery
>;
export const listUserConversationByUser =
  /* GraphQL */ `query ListUserConversationByUser(
  $userId: ID!
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserConversationsFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserConversationByUser(
    userId: $userId
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owners
      conversationId
      userId
      readAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListUserConversationByUserQueryVariables,
    APITypes.ListUserConversationByUserQuery
  >;
export const getPageQuery = /* GraphQL */ `query GetPageQuery($id: ID!) {
  getPageQuery(id: $id) {
    id
    url
    header
    jobs
    skills
    skillSets
    skillTags
    workType
    baseLocation {
      id
      type
      position
      name
      area
      createdAt
      updatedAt
      owner
      __typename
    }
    baseLocationTypes
    numberOfHoursPerDayStart
    numberOfHoursPerDayEnd
    numberOfHoursPerWeekStart
    numberOfHoursPerWeekEnd
    salaryType
    minumumSalary
    maximumSalary
    createdAt
    updatedAt
    pageQueryBaseLocationId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPageQueryQueryVariables,
  APITypes.GetPageQueryQuery
>;
export const listPageQueries = /* GraphQL */ `query ListPageQueries(
  $filter: ModelPageQueryFilterInput
  $limit: Int
  $nextToken: String
) {
  listPageQueries(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      url
      header
      jobs
      skills
      skillSets
      skillTags
      workType
      baseLocationTypes
      numberOfHoursPerDayStart
      numberOfHoursPerDayEnd
      numberOfHoursPerWeekStart
      numberOfHoursPerWeekEnd
      salaryType
      minumumSalary
      maximumSalary
      createdAt
      updatedAt
      pageQueryBaseLocationId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPageQueriesQueryVariables,
  APITypes.ListPageQueriesQuery
>;
export const getInterviewLog = /* GraphQL */ `query GetInterviewLog($id: ID!) {
  getInterviewLog(id: $id) {
    id
    owners
    intervieweeId
    interviewee {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    interviewerId
    interviewer {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    totalEvaluation
    humanPowerEvaluation
    motivationOfGrowEvaluation
    logicalThinkingEvaluation
    entrepreneurEvaluation
    cooperativenessEvaluation
    companyHistoryEvaluation
    projectHistoryEvaluation
    managementExperienceEvaluation
    comment
    commentForClient
    interviewerName
    video
    videos {
      nextToken
      __typename
    }
    minutes
    actionMethod
    interviewedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInterviewLogQueryVariables,
  APITypes.GetInterviewLogQuery
>;
export const listInterviewLogs = /* GraphQL */ `query ListInterviewLogs(
  $filter: ModelInterviewLogFilterInput
  $limit: Int
  $nextToken: String
) {
  listInterviewLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owners
      intervieweeId
      interviewerId
      totalEvaluation
      humanPowerEvaluation
      motivationOfGrowEvaluation
      logicalThinkingEvaluation
      entrepreneurEvaluation
      cooperativenessEvaluation
      companyHistoryEvaluation
      projectHistoryEvaluation
      managementExperienceEvaluation
      comment
      commentForClient
      interviewerName
      video
      minutes
      actionMethod
      interviewedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInterviewLogsQueryVariables,
  APITypes.ListInterviewLogsQuery
>;
export const getInterviewLogVideo =
  /* GraphQL */ `query GetInterviewLogVideo($id: ID!) {
  getInterviewLogVideo(id: $id) {
    id
    owners
    title
    url
    interviewLog {
      id
      owners
      intervieweeId
      interviewerId
      totalEvaluation
      humanPowerEvaluation
      motivationOfGrowEvaluation
      logicalThinkingEvaluation
      entrepreneurEvaluation
      cooperativenessEvaluation
      companyHistoryEvaluation
      projectHistoryEvaluation
      managementExperienceEvaluation
      comment
      commentForClient
      interviewerName
      video
      minutes
      actionMethod
      interviewedAt
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    interviewLogVideosId
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetInterviewLogVideoQueryVariables,
    APITypes.GetInterviewLogVideoQuery
  >;
export const listInterviewLogVideos =
  /* GraphQL */ `query ListInterviewLogVideos(
  $filter: ModelInterviewLogVideoFilterInput
  $limit: Int
  $nextToken: String
) {
  listInterviewLogVideos(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owners
      title
      url
      createdAt
      updatedAt
      interviewLogVideosId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListInterviewLogVideosQueryVariables,
    APITypes.ListInterviewLogVideosQuery
  >;
export const getSkillbaWorkHistory =
  /* GraphQL */ `query GetSkillbaWorkHistory($id: ID!) {
  getSkillbaWorkHistory(id: $id) {
    id
    owners
    openJobId
    openJob {
      id
      organizationName
      consultantName
      owners
      isPublic
      skills
      isDraft
      title
      coverImage
      workType
      numberOfDayPerWeek
      numberOfHoursPerDay
      baseLocationTypes
      baseLocationDetail
      salaryType
      minumumSalary
      maximumSalary
      overView
      issue
      description
      recommendFor
      selectionProcessSupplement
      requiredSkillTags
      requiredSkillTagsDescription
      welcomeSkillTags
      welcomeSkillTagsDescription
      memo
      isHideOrganizationName
      organizationNameForHidden
      isHideSkills
      skillNamesForHidden
      isExcellent
      createdAt
      updatedAt
      organizationOpenJobsId
      openJobJobId
      openJobBaseLocationId
      __typename
    }
    userId
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    startDate
    endDate
    isContinuation
    salary
    salaryType
    numberOfHoursPerWeek
    contractInfo
    comment
    description
    organizationName
    reviews {
      nextToken
      __typename
    }
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetSkillbaWorkHistoryQueryVariables,
    APITypes.GetSkillbaWorkHistoryQuery
  >;
export const listSkillbaWorkHistories =
  /* GraphQL */ `query ListSkillbaWorkHistories(
  $filter: ModelSkillbaWorkHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listSkillbaWorkHistories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owners
      openJobId
      userId
      startDate
      endDate
      isContinuation
      salary
      salaryType
      numberOfHoursPerWeek
      contractInfo
      comment
      description
      organizationName
      updatedAt
      createdAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListSkillbaWorkHistoriesQueryVariables,
    APITypes.ListSkillbaWorkHistoriesQuery
  >;
export const listSkillbaWorkHistoriesByOpenJob =
  /* GraphQL */ `query ListSkillbaWorkHistoriesByOpenJob(
  $openJobId: ID!
  $startDate: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSkillbaWorkHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listSkillbaWorkHistoriesByOpenJob(
    openJobId: $openJobId
    startDate: $startDate
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owners
      openJobId
      userId
      startDate
      endDate
      isContinuation
      salary
      salaryType
      numberOfHoursPerWeek
      contractInfo
      comment
      description
      organizationName
      updatedAt
      createdAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListSkillbaWorkHistoriesByOpenJobQueryVariables,
    APITypes.ListSkillbaWorkHistoriesByOpenJobQuery
  >;
export const listSkillbaWorkHistoriesByUser =
  /* GraphQL */ `query ListSkillbaWorkHistoriesByUser(
  $userId: ID!
  $startDate: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSkillbaWorkHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listSkillbaWorkHistoriesByUser(
    userId: $userId
    startDate: $startDate
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owners
      openJobId
      userId
      startDate
      endDate
      isContinuation
      salary
      salaryType
      numberOfHoursPerWeek
      contractInfo
      comment
      description
      organizationName
      updatedAt
      createdAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListSkillbaWorkHistoriesByUserQueryVariables,
    APITypes.ListSkillbaWorkHistoriesByUserQuery
  >;
export const getSkillbaWorkHistoryReview =
  /* GraphQL */ `query GetSkillbaWorkHistoryReview($id: ID!) {
  getSkillbaWorkHistoryReview(id: $id) {
    id
    owners
    userId
    user {
      id
      owner
      sub
      firstName
      lastName
      firstNameKana
      lastNameKana
      name
      phoneticName
      displayName
      nameInitial
      email
      birthDate
      gender
      tel
      address
      nearestStation
      profileIcon
      coverImage
      skillTags
      appetiteToChangeJobs
      appetiteToSideWork
      wayOfWorking
      currentLocationType
      isPrivate
      belongsTo
      companyName
      description
      numberOfDayPerWeek
      numberOfHoursPerDay
      salaryType
      facebookUrl
      linkedinUrl
      twitterUrl
      lineID
      instagramUrl
      chatworkID
      minumumSalary
      maximumSalary
      hopeLocationType
      otherHopes
      isShareFullname
      isShareProfileIcon
      isShareHistories
      isShareCurrentJob
      isShareAppetites
      isShareWorkTime
      isShareHopeSalary
      isShareHopeLocationType
      isShareAbleAreas
      isShareGender
      isShareBirthdate
      isShareBaseLocation
      isCompletedSurvey
      resumeFile
      isAlreadyEditedBasic
      isAgreedTerms
      isAgreedPostForClient
      isConfirmedServiceGuide
      nameForClient
      introductionForClient
      triggerForRegistration
      triggerForRegistrationDescription
      introducerId
      introducerName
      skillNamesForQuery
      jobNamesForQuery
      lastJobNameForQuery
      interviewCommentForQuery
      interviewCommentForClientForQuery
      interviewMinutesForQuery
      interviewActionMethodForQuery
      skillbaWorkHistoryNameForQuery
      skillTagNamesForQuery
      portfolioForQuery
      evaluationAvg
      isImportFromNotion
      notionWorkTimes
      notionSalaries
      isDebug
      shortID
      createdAt
      updatedAt
      lastVisitedAt
      organizationUsersId
      userBaseLocationId
      userMainJobId
      userWpWorkerId
      __typename
    }
    skillbaWorkHistoryId
    skillbaWorkHistory {
      id
      owners
      openJobId
      userId
      startDate
      endDate
      isContinuation
      salary
      salaryType
      numberOfHoursPerWeek
      contractInfo
      comment
      description
      organizationName
      updatedAt
      createdAt
      __typename
    }
    message
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetSkillbaWorkHistoryReviewQueryVariables,
    APITypes.GetSkillbaWorkHistoryReviewQuery
  >;
export const listSkillbaWorkHistoryReviews =
  /* GraphQL */ `query ListSkillbaWorkHistoryReviews(
  $filter: ModelSkillbaWorkHistoryReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  listSkillbaWorkHistoryReviews(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owners
      userId
      skillbaWorkHistoryId
      message
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListSkillbaWorkHistoryReviewsQueryVariables,
    APITypes.ListSkillbaWorkHistoryReviewsQuery
  >;
export const listSkillbaWorkHistoryReviewsByUser =
  /* GraphQL */ `query ListSkillbaWorkHistoryReviewsByUser(
  $userId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSkillbaWorkHistoryReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  listSkillbaWorkHistoryReviewsByUser(
    userId: $userId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owners
      userId
      skillbaWorkHistoryId
      message
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListSkillbaWorkHistoryReviewsByUserQueryVariables,
    APITypes.ListSkillbaWorkHistoryReviewsByUserQuery
  >;
export const getInsight = /* GraphQL */ `query GetInsight($id: ID!) {
  getInsight(id: $id) {
    id
    owners
    type
    metricsType
    title
    value
    dailyDifference
    reportedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInsightQueryVariables,
  APITypes.GetInsightQuery
>;
export const listInsights = /* GraphQL */ `query ListInsights(
  $filter: ModelInsightFilterInput
  $limit: Int
  $nextToken: String
) {
  listInsights(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owners
      type
      metricsType
      title
      value
      dailyDifference
      reportedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInsightsQueryVariables,
  APITypes.ListInsightsQuery
>;
export const listInsightsByOrderByReportedAt =
  /* GraphQL */ `query ListInsightsByOrderByReportedAt(
  $type: String!
  $reportedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInsightFilterInput
  $limit: Int
  $nextToken: String
) {
  listInsightsByOrderByReportedAt(
    type: $type
    reportedAt: $reportedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owners
      type
      metricsType
      title
      value
      dailyDifference
      reportedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListInsightsByOrderByReportedAtQueryVariables,
    APITypes.ListInsightsByOrderByReportedAtQuery
  >;
export const getWorkHistoryCompany =
  /* GraphQL */ `query GetWorkHistoryCompany($id: ID!) {
  getWorkHistoryCompany(id: $id) {
    id
    owners
    name
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetWorkHistoryCompanyQueryVariables,
    APITypes.GetWorkHistoryCompanyQuery
  >;
export const listWorkHistoryCompanies =
  /* GraphQL */ `query ListWorkHistoryCompanies(
  $filter: ModelWorkHistoryCompanyFilterInput
  $limit: Int
  $nextToken: String
) {
  listWorkHistoryCompanies(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owners
      name
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListWorkHistoryCompaniesQueryVariables,
    APITypes.ListWorkHistoryCompaniesQuery
  >;
export const getCSVDownload = /* GraphQL */ `query GetCSVDownload($id: ID!) {
  getCSVDownload(id: $id) {
    id
    owners
    status
    filekey
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCSVDownloadQueryVariables,
  APITypes.GetCSVDownloadQuery
>;
export const listCSVDownloads = /* GraphQL */ `query ListCSVDownloads(
  $filter: ModelCSVDownloadFilterInput
  $limit: Int
  $nextToken: String
) {
  listCSVDownloads(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owners
      status
      filekey
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCSVDownloadsQueryVariables,
  APITypes.ListCSVDownloadsQuery
>;
export const getProfessionalItem =
  /* GraphQL */ `query GetProfessionalItem($id: ID!) {
  getProfessionalItem(id: $id) {
    id
    name
    lead
    category
    keyID
    userIDs
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetProfessionalItemQueryVariables,
    APITypes.GetProfessionalItemQuery
  >;
export const listProfessionalItems = /* GraphQL */ `query ListProfessionalItems(
  $filter: ModelProfessionalItemFilterInput
  $limit: Int
  $nextToken: String
) {
  listProfessionalItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      lead
      category
      keyID
      userIDs
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProfessionalItemsQueryVariables,
  APITypes.ListProfessionalItemsQuery
>;
