import * as Yup from 'yup';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Link as RouterLink } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useMutation, gql } from '@apollo/client';
import { Auth } from 'aws-amplify';
// material
import {
  Link,
  Stack,
  Alert,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
//
import AuthSocial from '../AuthSocial';
import { MIconButton } from '../../@material-extend';
import { hasAuthUser } from '../../../utils/guard';
import { createUser } from '../../../graphql/mutations';
// import { getUser } from '../../../graphql/queries';

// ----------------------------------------------------------------------
type InitialValues = {
  email: string;
  password: string;
  remember: boolean;
  afterSubmit?: string;
};
type LoginFormProps = {
  idJob: string | null;
};

export default function LoginForm({ idJob }: LoginFormProps) {
  const { login, resendVerifyCode } = useAuth();
  const isMountedRef = useIsMountedRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('desktop'));
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [createUserFunction, { client, data }] = useMutation(gql(createUser));

  const urlParams = new URLSearchParams(window.location.search);
  const index = urlParams.get('index');

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required('メールアドレスを入力してください'),
    password: Yup.string().required('パスワードを入力してください')
  });

  const formik = useFormik<InitialValues>({
    initialValues: {
      email: '',
      password: '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        await login(values.email, values.password);
        enqueueSnackbar('ログインしました', {
          variant: 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
        if (isMountedRef.current) {
          setSubmitting(false);
        }
        if (index && idJob) {
          window.location.href = `auth/profile-register?id=${idJob}`;
        } else if (!index && idJob) {
          window.location.href = `/jobs/detail/${idJob}?id=${encodeURIComponent(true)}`;
        }
      } catch (error: any) {
        console.error(error);
        if (isMountedRef.current) {
          switch (error.code) {
            case 'NotAuthorizedException':
              setErrors({ afterSubmit: 'メールアドレスまたはパスワードが違います' });
              break;
            case 'UserNotFoundException':
              setErrors({ afterSubmit: 'このメールアドレスは登録されていません' });
              break;
            case 'UserNotConfirmedException':
              try {
                await Auth.resendSignUp(values.email);
                window.location.href = '/auth/verify';
              } catch (e) {
                console.log(e);
              }
              break;
            default:
              setErrors({ afterSubmit: 'エラーが発生しました' });
              break;
          }
          setSubmitting(false);
        }
      }
    }
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={isMobile ? 4 : 6}>
          {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

          {/* <AuthSocial /> */}

          <Stack spacing={3}>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              placeholder="メールアドレス"
              {...(isMobile && { size: 'small' })}
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              placeholder="パスワード"
              {...(isMobile && { size: 'small' })}
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ my: 2 }}
            >
              <FormControlLabel
                control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
                label="ログイン情報を保存"
              />

              <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
                パスワードを忘れた場合
              </Link>
            </Stack>

            <Stack alignItems="center">
              <LoadingButton
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ width: { mobile: '74.3%', desktop: '64.3%' } }}
                {...formik.values.email.includes('+alp-test') && { id: 'test-user' }}
              >
                ログイン
              </LoadingButton>
            </Stack>
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
