// ----------------------------------------------------------------------

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: false;
    md: true;
    lg: true;
    xl: true;
    mobile: true;
    tablet: true;
    desktop: true;
  }
}

const breakpoints = {
  values: {
    xs: 0,
    mobile: 0,
    md: 768,
    tablet: 768,
    lg: 1048,
    desktop: 1048,
    xl: 1128,
  }
};

export default breakpoints;
