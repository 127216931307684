// material
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

interface LogoProps extends BoxProps {
  isWhite?: boolean;
}

export default function Logo({ isWhite, sx }: LogoProps) {
  return (
    <Box sx={{ width: { mobile: '100%', desktop: 153 }, height: { mobile: 30, desktop: 40 }, ...sx }}>
      <Box
        component="img"
        src={`/static/logo${isWhite ? '_white' : ''}.svg`}
        sx={{ color: '#fff', width: '100%', height: '100%' }}
      />
    </Box>
  );
}
